import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';

class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {manageaction:'COMPANY',user:localStorage.getItem('tokens'),Resp:'',PID:'', datebirth:'',address:'',city:'',province:'',
						postcode:'', cellphone:'',ephone:'',states:[]};			
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({PID:result.data[6]})
				this.setState({datebirth:result.data[0]})
				this.setState({address:result.data[1]})
				this.setState({city:result.data[2]})
				this.setState({province:result.data[3]})
				this.setState({postcode:result.data[4]})
				this.setState({cellphone:result.data[5]})
				this.setState({ephone:result.data[7]})			
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/findprovinces.php')			
			.then(result => {		
				this.setState({states:result.data});
			})		
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	
	
	handleSubmit = event => {
		event.preventDefault()		
		const {datebirth,address,city,province,postcode,cellphone,ephone,PID} = this.state		
		if(!PID){
			alert('Please enter our company legal name')
			return false 
		} 			
		if(!datebirth){
			alert('Please enter our company short name')
			return false 
		}
		if(!address){
			alert('Please enter address')
			return false 
		}
		if(!city){
			alert('Please enter city')
			return false 
		}
		if(!province){
			alert('Please enter province')
			return false 
		}
		if(!postcode){
			alert('Please enter postcode and follow format of m1a-2b3')
			return false 
		}
		if(!cellphone){
			alert('Please enter email')
			return false 
		}
		if(!ephone){
			alert('Please enter phone')
			return false 
		}
		const userObject = {	
			user:this.state.user,
			manageaction:this.state.manageaction,
			datebirth:this.state.datebirth,
			address:this.state.address,
			city:this.state.city,
			province:this.state.province,
			postcode:this.state.postcode,
			cellphone:this.state.cellphone,			
			ephone:this.state.ephone,				
			PID:this.state.PID
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/managementprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Update Company Information"/>
				<form onSubmit={this.handleSubmit}>					
					<h3>Update Company Information</h3>					
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Company Legal Name:</b></label>
					<input type="text" name="PID" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.PID} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Company Short Name:</b></label>
					<input type="text" name="datebirth" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.datebirth} onChange={this.handleChange}/><br/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Address:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.address} onChange={this.handleChange}/><br/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>City:</b></label>
					<input type="text" name="city" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.city} onChange={this.handleChange}/><br/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
					<select name="province" style={{width: '160px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.province} onChange={this.handleChange}>
						<option value=""></option>
						<option value="0">--Select province--</option>		
						{this.state.states.map(item => (<option value={item[1]} key={item[0]}>{item[1]}</option>))}	
					</select><br/><br/>									
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Post Code:</b></label>
					<input type="text" name="postcode" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.postcode} onChange={this.handleChange}/><br/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
					<input type="text" name="cellphone" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.cellphone} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Phone:</b></label>
					<input type="text" name="ephone" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.ephone} onChange={this.handleChange}/><br/><br/>									
					<h3>{this.state.Resp}</h3><br/><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/><br/>
				</form>				
			</div>
		)
	}
}

export default NewApplication