import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';

class PrimaryFooter extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	render() {	

		return(<div className="footer">
				Copyright @ 2011. All rights reserved.			
				<Link to ="/">{this.state.CompName}</Link> | <Link to = "/Privacy">Privacy Policy</Link> | <Link to="/Contact">Contact US</Link>
			</div>)
	}
}
export default PrimaryFooter