import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';

class MenuBar extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}
	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	render() {
		return(
			<div className="header">
				<div className="top"><Link to="/" style={{color:"white"}}>{this.state.CompName}</Link></div>
				<div className="buttons" >
					<ul>
						<li><Link to = "/">HOME</Link></li>
						<li><Link to = "/Apply" >APPLY</Link></li>										
						<li><Link to = "/Member">MEMEBER</Link></li>								
						<li><Link to = "/Questions">QUESTION</Link></li>
						<li><Link to = "/Contact">CONTACT</Link></li>										
					</ul>			
				</div>
			</div>
		)
	}
}

export default MenuBar