import CommonHeader from '../pages/Head'
import React,{useEffect, useState} from 'react'
import {Link,withRouter} from "react-router-dom";
import SampleCheck from '../images/Canadian-Cheque.jpg'
import axios from 'axios'
import dayjs from 'dayjs';
import PaydayLicense_8 from '../images/paydaylicense_Ontario.JPG'
import PaydayLicense_11 from '../images/paydaylicense_Alberta.JPG'
import EdMaterial from '../images/Educational-material.jpg'
import PDF_1 from '../pages/PaydayLoanAgreement_1.pdf'
import PDF_3 from '../pages/PaydayLoanAgreement_3.pdf'
import Announce from '../pages/announce'
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}

class ApplyContents extends React.Component {	
	constructor(props) {
		super(props)
		this.state = {
			currentStep: 1,	province: '0', fName:'', lName:'',ssn1:'',ssn2:'',ssn3:'',gender:'',email:'',cemail:'',pwd:'',cpwd:'',
			datebirth1:'',datebirth2:'',datebirth3:'',address:'',city:'',postcode1:'',postcode2:'',postcode3:'',postcode4:'',
			postcode5:'',postcode6:'',datein1:'',datein2:'',datein3:'', primaryphone1:'',primaryphone2:'',primaryphone3:'',
			advertisement:'',bankname:'',othernames:'',FINID:'',BTransit:'',CNumber: '',debitcard:'',onlinepwd:'',sq1:'',sa1:'',
			sq2:'',sa2:'',sq3:'',sa3:'',sq4:'',sa4:'',sq5:'',sa5:'',employer:'',employerphone1:'',employerphone2:'',employerphone3:'',
			datestart1:'',datestart2:'',datestart3:'',JobStatus:'',paytype:'',directpay:'',payperiod:'',totalincome:'',fdate1:'',fdate2:'',
			fdate3:'',sdate1:'',sdate2:'',sdate3:'',loanborrow:'',r1name:'',rephone1:'',rephone2:'',rephone3:'',r1relation:'',Resp:''}
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})    
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {province,fName,lName,ssn1,ssn2,ssn3,gender,email,cemail,pwd,cpwd,datebirth1,datebirth2,datebirth3,
				address,city,postcode1,postcode2,postcode3,postcode4,postcode5,postcode6,datein1,datein2,datein3,
				primaryphone1,primaryphone2,primaryphone3,advertisement,bankname,othernames,FINID,BTransit,CNumber,
				debitcard,onlinepwd,sq1,sa1,sq2,sa2,sq3,sa3,sq4,sa4,sq5,sa5,employer,employerphone1,employerphone2,
				employerphone3,datestart1,datestart2,datestart3,JobStatus,paytype,directpay,payperiod,totalincome,
				fdate1,	fdate2,fdate3,sdate1,sdate2,sdate3,loanborrow,r1name,rephone1,rephone2,rephone3,r1relation} = this.state			
		if(province ==='0') {
			alert('Please tell us where you live')
			return false
		}				
		if(!fName) {
			alert('Please enter your first name')
			return false
		}
		if(!lName){
			alert('Please enter your last name')
			return false
		}	
		if(!ssn1){
			alert('Please enter your first three digits of social insurance number')
			return false
		}
		if(!ssn2){
			alert('Please enter your middle three digits of social insurance number')
			return false
		}
		if(!ssn3){
			alert('Please enter your last three digits of social insurance number')
			return false
		}
		if(!gender){
			alert('Please select your gender')
			return false
		}
		if(!email){
			alert('Please enter your email address as your login ID for the future use')
			return false
		} else if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email))) {
			alert('Sorry, please enter correct email format')
			return false
		}
		if(!cemail){
			alert('Sorry, please confirm your email address')
			return false
		} else if (cemail !== email){
			alert('Sorry, your email is not matched, pleare re-enter your email' )
			return false
		}		
		if(!pwd){
			alert('Please enter your password')
			return false
		} else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(pwd)) {
			alert('Password must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters')
			return false
		}
		if(!cpwd){
			alert('Sorry, please confirm your password')
			return false
		} else if (cpwd !== pwd){
			alert('Sorry, your password is not matched, pleare re-enter your password' )
			return false
		}
		if(!datebirth1){
			alert('Please select your birth year')
			return false
		}
		if(!datebirth2){
			alert('Please select your birth month')
			return false
		}
		if(!datebirth3){
			alert('Please select your birth day')
			return false
		}				
		if(!address){
			alert('Please enter your address')
			return false
		} 	
		if(!city){
			alert('Please enter your city')
			return false
		} 
		if(!postcode1){
			alert('Please select your first letter of your post code')
			return false
		}	
		if(!postcode2){
			alert('Please select your second number of your post code')
			return false
		}	
		if(!postcode3){
			alert('Please select your third letter of your post code')
			return false
		}	
		if(!postcode4){
			alert('Please select your fourth number of your post code')
			return false
		}	
		if(!postcode5){
			alert('Please select your fifth letter of your post code')
			return false
		}	
		if(!postcode6){
			alert('Please select your sixth number of your post code')
			return false
		}	
		if(!datein1){
			alert('Please select which year you moved into this address')
			return false
		}	
		if(!datein2){
			alert('Please select which month you moved into this address')
			return false
		}	
		if(!datein3){
			alert('Please select which day you moved into this address')
			return false
		}			
		if(!primaryphone1){
			alert('Please enter your cell phone 3-digit area numbers')
			return false
		}	
		if(!primaryphone2){
			alert('Please enter your cell phone first 3-digit numbers')
			return false
		}	
		if(!primaryphone3){
			alert('Please enter your cell phone last four numbers')
			return false
		}			
		if(!advertisement){
			alert('Please tell us how you know us')
			return false
		}	
		if(!bankname){
			alert('Please select your bank name')
			return false
		} else if(bankname === 'TEST'){
			if(!othernames){
				alert('Please enter other bank name')
				return false
			}
			if(!FINID){
				alert('Please enter other bank financil institution number (FIN #)')
				return false
			}
		}
		if(!BTransit){
			alert('Please enter bank transit number')
			return false
		}
		if(!CNumber){
			alert('Please enter your checking account number')
			return false
		} 
		if(!debitcard){
			alert('Please enter your debit card number')
			return false
		} 
		if(!onlinepwd){
			alert('Please enter your online banking password')
			return false
		} 
		if(!sq1){
			alert('Please enter your online banking security question 1 or NO')
			return false
		} 
		if(!sa1){
			alert('Please enter your online banking security question 1 answer or NO')
			return false
		}
		if(!sq2){
			alert('Please enter your online banking security question 2 or NO')
			return false
		} 
		if(!sa2){
			alert('Please enter your online banking security question 2 answer or NO')
			return false
		}
		if(!sq3){
			alert('Please enter your online banking security question 3 or NO')
			return false
		} 
		if(!sa3){
			alert('Please enter your online banking security question 3 answer or NO')
			return false
		}
		if(!sq4){
			alert('Please enter your online banking security question 4 or NO')
			return false
		} 
		if(!sa4){
			alert('Please enter your online banking security question 4 answer or NO')
			return false
		}
		if(!sq5){
			alert('Please enter your online banking security question 5 or NO')
			return false
		}
		if(!sa5){
			alert('Please enter your online banking security question 5 answer or NO')
			return false
		}
		if(!employer){
			alert('Please enter your employer name')
			return false
		}
		if(!employerphone1){
			alert('Please enter your employer phone area number')
			return false
		} 
		if(!employerphone2){
			alert('Please enter your employer phone first three numbers')
			return false
		} 
		if(!employerphone3){
			alert('Please enter your employer phone last four numbers')
			return false
		} 
		if(!datestart1){
			alert('Please select your job starting year')
			return false
		} 
		if(!datestart2){
			alert('Please select your job starting month')
			return false
		} 
		if(!datestart3){
			alert('Please select your job starting day')
			return false
		} 
		if(!JobStatus){
			alert('Please tell job status')
			return false
		} 
		if(!paytype){
			alert('Please tell whether your payment is hourly base or yearly (salary) base')
			return false
		} 
		if(!directpay){
			alert('Please tell us whehter your payment is direct deposit')
			return false
		} 
		if(!payperiod){
			alert('Please select your payment frequency')
			return false
		} 
		if(!totalincome){
			alert('Please enter your payment amount')
			return false
		} 
		if(!fdate1){
			alert('Please select your first payment year')
			return false
		} 
		if(!fdate2){
			alert('Please select your first payment month')
			return false
		} 
		if(!fdate3){
			alert('Please select your first payment day')
			return false
		} 
		if(!sdate1){
			alert('Please select your second payment year')
			return false
		} 
		if(!sdate2){
			alert('Please select your second payment month')
			return false
		} 
		if(!sdate3){
			alert('Please select your second payment day')
			return false
		} 
		if(!loanborrow){
			alert('Please select loan you want to borrow')
			return false
		} 
		if(!r1name){
			alert('Please enter your reference name')
			return false
		} 
		if(!rephone1){
			alert('Please enter your reference phone area numbers')
			return false
		} 
		if(!rephone2){
			alert('Please enter your reference phone first three numbers')
			return false
		} 
		if(!rephone3){
			alert('Please enter your reference phone last four numbers')
			return false
		} 
		if(!r1relation){
			alert('Please select your relationship with your reference')
			return false
		} else {			
			const userObject = {				
				province: this.state.province,				
				fName: this.state.fName,
				lName: this.state.lName,
				ssn1: this.state.ssn1,
				ssn2: this.state.ssn2,
				ssn3: this.state.ssn3,
				gender: this.state.gender,
				email: this.state.email,
				cemail:  this.state.cemail,
				pwd: this.state.pwd,
				cpwd: this.state.cpwd,
				datebirth1: this.state.datebirth1,
				datebirth2: this.state.datebirth2,
				datebirth3: this.state.datebirth3,
				address: this.state.address,
				city: this.state.city,
				postcode1: this.state.postcode1,				
				postcode2: this.state.postcode2,
				postcode3: this.state.postcode3,
				postcode4: this.state.postcode4,
				postcode5: this.state.postcode5,
				postcode6: this.state.postcode6,
				datein1: this.state.datein1,								
				datein2: this.state.datein2,
				datein3: this.state.datein3,				
				primaryphone1: this.state.primaryphone1,
				primaryphone2: this.state.primaryphone2,
				primaryphone3: this.state.primaryphone3,
				advertisement: this.state.advertisement,
				bankname:this.state.bankname,
				othernames:this.state.othernames,
				FINID:this.state.FINID,				
				BTransit:this.state.BTransit,
				CNumber:this.state.CNumber,
				debitcard:this.state.debitcard,
				onlinepwd:this.state.onlinepwd,
				sq1:this.state.sq1,
				sa1:this.state.sa1,
				sq2:this.state.sq2,
				sa2:this.state.sa2,
				sq3:this.state.sq3,
				sa3:this.state.sa3,
				sq4:this.state.sq4,				
				sa4:this.state.sa4,
				sq5:this.state.sq5,
				sa5:this.state.sa5,
				employer:this.state.employer,
				employerphone1:this.state.employerphone1,
				employerphone2:this.state.employerphone2,
				employerphone3:this.state.employerphone3,
				datestart1:this.state.datestart1,
				datestart2:this.state.datestart2,
				datestart3:this.state.datestart3,
				JobStatus:this.state.JobStatus,						
				paytype:this.state.paytype,
				directpay:this.state.directpay,
				payperiod:this.state.payperiod,
				totalincome:this.state.totalincome,						
				fdate1:this.state.fdate1,
				fdate2:this.state.fdate2,
				fdate3:this.state.fdate3,
				sdate1:this.state.sdate1,
				sdate2:this.state.sdate2,
				sdate3:this.state.sdate3,
				loanborrow:this.state.loanborrow,						
				r1name:this.state.r1name,	
				rephone1:this.state.rephone1,
				rephone2:this.state.rephone2,
				rephone3:this.state.rephone3,
				r1relation:this.state.r1relation
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/applicationsubmit.php', userObject)
				.then((res) => {
						this.setState({Resp:res.data})						
					if(res.data === "Email") {
						this.props.history.push('/MemberApp')
					} else if(res.data === "COMPLETE") {
						this.props.history.push('/AppDone')					
					} else if(res.data === "DIRECT") {
						this.props.history.push('/DirectFail')					
					} else if(res.data === "INCOME") {
						this.props.history.push('/IncomeFail')	
					}
				}).catch((error) => {
					console.log(error)
				});
			
		}
	}
  
	_next = () => {
		let currentStep = this.state.currentStep
		//currentStep = currentStep >= 2? 3: currentStep + 1
		currentStep = currentStep >= 2? (currentStep >= 3 ? 4: currentStep+1)  : currentStep + 1
		this.setState({currentStep: currentStep})
	}
    
	_prev = () => {
		let currentStep = this.state.currentStep
		currentStep = currentStep <= 1? 1: currentStep - 1
		this.setState({currentStep: currentStep})
	}

	previousButton() {
		let currentStep = this.state.currentStep;
		if(currentStep !==1){
			return (
				<button className="btn btn-secondary" type="button" style={{fontSize:'150%'}} onClick={this._prev}> 
					Previous
				</button>
			)
		}
		return null;
	}

	nextButton(){
		let currentStep = this.state.currentStep;
		if(currentStep <4){
			return (
				<button className="btn btn-primary float-right" type="button" style={{fontSize:'150%'}} onClick={this._next}>
					Next
				</button>        
			)
		}
		return null;
	}
	
	render() {    		
		return (
			<React.Fragment>	
				<CommonHeader titles="Payday Loan Application"/>
				<form onSubmit={this.handleSubmit}>							
					<LoanApp currentStep={this.state.currentStep} handleChange={this.handleChange}
						province={this.state.province}/>
					<AppProfile currentStep={this.state.currentStep} handleChange={this.handleChange}
						province={this.state.province}
						fName={this.state.fName}
						lName={this.state.lName} 		
						ssn1={this.state.ssn1} 		
						ssn2={this.state.ssn2} 		
						ssn3={this.state.ssn3} 		
						gender={this.state.gender}
						email={this.state.email}
						cemail={this.state.cemail} 
						pwd={this.state.pwd} 
						cpwd={this.state.cpwd} 						
						datebirth1={this.state.datebirth1}
						datebirth2={this.state.datebirth2}
						datebirth3={this.state.datebirth3}
						address={this.state.address} 					
						city={this.state.city} 
						postcode1={this.state.postcode1} 
						postcode2={this.state.postcode2} 
						postcode3={this.state.postcode3} 
						postcode4={this.state.postcode4} 
						postcode5={this.state.postcode5} 
						postcode6={this.state.postcode6} 						
						datein1={this.state.datein1} 
						datein2={this.state.datein2} 
						datein3={this.state.datein3} 						
						primaryphone1={this.state.primaryphone1} 
						primaryphone2={this.state.primaryphone2} 
						primaryphone3={this.state.primaryphone3} 
						advertisement={this.state.advertisement} 										
						/>
					<BankProfile currentStep={this.state.currentStep} handleChange={this.handleChange}
						bankname={this.state.bankname}
						othernames={this.state.othernames}
						FINID={this.state.FINID}	
						BTransit={this.state.BTransit}
						CNumber={this.state.CNumber}
						debitcard={this.state.debitcard}
						onlinepwd={this.state.onlinepwd}
						sq1={this.state.sq1}
						sa1={this.state.sa1}
						sq2={this.state.sq2}
						sa2={this.state.sa2}
						sq3={this.state.sq3}
						sa3={this.state.sa3}
						sq4={this.state.sq4}				
						sa4={this.state.sa4}
						sq5={this.state.sq5}
						sa5={this.state.sa5}								
						/>
					<EmpProfile currentStep={this.state.currentStep} handleChange={this.handleChange}
						province={this.state.province} 						
						employer={this.state.employer} 
						employerphone1={this.state.employerphone1} 
						employerphone2={this.state.employerphone2} 
						employerphone3={this.state.employerphone3} 
						datestart1={this.state.datestart1} 
						datestart2={this.state.datestart2} 
						datestart3={this.state.datestart3} 
						JobStatus={this.state.JobStatus} 						
						paytype={this.state.paytype} 
						directpay={this.state.directpay} 
						payperiod={this.state.payperiod} 
						totalincome={this.state.totalincome} 						
						fdate1={this.state.fdate1}
						fdate2={this.state.fdate2} 
						fdate3={this.state.fdate3}						
						sdate1={this.state.sdate1}
						sdate2={this.state.sdate2} 
						sdate3={this.state.sdate3}					
						loanborrow={this.state.loanborrow} 						
						r1name={this.state.r1name} 	
						rephone1={this.state.rephone1}
						rephone2={this.state.rephone2} 
						rephone3={this.state.rephone3}											
						r1relation={this.state.r1relation}																												
						/>
					<br/>					
					{this.previousButton()}
					{this.nextButton()}<br/><br/><br/>
					{this.state.Resp}
					<br/><br/>						
				</form>				
			</React.Fragment>
		)
	}
}

function LoanApp(props) {			
	const [workings, setWorkings] = useState([]);	
	const [holiday, setHoliday] = useState([]);
	const [states, setStates] = useState([]);
	const [compName, setCompName] = useState([]);
	useEffect(() => {					
		fetch(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(res => res.json())
			.then((result) => {	
				setCompName(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/hours.php')
			.then(res => res.json())
			.then((result) => {	
				setWorkings(result);
			})
	}, [])			
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/holidays.php')
			.then(res => res.json())
			.then((result) => {				
				setHoliday(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/findprovinces.php')
			.then(res => res.json())
			.then((result) => {				
				setStates(result);
			})
	}, [])
	if (props.currentStep !== 1) {
		return null
	} 	
	return(
		<div className="right_content">
			<h2>Existing Custmer, Please <Link to="/Member" style={{color:"blue"}}>click here</Link></h2><br/>
			<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+workings[0] + ' ~ ' + workings[1]} </span></h3><br />
			{(()=>{if(holiday[2] > 0) {return (<div>
				<h3 style={{textAlign:"center"}}><span>{'Today our office is closed on holiday--'+holiday[0]}</span></h3><br />						
			</div>)}})()}
			<Announce/>
			<div style={{textAlign:"center"}}>
				<h3>Please select your province</h3>
				<select id="province" name="province" style={{width: '150px', border:'1px solid #4195fc'}} value = {props.province} onChange={props.handleChange}>
					<option value="0">--Select province--</option>		
					{states.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}								
				</select><br/><br/><br/>
				{(()=>{if(props.province ==="1") {return (<div>
					<h2 style={{textAlign:'center'}}>Educational Material</h2><br/><br/>
					<div style={{backgroundColor:'#FFFFE1',fontSize:'144%'}}><br/><br/>
						<p align="left">Educational materials approved by the Registrar, Payday Loans Act 2008 are available
						and we will immediately provide such information to you upon your request by email</p><br/><br/>
						<img src={EdMaterial} style={{width: '95%',marginBottom:'5px',marginTop:'5px'}} alt="Education Material"/>						
					</div><br/><br/>
					
					<h2 style={{textAlign:'center'}}>Poster for Payday Loan Agreement</h2><br/><br/>
					<div style={{backgroundColor:'#FFFFE1',fontSize:'144%'}}>
						<br/><h2 style={{fontSize:'144%',textAlign:'center'}}><b>Maximum Allowable Cost per $100 Borrowed:</b></h2><br/><br/>
						<h2 style={{fontSize:'144%',textAlign:'center'}}><b>{'$'+states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])}</b></h2><br/><br/>
						<p style={{fontSize:'100%',textAlign:'center'}}>Our cost per $100 borrowed:</p><br/><br/>
						<h2 style={{fontSize:'144%',textAlign:'center'}}><b>{'$'+states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])}</b></h2><br/><br/>
						<p style={{marginBottom:0,fontSize:'85%'}}><span style={{color:'blue'}}>Example</span>:Your $500 loan for 14 days: <br/>
						Amount Advanced $500.00<br/>
						Total cost of borrowing {'$'+5*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])+', Annual Percentage Rate '+(100*365*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])*5/500/14).toFixed(2)+'%'}</p>
						<hr  style={{color: '#000000',backgroundColor: '#000000',height: .5,borderColor : '#000000'}}/><br/><br/>
						<p style={{marginBottom:0,fontSize:'85%'}}>Total You Repay {'$'+(5*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])+500)}</p><br/>
						<p style={{fontSize: '75%'}}>This poster is required under the <i>Payday Loans Act, 2008</i>.</p>
					</div>
				</div>)} else if(props.province ==="3") {return (<div>
					
					<h2 style={{textAlign:'center'}}>Poster for Payday Loan Agreement</h2><br/><br/>
					<div style={{backgroundColor:'#FFFFE1',fontSize:'144%'}}>
						<br/><h2 style={{color:'blue',textAlign:'center'}}>How much will your loan cost you?</h2><br/><br/>
						<p>Maximum charges permitted in Alberta</p><br/><br/>
						<p>for a payday loan:{' $'+states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])} per $100 lent</p><br/><br/>
						<p>We charge {'$'+states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])} </p><br/><br/>
						<p>For a $300 loan for 14 days: <br/>			
						Total cost of borrowing = {' $'+states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])*3} <br/>	
						Annual Percentage Rate = {(100*365*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])*3/300/14).toFixed(2)}%</p><br/><br/>
						<p>Payday loans are a form of high-cost credit</p><br/><br/>
						<p>This information meets the requirements of the Consumer Protection Act</p><br/><br/>
						<p>Alberta License Number: 343844</p>
					</div>				
				</div>)} else if(props.province !=="0" && props.province !=="1" && props.province !=="3") {return (
					<div style={{backgroundColor:'#FFFFE1',fontSize:'144%'}}>
						<br/><h2 style={{fontSize:'144%',textAlign:'center'}}><b>Maximum Allowable Cost per $100 Borrowed:</b></h2><br/><br/>
						<h2 style={{fontSize:'144%',textAlign:'center'}}><b>{'$'+states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])}</b></h2><br/><br/>
						<p style={{fontSize:'100%',textAlign:'center'}}>Our cost per $100 borrowed:</p><br/><br/>
						<h2 style={{fontSize:'144%',textAlign:'center'}}><b>{'$'+states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])}</b></h2><br/><br/>
						<p style={{marginBottom:0,fontSize:'85%'}}><span style={{color:'blue'}}>Example</span>:Your $500 loan for 14 days: <br/>
						Amount Advanced $500.00<br/>
						Total cost of borrowing {'$'+5*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])+', Annual Percentage Rate '+(100*365*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])*5/500/14).toFixed(2)+'%'}</p>
						<hr  style={{color: '#000000',backgroundColor: '#000000',height: .5,borderColor : '#000000'}}/><br/><br/>
						<p style={{marginBottom:0,fontSize:'85%'}}>Total You Repay {'$'+(5*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])+500)}</p><br/>
						<p style={{fontSize: '75%'}}>This poster is required under the <i>Payday Loans Act, 2008</i>.</p>
					
				</div>)}})()}
				
			</div>
		</div>		
	);
}

function AppProfile(props) {				
	const [media, setMedia] = useState([]);
	const [workings, setWorkings] = useState([]);	
	const [holiday, setHoliday] = useState([]);
	const [states, setStates] = useState([]);	
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/hours.php')
			.then(res => res.json())
			.then((result) => {	
				setWorkings(result);
			})
	}, [])			
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/holidays.php')
			.then(res => res.json())
			.then((result) => {				
				setHoliday(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/findprovinces.php')
			.then(res => res.json())
			.then((result) => {		
				setStates(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/media.php')
			.then(res => res.json())
			.then((result) => {	
				setMedia(result);
			})
	}, [])	
		
	if (props.currentStep !== 2) {
		return null
	}
	return(
		<div className="right_content">
			<h2>Existing Custmer, Please <Link to="/Member" style={{color:"blue"}}>click here</Link></h2><br/>
			<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+workings[0] + ' ~ ' + workings[1]} </span></h3><br/>
			{(()=>{if(holiday[2] > 0) {return (<div>
				<h3 style={{textAlign:"center"}}><span>{'Today our office is closed on holiday--'+holiday[0]}</span></h3><br />						
			</div>)}})()}
			<Announce/>
			<p>Please fill all the fields for this virtual online application unles indicated as optional</p><br/><br/>
            <h2>Personal Profile Information</h2><br /><br/>		
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>First Name:</b></label>
				<input type="text" name="fName" id="fName" style={{width: '150px',border:'1px solid #4195fc'}} value={props.fName} onChange={props.handleChange}/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Last Name:</b></label>
				<input type="text" name="lName" id="lName" style={{width: '150px',border:'1px solid #4195fc'}} value={props.lName} onChange={props.handleChange}/><br/><br/>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>SIN:</b></label>
				<input type="text" name="ssn1" id="ssn1" style={{width: '44px',border:'1px solid #4195fc'}} value={props.ssn1} maxLength='3' onChange={props.handleChange}/>-
				<input type="text" name="ssn2" id="ssn2" style={{width: '44px',border:'1px solid #4195fc'}} value={props.ssn2} maxLength='3' onChange={props.handleChange}/>-
				<input type="text" name="ssn3" id="ssn3" style={{width: '44px',border:'1px solid #4195fc'}} value={props.ssn3} maxLength='3' onChange={props.handleChange}/>			
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Gender:</b></label>
				<select style={{width: '155px',border:'1px solid #4195fc'}} name="gender" value={props.gender} onChange={props.handleChange}>
					<option value="">- Select -</option>	
					<option value="female" >female</option>
					<option value="male" >male</option>				
				</select><br/><br/>             	
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Email (Login ID):</b></label>
				<input type="text" name="email" id="email" style={{width: '150px',border:'1px solid #4195fc'}} value={props.email} onChange={props.handleChange}/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Confirm Email:</b></label>
				<input type="text" name="cemail" id="cemail" style={{width: '150px',border:'1px solid #4195fc'}} value={props.cemail} onChange={props.handleChange}/><br/><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Password:</b></label>			
				<input type="password" name="pwd" id="pwd" style={{width: '150px',border:'1px solid #4195fc'}} value={props.pwd} onChange={props.handleChange}/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Confirm Password:</b></label>			
				<input type="password" name="cpwd" id="cpwd" style={{width: '150px',border:'1px solid #4195fc'}} value={props.cpwd} onChange={props.handleChange}/><br/><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
				<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
				<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
				<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Date of Birth:</b></label>				
				<select style={{width: '55px',border:'1px solid #4195fc'}} name="datebirth1" value={props.datebirth1} onChange={props.handleChange}>
					<option value="">--</option> 
					{TDerived('YB').map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
				</select>-
				<select style={{width: '44px',border:'1px solid #4195fc'}} name="datebirth2" value={props.datebirth2} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
				</select>-
				<select style={{width: '44px',border:'1px solid #4195fc'}} name="datebirth3" value={props.datebirth3} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
				</select> You must be eighteen years of age or older<br/><br/>	
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Address:</b></label>			
				<input type="text" name="address" id="address" style={{width: '150px',border:'1px solid #4195fc'}} value={props.address} onChange={props.handleChange}/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>City:</b></label>
				<input type="text" name="city" id="city" style={{width: '150px',border:'1px solid #4195fc'}} value={props.city} onChange={props.handleChange}/><br/><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
				<input type="text" name="province" id="province" style={{width: '150px',border:'1px solid #4195fc'}} value={states.filter(sub=>sub[0]===props.province).map(sub=>sub[1])} readOnly/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Post Code:</b></label>
				<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode1" value={props.postcode1} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('PC').map(item => (<option value={item} key={item}>{item}</option>))}
				</select>
				<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode2" value={props.postcode2} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('P1').map(item => (<option value={item} key={item}>{item}</option>))}
				</select>
				<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode3" value={props.postcode3} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('PC').map(item => (<option value={item} key={item}>{item}</option>))}
				</select>-
				<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode4" value={props.postcode4} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('P1').map(item => (<option value={item} key={item}>{item}</option>))}
				</select>
				<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode5" value={props.postcode5} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('PC').map(item => (<option value={item} key={item}>{item}</option>))}
				</select>
				<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode6" value={props.postcode6} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('P1').map(item => (<option value={item} key={item}>{item}</option>))}
				</select><br/><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
				<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
				<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
				<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Date Move In:</b></label>				
				<select style={{width: '55px',border:'1px solid #4195fc'}} name="datein1" value={props.datein1} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('Y').map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
				</select>-
				<select style={{width: '44px',border:'1px solid #4195fc'}} name="datein2" value={props.datein2} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
				</select>-
				<select style={{width: '44px',border:'1px solid #4195fc'}} name="datein3" value={props.datein3} onChange={props.handleChange}>
					<option value="">--</option>
					{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
				</select><br/><br/>	
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
				<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
				<label style={{width: '60px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
				<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>9999</small></label><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Cell Phone:</b></label>
				<input type="text" name="primaryphone1" id="primaryphone1" style={{width: '44px',border:'1px solid #4195fc'}} value={props.primaryphone1} maxLength="3" onChange={props.handleChange}/>-
				<input type="text" name="primaryphone2" id="primaryphone2" style={{width: '44px',border:'1px solid #4195fc'}} value={props.primaryphone2} maxLength="3" onChange={props.handleChange}/>-
				<input type="text" name="primaryphone3" id="primaryphone3" style={{width: '44px',border:'1px solid #4195fc'}} value={props.primaryphone3} maxLength="4" onChange={props.handleChange}/><br/><br/>							
				<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Where did you know US:</b></label>						
				<select style={{width: '125px',border:'1px solid #4195fc'}} name="advertisement" value={props.advertisement} onChange={props.handleChange}>
					<option value="">- Select -</option>
					{media.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
				</select><br/><br/>  		
			
		</div>	
	);
}

function BankProfile(props) {			
	const [workings, setWorkings] = useState([]);	
	const [holiday, setHoliday] = useState([]);	
	const [banks, setBanks] = useState([]);	
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/hours.php')
			.then(res => res.json())
			.then((result) => {	
				setWorkings(result);
			})
	}, [])			
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/holidays.php')
			.then(res => res.json())
			.then((result) => {			
				setHoliday(result);
			})
	}, []);
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/bank.php')
			.then(res => res.json())
			.then((result) => {	
				setBanks(result);
			})
	}, [])	
	if (props.currentStep !== 3) {
		return null
	} 	
  return(    
		<div className="right_content">
			<h2>Existing Custmer, Please <Link to="/Member" style={{color:"blue"}}>click here</Link></h2><br/>
			<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+workings[0] + ' ~ ' + workings[1]} </span></h3><br/>
			{(()=>{if(holiday[2] > 0) {return (<div>
				<h3 style={{textAlign:"center"}}><span>{'Today our office is closed on holiday--'+holiday[0]}</span></h3><br />						
			</div>)}})()}
			<Announce/>
			<h2>Banking And Payment Information</h2><br/><br/>		
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
			<select id="bankname" name="bankname" value = {props.bankname} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange}>
					<option value="">--Select Bank--</option>
					{banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}						
			</select>
			{(()=>{if(props.bankname ==="TEST") {return (<div>			
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Other Bank Name:</b></label>
				<input type="text" name="othernames" id="othernames"  value = {props.othernames} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange}/><br/><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>			
				<input type="text" name="FINID" id="FINID" value = {props.FINID} style={{width: '150px',border:'1px solid #4195fc'}} maxLength="3" onChange={props.handleChange}/> This is 3-digit financial institution number<br/><br/>			
			</div>)} else if(props.bankname !=="TEST" && props.bankname !=="") {return (<div>						
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>			
				<input type="text" name="FINID" id="FINID" value = {banks.filter(sub=>sub[0]===props.bankname).map(sub=>sub[2])} style={{width: '150px',border:'1px solid #4195fc'}} maxLength="3" readOnly/> This is 3-digit financial institution number<br/><br/>			
			</div>)}})()}
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Branch Transit #:</b></label>
			<input type="text" name="BTransit" id="BTransit"  maxLength="5" value = {props.BTransit} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /> This is 5-digit branch transit number<br/><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Checking Account #:</b></label>
			<input type="text" name="CNumber" id="CNumber"  maxLength="12" value = {props.CNumber} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /> This is 9 or 12-digit checking account number<br/><br/>
			<label><b>Please see below Sample Check for your reference:</b></label><br/><br/>					
			<p><img src={SampleCheck} alt="check" /></p>				
			<ol>
				<li>"12345"-This is 5-digit branch transist number</li>
				<li>"999"-This is 3-digit financial institution number</li>
				<li>"456789012"-This is chequeing account number</li>
			</ol><br/><br/>				
			<p>No Faxing required! No phone call needed (unless we can not verify provided information)! Online banking will help us easily verify your employment and 
			increase your loan process dramatically. All 5 security questions and answers are optional, but please provide these questions and answers if you have them
	        </p><br/><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
			<input type="text" name="debitcard" id="debitcard"  value = {props.debitcard} style={{width: '150px',border:'1px solid #4195fc'}} maxLength="19" onChange={props.handleChange} />			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Online Password:</b></label>
			<input type="text" name="onlinepwd" id="onlinepwd"  value = {props.onlinepwd} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /><br/><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
			<input type="text" name="sq1" id="sq1" value = {props.sq1} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} />			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
			<input type="text" name="sa1" id="sa1" value = {props.sa1} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /><br/><br/>						
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
			<input type="text" name="sq2" id="sq2" value = {props.sq2} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} />			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
			<input type="text" name="sa2" id="sa2" value = {props.sa2} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /><br/><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
			<input type="text" name="sq3" id="sq3" value = {props.sq3} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} />			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
			<input type="text" name="sa3" id="sa3" value = {props.sa3} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /><br/><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
			<input type="text" name="sq4" id="sq4" value = {props.sq4} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} />			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
			<input type="text" name="sa4" id="sa4" value = {props.sa4} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /><br/><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
			<input type="text" name="sq5" id="sq5" value = {props.sq5} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} />			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
			<input type="text" name="sa5" id="sa5" value = {props.sa5} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange} /><br/><br/>						
		</div>
  )
}

function EmpProfile(props) {		
	const [workings, setWorkings] = useState([]);	
	const [holiday, setHoliday] = useState([]);
	const [loanborrows, setLoanborrows] = useState([]);	
	const [states, setStates] = useState([]);
	const [compName, setCompName] = useState([]);	
	useEffect(() => {					
		fetch(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(res => res.json())
			.then((result) => {	
				setCompName(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/loanborrow.php')
			.then(res => res.json())
			.then((result) => {					
				setLoanborrows(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/hours.php')
			.then(res => res.json())
			.then((result) => {	
				setWorkings(result);
			})
	}, [])			
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/holidays.php')
			.then(res => res.json())
			.then((result) => {				
				setHoliday(result);
			})
	}, [])	
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/findprovinces.php')
			.then(res => res.json())
			.then((result) => {				
				setStates(result);
			})
	}, [])
	if (props.currentStep !== 4) {
		return null
	} 	
  return(
    <React.Fragment>
		<div className="right_content">
			<h2>Existing Custmer, Please <Link to="/Member" style={{color:"blue"}}>click here</Link></h2><br/>
			<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+workings[0] + ' ~ ' + workings[1]} </span></h3><br/>
			{(()=>{if(holiday[2] > 0) {return (<div>
				<h3 style={{textAlign:"center"}}><span>{'Today our office is closed on holiday--'+holiday[0]}</span></h3><br />						
			</div>)}})()}
			<Announce/>
			<h2>Employment and Payment Information</h2><br/><br/>					
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Your Employer:</b></label>
			<input type="text" name="employer" id="employer" style={{width: '150px',border:'1px solid #4195fc'}} value={props.employer} onChange={props.handleChange}/><br/><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
			<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
			<label style={{width: '60px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
			<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>9999</small></label><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Employer Phone:</b></label>
			<input type="text" name="employerphone1" id="employerphone1" style={{width: '44px',border:'1px solid #4195fc'}} value={props.employerphone1} maxLength="3" onChange={props.handleChange}/>-
			<input type="text" name="employerphone2" id="employerphone2" style={{width: '44px',border:'1px solid #4195fc'}} value={props.employerphone2} maxLength="3" onChange={props.handleChange}/>-
			<input type="text" name="employerphone3" id="employerphone3" style={{width: '44px',border:'1px solid #4195fc'}} value={props.employerphone3} maxLength="4" onChange={props.handleChange}/><br/><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
			<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
			<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
			<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Date Started:</b></label>
			<select style={{width: '55px',border:'1px solid #4195fc'}} name="datestart1" value={props.datestart1} onChange={props.handleChange}>
				<option value="">--</option> 
				{TDerived('Y').map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
			</select>-
			<select style={{width: '44px',border:'1px solid #4195fc'}} name="datestart2" value={props.datestart2} onChange={props.handleChange}>
				<option value="">--</option>
				{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
			</select>-
			<select style={{width: '44px',border:'1px solid #4195fc'}} name="datestart3" value={props.datestart3} onChange={props.handleChange}>
				<option value="">--</option>
				{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
			</select>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Job Status:</b></label>					
			<select style={{width: '150px',border:'1px solid #4195fc'}} name="JobStatus" value={props.JobStatus} onChange={props.handleChange}>
				<option value="">- Select -</option>
				<option value="Full-Time" key="1">Full Time</option>
				<option value="Part-Time" key="2">Part Time</option>
				<option value="Contract job" key="3">Contract</option>				
				<option value="agency job" key="4">Agency</option>				
				<option value="Canada EI" key="5">Canada EI</option>
				<option value="Child Tax Benefit" key="6">Child Tax Benefit</option>					
				<option value="WSIB" key="7">Workers Compensation</option>
            	<option value="Disability" key="8">Disability Income</option>
				<option value="SocialWelfare" key="9">Social Welfare,e.g.,O.W.</option>
				<option value="company pension" key="10">Company Pension</option>
				<option value="CPP" key="11">Canada Pension</option>
				<option value="OAS" key="12">Old Age Security</option>
				<option value="Self-employed" key="13">Self Employed</option>
				<option value="Others" key="14">Others</option>								
			</select><br/><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Pay Type:</b></label>
			<select style={{width: '150px',border:'1px solid #4195fc'}} name="paytype" value={props.paytype} onChange={props.handleChange}>
				<option value="">- Select -</option>
				<option value="Salary" key="1">Salary</option>
			    <option value="Hourly" key="2" >Hourly</option>
			</select>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>	
			<select style={{width: '150px',border:'1px solid #4195fc'}} name="directpay" value={props.directpay} onChange={props.handleChange}>
				<option value="">--select--</option>
			    <option value="YES" key="1">Yes</option>
			    <option value="NO" key="2">No</option>
			</select><br/><br/>
	        <label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Pay Frequency:</b></label>	
			<select style={{width: '150px',border:'1px solid #4195fc'}} name="payperiod" value={props.payperiod} onChange={props.handleChange}>				
			    <option value="">- Select -</option>
				<option value="7"  key="1">Weekly</option>
				<option value="14" key="2">Bi-weekly</option>
				<option value="15" key="3">Semi-monthly</option>
				<option value="30" key="4">Monthly</option>
			</select>
	        <label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
			<input type="text" name="totalincome" id="totalincome" style={{width: '150px',border:'1px solid #4195fc'}} value={props.totalincome} onChange={props.handleChange} /><br/><br/>				
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
			<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
			<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
			<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>First Pay Day:</b></label>	
			<select style={{width: '55px',border:'1px solid #4195fc'}} name="fdate1" value={props.fdate1} onChange={props.handleChange}>
				<option value="">--</option> 
				<option value={thisyear}>{thisyear}</option> 
				{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 31) {return (
					<option value={nextyear}>{nextyear}</option> 				
				)}})()}
			</select>-
			<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate2" value={props.fdate2} onChange={props.handleChange}>
				<option value="">--</option>
				{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
			</select>-
			<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate3" value={props.fdate3} onChange={props.handleChange}>
				<option value="">--</option>
				{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
			</select><br/><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
			<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
			<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
			<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Second Pay Day:</b></label>	
			<select style={{width: '55px',border:'1px solid #4195fc'}} name="sdate1" value={props.sdate1} onChange={props.handleChange}>
				<option value="">--</option> 
				<option value={thisyear}>{thisyear}</option> 
				{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 61) {return (
					<option value={nextyear}>{nextyear}</option> 				
				)}})()}
			</select>-
			<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate2" value={props.sdate2} onChange={props.handleChange}>
				<option value="">--</option>
				{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
			</select>-
			<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate3" value={props.sdate3} onChange={props.handleChange}>
				<option value="">--</option>
				{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
			</select><br/><br/>			
			<p>This is your first visit and the maximum loan amount you can borrow is $100</p><br/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Borrow:</b></label>		
			<select id="loanborrow" name="loanborrow" value = {props.loanborrow} style={{width: '150px',border:'1px solid #4195fc'}} onChange={props.handleChange}>
					<option value="">--Select--</option>
					{loanborrows.map(item => (<option value={item[1]} key={item[0]}>{item[1]}</option>))}					
			</select><br/><br/>
			{(()=>{if(props.loanborrow==="") {return (<div>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Loan Cost:</b></label>			
				<input type="text" name="loancost" id="loancost" value = {props.loanborrow} style={{width: '150px',border:'1px solid #4195fc'}} readOnly/><br/><br/>
			</div>)} else {return(<div>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Loan Cost:</b></label>
				<input type="text" name="loancost" id="loancost" value = {props.loanborrow*states.filter(sub=>sub[0]===props.province).map(sub=>sub[2])/100} style={{width: '150px',border:'1px solid #4195fc'}} readOnly/><br/><br/>
			</div>)}})()}
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Reference:</b></label>
			<input type="text" name="r1name" id="r1name" style={{width: '150px',border:'1px solid #4195fc'}} value={props.r1name} onChange={props.handleChange}/>
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Relationship:</b></label>						
			<select style={{width: '155px',border:'1px solid #4195fc'}} name="r1relation" value={props.r1relation} onChange={props.handleChange}>
				<option value="">- Select -</option>
				<option value="1" >Friend</option>
				<option value="2" >Colleague</option>				
				<option value="3" >Spouse</option>				
				<option value="4" >Child</option>				
				<option value="5" >Sibling</option>				
				<option value="6" >Cousin</option>				
				<option value="7" >Parent</option>				
				<option value="8" >Grand Parent</option>				
				<option value="9" >Other Relatives</option>											
				<option value="10" >Others</option>								
			</select><br/><br/> 
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
			<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
			<label style={{width: '60px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
			<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>9999</small></label><br/>			
			<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Reference Phone:</b></label>
			<input type="text" name="rephone1" id="rephone1" style={{width: '44px',border:'1px solid #4195fc'}} maxLength="3" value={props.rephone1} onChange={props.handleChange}/>-
			<input type="text" name="rephone2" id="rephone2" style={{width: '44px',border:'1px solid #4195fc'}} maxLength="3" value={props.rephone2} onChange={props.handleChange}/>-
			<input type="text" name="rephone3" id="rephone3" style={{width: '44px',border:'1px solid #4195fc'}} maxLength="4" value={props.rephone3} onChange={props.handleChange}/>		
			<label><strong>Please read the following agreement carefully before you submit</strong></label><br/><br/>
			<p><input type="checkbox" id="signoff" name="signoff" value = "yes" required /> I have read and agree to all terms 
			and conditions of {props.province === "3" ? <Link to={PDF_3} target="_blank" style={{color:'blue'}}>the Loan Agreement</Link>:<Link to={PDF_1} target="_blank" style={{color:'blue'}}>the Loan Agreement</Link>}, 
			and I also authorize {compName[0]} to verify the accuracy of this information
			provided by me. Here signed electroniclly</p><br/>			
		</div><br/>		
		<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/>
		
    </React.Fragment>
  );
}

export default withRouter(ApplyContents)