import React from 'react'
import axios from 'axios'
import CommonHeader from '../pages/Head'

class ChangePWD extends React.Component {	
	constructor(props) {
		super(props);
		this.state = {oldpassword:'',password:'', password2:'',Resp:'',user:localStorage.getItem('tokens')};		
		this.handleChange=this.handleChange.bind(this);			
		this.handleSubmit=this.handleSubmit.bind(this);	
	}

	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {password,password2,oldpassword} = this.state	
		if(!oldpassword){
			alert('Please enter your old password')
			return false
		}
		if(!password){
			alert('Please enter your new password')
			return false
		} else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password)) {
			alert('Password must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters')
			return false
		}
		if(!password2){
			alert('Sorry, please confirm your new password')
			return false
		} else if (password2 !== password){
			alert('Sorry, your new password is not matched, pleare re-enter your new password' )
			return false
		}			
		const userObject={
				user:this.state.user,
				password:this.state.password,	
				password2: this.state.password2,
				oldpassword: this.state.oldpassword
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/changeEMPpwd.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});
	}
	
	render() {							
		return (
			<div>			
				<CommonHeader titles="Password"/>
				<form onSubmit={this.handleSubmit}>	
					<h3 style={{textAlign:"center"}}>Change Your Password</h3><br/><br/>
					<div style={{textAlign:"center"}}>
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}>Old Password</label>	
						<input type="password" name="oldpassword" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'5px'}} value = {this.state.oldpassword} onChange={this.handleChange} /><br/><br/>
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}>New Password</label>	
						<input type="password" name="password" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'5px'}} value = {this.state.password} onChange={this.handleChange} /><br/><br/>
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}>New Password Again</label>											
						<input type="password" name="password2" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'5px'}} value = {this.state.password2} onChange={this.handleChange} /><br/><br/>						
						<h3>{this.state.Resp}</h3><br/><br/>
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> &nbsp;&nbsp;<br/><br/>					
					</div>
				</form>
			</div>
		)
	}
}

export default ChangePWD;
	