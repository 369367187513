import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';


class TopPart extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/announcement.php')
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {	
		return(
			<div>
				{(()=>{if(this.state.CompName !== "NO" && this.state.CompName !== "EMPTY") {return (<div>
					<h3 style={{textAlign:"center"}}><span>{this.state.CompName}</span></h3><br/>	
				</div>)}})()}
			</div>
		)
	}
}


export default TopPart	