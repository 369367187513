import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";

class MenuBar extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {roles:'',management:'',administrative:'',hrs:'',accountant:'',manager:'',emp:'', user:localStorage.getItem('tokens'),
						CompName:'',names:''};				
	}
	
	componentDidMount() {				
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php',para)
			.then(result => {						
				this.setState({management:result.data[5]})													
				this.setState({administrative:result.data[6]})		
				this.setState({hrs:result.data[7]})		
				this.setState({accountant:result.data[8]})		
				this.setState({manager:result.data[9]})		
				this.setState({emp:result.data[10]})								
				this.setState({roles:result.data[2]})			
				this.setState({names:result.data[1]})	
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}
	render() {				
		return (<div className="header">
				<div className="top"><Link to="/Payday" style={{color:"white"}}>{this.state.CompName+' welcome '+this.state.names}</Link></div>
				<div className="buttons" >
				<ul>
					{(()=>{if(this.state.roles === "CLIENT") {return(<div>
						<li><Link to = "/Payday">Profile</Link></li>							
						<li><Link to = "/Paybank">Bank</Link></li>
						<li><Link to = "/Reloan">Reloan</Link></li>														
						<li><Link to = "/Setting">Settings</Link></li>
						<li><Link to = "/Logout">Logout</Link></li>
					</div>)} else {return(<div>
						{(()=>{if(this.state.emp === "1") {return(
								<li><Link to = "/Application">Application</Link></li>
						)}})()}
						{(()=>{if(this.state.administrative==="1") {return(
								<li><Link to = "/Admin">Admin</Link></li>
						)}})()}
						{(()=>{if(this.state.management==="1") {return(<div>
								<li><Link to = "/Management">Management</Link></li>
								<li><Link to = "/report">Report</Link></li>
						</div>)}})()}						
						{(()=>{if(this.state.emp === "1") {return(
								<li><Link to = "/Search">Search</Link></li>
						)}})()}
						<li><Link to = "/Manages">Logout</Link></li>
					</div>)}})()}				
										
				</ul>
				
			</div>
		</div>)
	}	
}


export default MenuBar