import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';

class SetupRoles extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {manageaction:'ROLE',CompName:'',NewApp:[],user:localStorage.getItem('tokens'), Resp:'',updaterole:'',newrole:'',management:'',
				admin:'', human:'',account:'',manager:'',employee:'',roleforupdate:''};							
		this.handleChange=this.handleChange.bind(this)
		this.handleRoleChange=this.handleRoleChange.bind(this)
		this.handleAddChange=this.handleAddChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/setuproles.php')
			.then(result => {																		
					this.setState({NewApp:result.data})				
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}
	handleAddChange = event => {				
		const {name, value} = event.target
		this.setState({[name]: value}) 		
		this.setState({management:''})
		this.setState({admin:''})
		this.setState({human:''})
		this.setState({account:''})
		this.setState({manager:''})
		this.setState({employee:''})			
		this.setState({Resp:''})
	}
	handleChange = event => {				
		const {name, value} = event.target
		this.setState({[name]: value}) 		
		this.setState({Resp:''})
	}
	handleRoleChange= event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value !=="") {
			this.setState({newrole:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0]})
			this.setState({management:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[2])[0]})
			this.setState({admin:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[3])[0]})
			this.setState({human:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[4])[0]})
			this.setState({account:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[5])[0]})
			this.setState({manager:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[6])[0]})
			this.setState({employee:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[7])[0]})			
		}
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {updaterole,newrole,management,admin, human,account,manager,employee,roleforupdate} = this.state		
		if(!updaterole){
			alert('Please select action')
			return false 
		} else if(updaterole === '1') {
			if(!newrole){
				alert('Please enter new role name')
				return false
			} 
			if(!management){
				alert('Please select whether this new role has management function')
				return false 
			} 
			if(!admin){
				alert('Please select whether this new role has admin function')
				return false 
			} 
			if(!human){
				alert('Please select whether this new role has human resource function')
				return false 
			} 
			if(!account){
				alert('Please select whether this new role has accountant function')
				return false 
			} 
			if(!manager){
				alert('Please select whether this new role has manager function')
				return false 
			} 
			if(!employee){
				alert('Please select whether this new role has employee function')
				return false 
			} 
		} else if(updaterole === '2') {
			if(!roleforupdate){
				alert('Please select role for updating')
				return false
			} 			
			if(!newrole){
				alert('Please enter the new name for the selected role')
				return false 
			} 
			if(!management){
				alert('Please select whether this new role has management function')
				return false 
			} 
			if(!admin){
				alert('Please select whether this new role has admin function')
				return false 
			} 
			if(!human){
				alert('Please select whether this new role has human resource function')
				return false 
			} 
			if(!account){
				alert('Please select whether this new role has accountant function')
				return false 
			} 
			if(!manager){
				alert('Please select whether this new role has manager function')
				return false 
			} 
			if(!employee){
				alert('Please select whether this new role has employee function')
				return false 
			} 
		} else if(updaterole === '3') {
			if(!roleforupdate){
				alert('Please select role to be deleted')
				return false
			} 
		}
		const userObject = {	
			user:this.state.user,
			manageaction:this.state.manageaction,
			updaterole:this.state.updaterole,
			newrole:this.state.newrole,
			management:this.state.management,
			admin: this.state.admin,
			human: this.state.human,
			account: this.state.account,	
			manager: this.state.manager,
			employee: this.state.employee,
			roleforupdate:this.state.roleforupdate
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/managementprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Management Role"/>	
				<form onSubmit={this.handleSubmit}>
					<h3>Select Action</h3>
					<select name="updaterole" style={{width: '150px', border:'1px solid grey'}} value = {this.state.updaterole} onChange={this.handleAddChange}>
						<option value="">--Select--</option>		
						<option value="1">Add New Role</option>		
						<option value="2">Update Role</option>		
						<option value="3">Remove Role</option>						
					</select><br/><br/>	
					{(()=>{if(this.state.updaterole === "1") {return (<div>	
						<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Add New Role Name</b></label><br/>
						<input type="text" name="newrole" style={{width: '125px',border:'1px solid #4195fc'}} value={this.state.newrole} onChange={this.handleChange} /><br/><br/>
						<label style={{textAlign:'center'}}><b>With the following functions</b></label><br/><br/>
						<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Management</b></label>
						<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Admin</b></label>
						<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Human Resource</b></label>
						<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Accountant</b></label>
						<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Manager</b></label>
						<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Employee</b></label><br/>
						
						<select name="management" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.management} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Yes</option>		
							<option value="0">No</option>							
						</select>
						<select name="admin" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.admin} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Yes</option>		
							<option value="0">No</option>							
						</select>
						<select name="human" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.human} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Yes</option>		
							<option value="0">No</option>							
						</select>
						<select name="account" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.account} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Yes</option>		
							<option value="0">No</option>							
						</select>
						<select name="manager" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.manager} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Yes</option>		
							<option value="0">No</option>							
						</select>
						<select name="employee" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.employee} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Yes</option>		
							<option value="0">No</option>							
						</select><br/><br/>
					</div>)} else if(this.state.updaterole === "2") {return (<div>	
						<h3>Select Role for updating</h3>
						<select name="roleforupdate" style={{width: '150px', border:'1px solid grey'}} value = {this.state.roleforupdate} onChange={this.handleRoleChange}>
							<option value="">--Select--</option>		
							{this.state.NewApp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}							
						</select><br/><br/>	
						{(()=>{if(this.state.roleforupdate !== "") {return (<div>	
							<label style={{textAlign:'center'}}><b>Please update the following functions</b></label><br/><br/>
							<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Role Name</b></label>
							<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Management</b></label>
							<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Admin</b></label>
							<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Human Resource</b></label>
							<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Accountant</b></label>
							<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Manager</b></label>
							<label style={{width: '125px',display:'inline-block',textAlign:'center'}}><b>Employee</b></label><br/>							
							<input type="text" name="newrole" style={{width: '125px',border:'1px solid #4195fc'}} value={this.state.newrole} onChange={this.handleChange} />
							<select name="management" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.management} onChange={this.handleChange}>
								<option value="">--Select--</option>		
								<option value="1">Yes</option>		
								<option value="0">No</option>							
							</select>
							<select name="admin" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.admin} onChange={this.handleChange}>
								<option value="">--Select--</option>		
								<option value="1">Yes</option>		
								<option value="0">No</option>							
							</select>
							<select name="human" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.human} onChange={this.handleChange}>
								<option value="">--Select--</option>		
								<option value="1">Yes</option>		
								<option value="0">No</option>							
							</select>
							<select name="account" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.account} onChange={this.handleChange}>
								<option value="">--Select--</option>		
								<option value="1">Yes</option>		
								<option value="0">No</option>							
							</select>
							<select name="manager" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.manager} onChange={this.handleChange}>
								<option value="">--Select--</option>		
								<option value="1">Yes</option>		
								<option value="0">No</option>							
							</select>
							<select name="employee" style={{width: '115px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.employee} onChange={this.handleChange}>
								<option value="">--Select--</option>		
								<option value="1">Yes</option>		
								<option value="0">No</option>							
							</select><br/><br/>
						</div>)}})()}
					</div>)} else if(this.state.updaterole === "3") {return (<div>	
						<h3>Select Role to delete</h3>
						<select name="roleforupdate" style={{width: '150px', border:'1px solid grey'}} value = {this.state.roleforupdate} onChange={this.handleRoleChange}>
							<option value="">--Select--</option>		
							{this.state.NewApp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}							
						</select><br/><br/>	
					</div>)}})()}
					<h3>{this.state.Resp}</h3><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
				</form>				
			</div>
		)
	}
}

export default SetupRoles