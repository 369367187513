import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';

class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'CANCEL',CompName:'',NewApp:[],user:localStorage.getItem('tokens'),profileinfo:[],Resp:'',newapps:'',cancels:''};									
		this.handleAPPChange=this.handleAPPChange.bind(this)		
		this.handleChange=this.handleChange.bind(this)				
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/cancelloaninformation.php')
			.then(result => {		
				if(result.data[0][0] === "No") {
					this.setState({Resp:"No Payday Loan"})
				} else {
					this.setState({Resp:""})
					this.setState({NewApp:result.data})
				}
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})								
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleAPPChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})			
		const userObject = {	
				PID:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0]				
			 }
		axios.post(process.env.REACT_APP_API_ENV+'api/padinformation.php',userObject)
			.then(result => {						
				this.setState({profileinfo:result.data})				
				this.setState({comments:result.data[17]})
			})				
	}
	
			
	handleSubmit = event => {
		event.preventDefault()		
		const {newapps,cancels} = this.state					
		if(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[11])[0] === "PAD"){
			if(!cancels){
				alert('Please select cancel or not')
				return false 
			} 
		}		
		const userObject = {	
			user:this.state.user,
			applicationid:this.state.newapps,
			Appstatus: this.state.Appstatus,
			loanstatus: this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[12])[0],
			cancels:this.state.cancels,
			firstname:this.state.profileinfo[1],
			approvedloan:this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[5])[0],
			approveddate: this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0],		
	
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/approveprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {			
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>
				<label><b>Please Select Application ID</b></label><br/>
				<select id="newapps" name="newapps" style={{width: '150px', border:'1px solid grey'}} value = {this.state.newapps} onChange={this.handleAPPChange}>
					<option value="">--Select Application ID--</option>		
					{this.state.NewApp.map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
				</select><br/><br/>			
				<form onSubmit={this.handleSubmit}>				
					{(()=>{if(this.state.newapps !== "" && this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[12])[0] === "000") {return (<div>	
						{(()=>{if((dayjs().diff(dayjs(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]),'day') <=3 && dayjs(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[13])[0]).diff(dayjs(),'day') > 1 && dayjs(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]).format('d') === 5) || 
						(dayjs().diff(dayjs(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]),'day') <=1 && dayjs(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[13])[0]).diff(dayjs(),'day') > 1 && dayjs(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]).format('d') !== 5)) {return (<div>								
							<h3>Receipt of Loan Cancellation</h3><br/>
							<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>{this.state.CompName} acknowledges receipt of Notice of Cancellation and repayment 
							of Loan Advance within the Cancellation Period from {this.state.profileinfo[1]+' '+ this.state.profileinfo[2]}, (Borrower).</label><br/><br/>
							<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Loan Date: {this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]}</label><br/><br/>
							<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Loan Amount: {this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[5])[0]}</label><br/><br/>
							<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>{this.state.CompName} Team</label><br/><br/>
							<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Dates: {dayjs().format('MM/DD/YYYY')}</label><br/><br/>
							<h3>{this.state.Resp}</h3><br/><br/>
							<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Cancel Loan</button><br/><br/>
						</div>)} else {return (<div>
							<h3>New or Approved Loan has passed the Cancellation Period, we can not cancel the loan</h3><br/>
						</div>)}})()}
					</div>)} else if(this.state.newapps !== "" && this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[12])[0] === "111") {return (<div>	
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Since this application has been set up PAD process, please communicate with the client manually. 
						If the loan has been in client bank account and the client still wants to cancel the loan, please ask the client repay the loan with full amount and then click the Cancel Loan button to 
						update the database information</label><br/><br/>
						<label><b>Cancel Loan after Communication</b></label><br/>
						<select id="cancels" name="cancels" style={{width: '150px', border:'1px solid grey'}} value = {this.state.cancels} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Send Email to Ask Loan Repayment First</option>		
							<option value="2">Cancel the Loan</option>
						</select><br/><br/>		
						<h3>{this.state.Resp}</h3><br/><br/>
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Cancel Loan</button><br/><br/>
					</div>)}})()}
				</form>
			</div>
		)
	}
}

export default NewApplication