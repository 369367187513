import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import SendEmail from '../manages/sendemail'
import NewApplication from '../manages/newapplication'
import ApprovedLoan from '../manages/approvedLoancashhub'//since we ftp with cash2u together, 
//import ApprovedLoan from '../manages/approvedLoan'//if we ftp self, then uncomment this and comment above one
import PADLoan from '../manages/PADLoan'
import NSFLoan from '../manages/NSFLoan'
import AgencyFor from '../manages/foragency'
import CollectionAgency from '../manages/collectionagency'
import CancelLoan from '../manages/cancelLoan'

class ApplicationProcess extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'', workinghours:[],process:''};		
		this.handleChange=this.handleChange.bind(this)		
	}	
	
	componentDidMount() {						
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')			
			.then(result => {	
				this.setState({workinghours:result.data});
			})					
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  		
	}
	
	render() {		
		return(
			<div className="right_content" style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>
				<h2  style={{textAlign:'center'}}>Application Process</h2><br/><br/>				
				<label>Please strictly follow the steps in Application Process for the daily operation</label><br/><br/>
				<div className="text2" style={{textAlign:'center'}}>
					<label><b>Application Process</b></label><br/>
					<select style={{width: '150px',border:'1px solid #4195fc'}} name="process" value={this.state.process} onChange={this.handleChange}>				
						<option value="">- Select -</option>						
						<option value="EMAIL">{'Sending Emails after '+ this.state.workinghours[0]} </option>
						<option value="NEW">New Application</option>						
						<option value="PAD">Approved Loan Scheduled Repayment</option>
						<option value="NSF">NSF Scheduled Repayment</option>						
						<option value="APPROVED">Send Data to Cash2u for FTP Process</option>
						<option value="COLLECTIONAGENCY">Clients to Agency</option>
						<option value="INAGENCY">Clients In Collection Agency</option>	
						<option value="CANCEL">Cancel Payday Loan Application</option>	
					</select><br/>
					<label style={{width: '600px',display:'inline-block'}}>{this.state.process === "APPROVED" ? "Please make sure that both Approved Loan Scheduled Repayment and NSF Scheduled Repayment functions are checked so that we can generate PAD/PDS files including new approved loans and NSF cases":""}</label>
					<br/><br/>
					{(()=>{if(this.state.process === "EMAIL") {return (
							<SendEmail/>					
						)} else if(this.state.process === "NEW") {return (
							<NewApplication/>					
						)} else if(this.state.process === "APPROVED") {return (
							<ApprovedLoan/>					
						)} else if(this.state.process === "PAD") {return (
							<PADLoan />					
						)} else if(this.state.process === "NSF") {return (
							<NSFLoan />					
						)} else if(this.state.process === "COLLECTIONAGENCY") {return (
							<AgencyFor />					
						)} else if(this.state.process === "INAGENCY") {return (
							<CollectionAgency />	
						)} else if(this.state.process === "CANCEL") {return (
							<CancelLoan />	
						)}
					})()}					
				</div>
			</div>
		)
	}
}

export default ApplicationProcess