import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import dayjs from 'dayjs';

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}

class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {Resp:'',CompName:'',Names:'', ssn:'',gender:'',email:'',datebirth:'',address:'',city:'',province:'',postcode1:'',postcode2:'',postcode3:'',
			postcode4:'',postcode5:'',postcode6:'',states:[],employer:'',employerphone1:'',employerphone2:'',employerphone3:'',datestart1:'',datestart2:'',
			datestart3:'',JobStatus:'',paytype:'',directpay:'',payperiod:'',totalincome:'',r1name:'',rephone1:'',rephone2:'',rephone3:'',r1relation:'',
			user:localStorage.getItem('tokens')};				
	}

	componentDidMount() {		
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php',para)
			.then(result => {						
				this.setState({Names:result.data[1]})				
				this.setState({ssn:result.data[18]})
				this.setState({gender:result.data[19]})
				this.setState({email:result.data[0]})
				this.setState({datebirth:result.data[20]})
				this.setState({address:result.data[21]})
				this.setState({city:result.data[22]})
				this.setState({province:result.data[23]})
				this.setState({postcode1:result.data[24].substring(0,1)})
				this.setState({postcode2:result.data[24].substring(1,2)})
				this.setState({postcode3:result.data[24].substring(2,3)})
				this.setState({postcode4:result.data[24].substring(4,5)})
				this.setState({postcode5:result.data[24].substring(5,6)})
				this.setState({postcode6:result.data[24].substring(6,7)})
				this.setState({datein1:result.data[25].substring(6,10)})
				this.setState({datein2:result.data[25].substring(0,2)})
				this.setState({datein3:result.data[25].substring(3,5)})
				this.setState({primaryphone1:result.data[26].substring(1,4)})
				this.setState({primaryphone2:result.data[26].substring(5,8)})
				this.setState({primaryphone3:result.data[26].substring(9,13)})
				this.setState({employer:result.data[44]})
				this.setState({employerphone1:result.data[45].substring(1,4)})
				this.setState({employerphone2:result.data[45].substring(5,8)})
				this.setState({employerphone3:result.data[45].substring(9,13)})
				this.setState({datestart1:result.data[46].substring(6,10)})
				this.setState({datestart2:result.data[46].substring(0,2)})
				this.setState({datestart3:result.data[46].substring(3,5)})
				this.setState({JobStatus:result.data[47]})
				this.setState({paytype:result.data[48]})
				this.setState({directpay:result.data[51]})
				this.setState({payperiod:result.data[49]})
				this.setState({totalincome:result.data[50]})
				this.setState({r1name:result.data[27]})
				this.setState({rephone1:result.data[28].substring(1,4)})
				this.setState({rephone2:result.data[28].substring(5,8)})
				this.setState({rephone3:result.data[28].substring(9,13)})
				this.setState({r1relation:result.data[29]})
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/findprovinces.php')
			.then(result => {		
				this.setState({states:result.data});
			})
	
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {province, address,city,postcode1,postcode2,postcode3,postcode4,postcode5,postcode6,datein1,datein2,datein3,primaryphone1,primaryphone2,
			primaryphone3,employer,employerphone1,employerphone2,employerphone3,datestart1,datestart2,datestart3,JobStatus,paytype,directpay,payperiod,
			totalincome,r1name,rephone1,rephone2,rephone3,r1relation} = this.state		
		if(!address){
			alert('Please enter your address')
			return false
		} 	
		if(!city){
			alert('Please enter your city')
			return false
		} 
		if(province ==='0') {
			alert('Please select your province')
			return false
		}
		if(!postcode1){
			alert('Please select your first letter of your post code')
			return false
		}	
		if(!postcode2){
			alert('Please select your second number of your post code')
			return false
		}	
		if(!postcode3){
			alert('Please select your third letter of your post code')
			return false
		}	
		if(!postcode4){
			alert('Please select your fourth number of your post code')
			return false
		}	
		if(!postcode5){
			alert('Please select your fifth letter of your post code')
			return false
		}	
		if(!postcode6){
			alert('Please select your sixth number of your post code')
			return false
		}	
		if(!datein1){
			alert('Please select which year you moved into this address')
			return false
		}	
		if(!datein2){
			alert('Please select which month you moved into this address')
			return false
		}	
		if(!datein3){
			alert('Please select which day you moved into this address')
			return false
		}			
		if(!primaryphone1){
			alert('Please enter your cell phone 3-digit area numbers')
			return false
		}	
		if(!primaryphone2){
			alert('Please enter your cell phone first 3-digit numbers')
			return false
		}	
		if(!primaryphone3){
			alert('Please enter your cell phone last four numbers')
			return false
		}
		if(!employer){
			alert('Please enter your employer name')
			return false
		}
		if(!employerphone1){
			alert('Please enter your employer phone area number')
			return false
		} 
		if(!employerphone2){
			alert('Please enter your employer phone first three numbers')
			return false
		} 
		if(!employerphone3){
			alert('Please enter your employer phone last four numbers')
			return false
		} 
		if(!datestart1){
			alert('Please select your job starting year')
			return false
		} 
		if(!datestart2){
			alert('Please select your job starting month')
			return false
		} 
		if(!datestart3){
			alert('Please select your job starting day')
			return false
		} 
		if(!JobStatus){
			alert('Please tell job status')
			return false
		} 
		if(!paytype){
			alert('Please tell whether your payment is hourly base or yearly (salary) base')
			return false
		} 
		if(!directpay){
			alert('Please tell us whehter your payment is direct deposit')
			return false
		} 
		if(!payperiod){
			alert('Please select your payment frequency')
			return false
		} 
		if(!totalincome){
			alert('Please enter your payment amount')
			return false
		} 
		if(!r1name){
			alert('Please enter your reference name')
			return false
		} 
		if(!rephone1){
			alert('Please enter your reference phone area numbers')
			return false
		} 
		if(!rephone2){
			alert('Please enter your reference phone first three numbers')
			return false
		} 
		if(!rephone3){
			alert('Please enter your reference phone last four numbers')
			return false
		} 
		if(!r1relation){
			alert('Please select your relationship with your reference')
			return false
		} else {			
			const userObject = {	
				user:this.state.user,
				address: this.state.address,
				city: this.state.city,
				province: this.state.province,				
				postcode1: this.state.postcode1,				
				postcode2: this.state.postcode2,
				postcode3: this.state.postcode3,
				postcode4: this.state.postcode4,
				postcode5: this.state.postcode5,
				postcode6: this.state.postcode6,
				datein1: this.state.datein1,								
				datein2: this.state.datein2,
				datein3: this.state.datein3,				
				primaryphone1: this.state.primaryphone1,
				primaryphone2: this.state.primaryphone2,
				primaryphone3: this.state.primaryphone3,
				advertisement: this.state.advertisement,
				employer:this.state.employer,
				employerphone1:this.state.employerphone1,
				employerphone2:this.state.employerphone2,
				employerphone3:this.state.employerphone3,
				datestart1:this.state.datestart1,
				datestart2:this.state.datestart2,
				datestart3:this.state.datestart3,
				JobStatus:this.state.JobStatus,						
				paytype:this.state.paytype,
				directpay:this.state.directpay,
				payperiod:this.state.payperiod,
				totalincome:this.state.totalincome,					
				r1name:this.state.r1name,	
				rephone1:this.state.rephone1,
				rephone2:this.state.rephone2,
				rephone3:this.state.rephone3,
				r1relation:this.state.r1relation
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/updateprofile.php', userObject)
				.then((res) => {
						this.setState({Resp:res.data})											
				}).catch((error) => {
					console.log(error)
				});
			
		}
	}
	render() {		
		return(
			<div >
				<CommonHeader titles="Profile"/>
				<h2>Updating Profile Information</h2><br /><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Name:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.Names}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>SIN:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.ssn}</label><br/><br/>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Gender:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.gender}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.email}</label><br/><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Datebirth:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.datebirth}</label><br/><br/>								
				<form onSubmit={this.handleSubmit}>	
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Address:</b></label>			
					<input type="text" name="address" id="address" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.address} onChange={this.handleChange}/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>City:</b></label>
					<input type="text" name="city" id="city" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.city} onChange={this.handleChange}/><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
					<select id="province" name="province" style={{width: '150px', border:'1px solid #4195fc'}} value = {this.state.province} onChange={this.handleChange}>
						<option value="0">--Select province--</option>		
						{this.state.states.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}								
					</select>			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Post Code:</b></label>
					<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode1" value={this.state.postcode1} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('PC').map(item => (<option value={item} key={item}>{item}</option>))}
					</select>
					<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode2" value={this.state.postcode2} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('P1').map(item => (<option value={item} key={item}>{item}</option>))}
					</select>
					<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode3" value={this.state.postcode3} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('PC').map(item => (<option value={item} key={item}>{item}</option>))}
					</select>-
					<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode4" value={this.state.postcode4} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('P1').map(item => (<option value={item} key={item}>{item}</option>))}
					</select>
					<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode5" value={this.state.postcode5} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('PC').map(item => (<option value={item} key={item}>{item}</option>))}
					</select>
					<select style={{width: '35px',border:'1px solid #4195fc'}} name="postcode6" value={this.state.postcode6} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('P1').map(item => (<option value={item} key={item}>{item}</option>))}
					</select><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Date Move In:</b></label>				
					<select style={{width: '55px',border:'1px solid #4195fc'}} name="datein1" value={this.state.datein1} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('Y').map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="datein2" value={this.state.datein2} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="datein3" value={this.state.datein3} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select><br/><br/>	
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
					<label style={{width: '60px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>9999</small></label><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Cell Phone:</b></label>
					<input type="text" name="primaryphone1" id="primaryphone1" style={{width: '44px',border:'1px solid #4195fc'}} value={this.state.primaryphone1} maxLength="3" onChange={this.handleChange}/>-
					<input type="text" name="primaryphone2" id="primaryphone2" style={{width: '44px',border:'1px solid #4195fc'}} value={this.state.primaryphone2} maxLength="3" onChange={this.handleChange}/>-
					<input type="text" name="primaryphone3" id="primaryphone3" style={{width: '44px',border:'1px solid #4195fc'}} value={this.state.primaryphone3} maxLength="4" onChange={this.handleChange}/><br/><br/>											
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Your Employer:</b></label>
					<input type="text" name="employer" id="employer" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.employer} onChange={this.handleChange}/><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
					<label style={{width: '60px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>9999</small></label><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Employer Phone:</b></label>
					<input type="text" name="employerphone1" id="employerphone1" style={{width: '44px',border:'1px solid #4195fc'}} value={this.state.employerphone1} maxLength="3" onChange={this.handleChange}/>-
					<input type="text" name="employerphone2" id="employerphone2" style={{width: '44px',border:'1px solid #4195fc'}} value={this.state.employerphone2} maxLength="3" onChange={this.handleChange}/>-
					<input type="text" name="employerphone3" id="employerphone3" style={{width: '44px',border:'1px solid #4195fc'}} value={this.state.employerphone3} maxLength="4" onChange={this.handleChange}/><br/><br/>			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Date Started:</b></label>
					<select style={{width: '55px',border:'1px solid #4195fc'}} name="datestart1" value={this.state.datestart1} onChange={this.handleChange}>
						<option value="">--</option> 
						{TDerived('Y').map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="datestart2" value={this.state.datestart2} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="datestart3" value={this.state.datestart3} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Job Status:</b></label>					
					<select style={{width: '150px',border:'1px solid #4195fc'}} name="JobStatus" value={this.state.JobStatus} onChange={this.handleChange}>
						<option value="">- Select -</option>
						<option value="Full-Time" key="1">Full Time</option>
						<option value="Part-Time" key="2">Part Time</option>
						<option value="Contract job" key="3">Contract</option>				
						<option value="agency job" key="4">Agency</option>				
						<option value="Canada EI" key="5">Canada EI</option>
						<option value="Child Tax Benefit" key="6">Child Tax Benefit</option>					
						<option value="WSIB" key="7">Workers Compensation</option>
						<option value="Disability" key="8">Disability Income</option>
						<option value="SocialWelfare" key="9">Social Welfare,e.g.,O.W.</option>
						<option value="company pension" key="10">Company Pension</option>
						<option value="CPP" key="11">Canada Pension</option>
						<option value="OAS" key="12">Old Age Security</option>
						<option value="Self-employed" key="13">Self Employed</option>
						<option value="Others" key="14">Others</option>								
					</select><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Pay Type:</b></label>
					<select style={{width: '150px',border:'1px solid #4195fc'}} name="paytype" value={this.state.paytype} onChange={this.handleChange}>
						<option value="">- Select -</option>
						<option value="Salary" key="1">Salary</option>
						<option value="Hourly" key="2" >Hourly</option>
					</select>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>	
					<select style={{width: '150px',border:'1px solid #4195fc'}} name="directpay" value={this.state.directpay} onChange={this.handleChange}>
						<option value="">--select--</option>
						<option value="YES" key="1">Yes</option>
						<option value="NO" key="2">No</option>
					</select><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Pay Frequency:</b></label>	
					<select style={{width: '150px',border:'1px solid #4195fc'}} name="payperiod" value={this.state.payperiod} onChange={this.handleChange}>				
						<option value="">- Select -</option>
						<option value="7"  key="1">Weekly</option>
						<option value="14" key="2">Bi-weekly</option>
						<option value="15" key="3">Semi-monthly</option>
						<option value="30" key="4">Monthly</option>
					</select> 
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
					<input type="text" name="totalincome" id="totalincome" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.totalincome} onChange={this.handleChange} /><br/><br/>					
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Reference:</b></label>
					<input type="text" name="r1name" id="r1name" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.r1name} onChange={this.handleChange}/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Relationship:</b></label>						
					<select style={{width: '155px',border:'1px solid #4195fc'}} name="r1relation" value={this.state.r1relation} onChange={this.handleChange}>
						<option value="">- Select -</option>
						<option value="1" >Friend</option>
						<option value="2" >Colleague</option>				
						<option value="3" >Spouse</option>				
						<option value="4" >Child</option>				
						<option value="5" >Sibling</option>				
						<option value="6" >Cousin</option>				
						<option value="7" >Parent</option>				
						<option value="8" >Grand Parent</option>				
						<option value="9" >Other Relatives</option>											
						<option value="10" >Others</option>								
					</select><br/><br/> 
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
					<label style={{width: '60px',display:'inline-block',textAlign:'center'}}><small>999</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>9999</small></label><br/>			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Reference Phone:</b></label>
					<input type="text" name="rephone1" id="rephone1" style={{width: '44px',border:'1px solid #4195fc'}} maxLength="3" value={this.state.rephone1} onChange={this.handleChange}/>-
					<input type="text" name="rephone2" id="rephone2" style={{width: '44px',border:'1px solid #4195fc'}} maxLength="3" value={this.state.rephone2} onChange={this.handleChange}/>-
					<input type="text" name="rephone3" id="rephone3" style={{width: '44px',border:'1px solid #4195fc'}} maxLength="4" value={this.state.rephone3} onChange={this.handleChange}/><br/><br/>	
					
					<h2>{this.state.Resp}</h2><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b></b></label>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Update</button><br/><br/>
				</form>
			</div>
		)
	}
}

export default HomtContents