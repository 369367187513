import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}


class SetupOperation extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {manageaction:'HOLIDAY',CompName:'',NewApp:[],user:localStorage.getItem('tokens'), Resp:'',updaterole:'',management:'',
				human:'',roleforupdate:'',fdate1:'',fdate2:'',fdate3:''};							
		this.handleChange=this.handleChange.bind(this)
		this.handleRoleChange=this.handleRoleChange.bind(this)
		this.handleAddChange=this.handleAddChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/findHolidays.php')
			.then(result => {																		
					this.setState({NewApp:result.data})				
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}
	handleAddChange = event => {				
		const {name, value} = event.target
		this.setState({[name]: value}) 		
		this.setState({management:''})		
		this.setState({human:''})				
		this.setState({Resp:''})
		this.setState({fdate1:''})
		this.setState({fdate2:''})
		this.setState({fdate3:''})
	}
	handleChange = event => {				
		const {name, value} = event.target
		this.setState({[name]: value}) 		
		this.setState({Resp:''})
	}
	handleRoleChange= event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value !=="") {			
			this.setState({management:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[0])[0]})			
			this.setState({human:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0]})						
		}
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {updaterole,management,human,roleforupdate,fdate1,fdate2,fdate3} = this.state		
		if(!updaterole){
			alert('Please select action')
			return false 
		} else if(updaterole === '1') {			
			if(!management){
				alert('Please enter new holiday name')
				return false 
			} 
			if(!fdate1){
				alert('Please select year')
				return false 
			} 
			if(!fdate2){
				alert('Please select month')
				return false 
			} 			
			if(!fdate3){
				alert('Please select day')
				return false 
			} 			
		} else if(updaterole === '2') {
			if(!roleforupdate){
				alert('Please select holiday for updating')
				return false
			} 					
			if(!management){
				alert('Please enter holiday name')
				return false 
			} 			
			if(!human){
				alert('Please enter holiday date')
				return false 
			} 
		} else if(updaterole === '3') {
			if(!roleforupdate){
				alert('Please select holiday to be deleted')
				return false
			} 
		}
		const userObject = {	
			user:this.state.user,
			manageaction:this.state.manageaction,
			updaterole:this.state.updaterole,			
			management:this.state.management,			
			human: this.state.human,						
			roleforupdate:this.state.roleforupdate,
			fdate1:this.state.fdate1,
			fdate2:this.state.fdate2,
			fdate3:this.state.fdate3
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/managementprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Management Role"/>	
				<form onSubmit={this.handleSubmit}>
					<h3>Select Action</h3>
					<select name="updaterole" style={{width: '150px', border:'1px solid grey'}} value = {this.state.updaterole} onChange={this.handleAddChange}>
						<option value="">--Select--</option>		
						<option value="1">Add New Holidays</option>		
						<option value="2">Update Holidays</option>		
						<option value="3">Remove Holidays</option>						
					</select><br/><br/>	
					{(()=>{if(this.state.updaterole === "1") {return (<div>													
						<label style={{width: '200px',display:'inline-block',textAlign:'center'}}>New Holiday Name</label>						
						<label style={{width: '70px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
						<label style={{width: '60px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
						<label style={{width: '70px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>						
						<input type="text" name="management" style={{width: '200px',border:'1px solid #4195fc',marginRight:'10px'}} value={this.state.management} onChange={this.handleChange} />						
						<select style={{width: '70px',border:'1px solid #4195fc'}} name="fdate1" value={this.state.fdate1} onChange={this.handleChange}>
							<option value="">--</option> 
							<option value={thisyear}>{thisyear}</option> 							
							<option value={nextyear}>{nextyear}</option> 							
						</select>-
						<select style={{width: '60px',border:'1px solid #4195fc'}} name="fdate2" value={this.state.fdate2} onChange={this.handleChange}>
							<option value="">--</option>
							{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
							</select>-
						<select style={{width: '70px',border:'1px solid #4195fc'}} name="fdate3" value={this.state.fdate3} onChange={this.handleChange}>
							<option value="">--</option>
							{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
						</select><br/><br/>
					</div>)} else if(this.state.updaterole === "2") {return (<div>	
						<h3>Select Holiday for updating</h3>
						<select name="roleforupdate" style={{width: '150px', border:'1px solid grey'}} value = {this.state.roleforupdate} onChange={this.handleRoleChange}>
							<option value="">--Select--</option>		
							{this.state.NewApp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]}</option>))}							
						</select><br/><br/>	
						{(()=>{if(this.state.roleforupdate !== "") {return (<div>								
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>Holiday</b></label>							
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>Date</b></label><br/>							
							<input type="text" name="management" style={{width: '190px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.management} onChange={this.handleChange} />							
							<input type="text" name="human" style={{width: '190px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.human} onChange={this.handleChange} />
							<br/><br/>
						</div>)}})()}
					</div>)} else if(this.state.updaterole === "3") {return (<div>	
						<h3>Select Holiday to be deleted</h3>
						<select name="roleforupdate" style={{width: '150px', border:'1px solid grey'}} value = {this.state.roleforupdate} onChange={this.handleRoleChange}>
							<option value="">--Select--</option>		
							{this.state.NewApp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]}</option>))}							
						</select><br/><br/>	
					</div>)}})()}
					<h3>{this.state.Resp}</h3><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
				</form>				
			</div>
		)
	}
}

export default SetupOperation