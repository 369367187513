import CommonHeader from '../pages/Head'
import React from 'react'
import SetupTables from '../manages/setupTables'
import ChangePWD from '../manages/changepassword'
import axios from 'axios'

class PaydayLoans extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminjobs:'',LID:localStorage.getItem('tokens'), Names:'', CompName:''};				
		this.handleChange=this.handleChange.bind(this)
	}
	
	componentDidMount() {						
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	
	render() {							
		return (
			<div id="content">			
				<CommonHeader titles="Payday Loan Settings"/>				
				<div style={{textAlign:'center'}}>
				<h3 style={{textAlign:'center'}}>Manage Settings</h3>
					<select name="adminjobs" id="adminjobs" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.adminjobs} onChange={this.handleChange}>
						<option value="">--Manage Items--</option>						
						<option value="1">Setup Initial Tables</option>						
						<option value="2">Change PWD</option>			
					</select><br/><br/>				
				{(()=>{
					if(this.state.adminjobs === "1") {return (
						<SetupTables/>					
					)} else if(this.state.adminjobs === "2") {return (						
						<ChangePWD />					
					)} 
				})()}				
				</div>
			</div>
		)
	}
}

export default PaydayLoans;