import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import Announce from '../pages/announce'


class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'', workinghours:[],holidays:[]};				
	}	
	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')			
			.then(result => {	
				this.setState({workinghours:result.data});
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/holidays.php')			
			.then((result) => {				
				this.setState({holidays:result.data});
			})	
	}
	
	render() {		
		return(
			<div className="right_content">
				<CommonHeader titles="Question"/>
				<h2 >{this.state.CompName} - Common Questions</h2><br/><br/>
				<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+this.state.workinghours[0] + ' ~ ' + this.state.workinghours[1]} </span></h3><br/>				
				{(()=>{if(this.state.holidays[2] > 0) {return (<div>
					<h3 style={{textAlign:"center"}}><span>{'Today our office is closed on holiday--'+this.state.holidays[0]}</span></h3><br />						
				</div>)}})()}
				<Announce/>
				<h3>Q: What is a payday loan?</h3>
				<p>Due to higher fees involved, a payday loan is intended to address short-term financial needs. 
					Typically, the Loan is repaid at your next scheduled pay day. If you need longer term financial solution, you should turn
					to other lending options such as line of credit, banking overdraft, credti card or friend, employer and family member, etc.
				</p><br/><br/>                          				
				<h3>Q: How do I qualify for a payday loan?</h3>
				<ul>
					<li> You must be eighteen (18) years of age or older</li>
					<li> You must have steady income directly deposited into your banking account</li>
					<li> You must have an active banking account with a satisfactory transaction history</li>
					<li> You must be a Canadian citizen or Permanent Resident</li>
					<li> You must have a valid email address and active phone number</li>
				</ul><br/><br/>
				<p>Regardless of the above requirements, the final decision on all applications is the sole discretion of {this.state.CompName}</p><br/><br/>
                <p>To qualify for a payday loan you must verify your identity, your employment, your banking information and meet our underwriting criteria.</p><br/><br/>           
            	<h3>Q: How quick can I know if I am approved?</h3>
                <p>If all requirements are met and provided over our online applicaton system, you may be approved in as quickly as 30 minutes. 
					You will receive an email notice that you are approved once we have final decision on your case.</p><br/><br/>
                <p>Your approved loan will be directed depositted into your personal banking account by Electronic Funds Transfer (PAD process).</p><br/><br/>
            	<h3>Q: How do I pay you back?</h3>
                <p>Your repayment will be debitted from your banking account on your next scheduled pay day for the full amount including principal and cost.</p><br/><br/>		
            	<h3>Q: How soon can I receive another loan?</h3>
                <p>Once the payment is clear (it often takes 3 business days), we can issue you another loan. 
					Keep in mind that a payday loan is intended to be your short-term financial needs</p><br/><br/>
            	<h3>Q: Can I borrow more than once in a pay period?</h3>
                <p>You are only allowed to borrow one time per pay period, No matter how much money you want to get.</p><br/><br/>
            	<h3>Q: How much can I borrow?</h3>
                <p>From $50 up to $600.(Subject to approval)</p><br/>
				<p>The maximum amount of your limit is calculated on our own credit system and we will issue a loan up to that determined limit.</p><br/><br/>	
            	<h3>Q: What if I can not repay my loan on the due date?</h3>
                <p>You must contact us immediately to set up payment arrangements. 
				If your payment comes back for any reason from the bank and you have not contacted us - we may initiate collection procedures immediately.</p><br /><br />				
			</div>
		)
	}
}

export default HomtContents