import React from 'react'
import axios from 'axios';

class UpdateBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {TTest:[]};				
	}

	componentDidMount() {				
		axios.post(process.env.REACT_APP_API_ENV+'api/SetupTables.php')
			.then(result => {
				this.setState({TTest:result.data})												
			})		
	}
	
	render() {	console.log(this.state.TTest)	
		return(
			<div >	
				<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Sequence</b></label>							
				<label style={{width: '100px',display:'inline-block',textAlign:'left',marginLeft:'10px'}}><b>Table</b></label>		
				<label style={{width: '200px',display:'inline-block',textAlign:'left',marginLeft:'10px'}}><b>Success</b></label>		
				<label style={{width: '230px',display:'inline-block',textAlign:'left',marginLeft:'10px'}}><b>Fail</b></label>			
				<label style={{width: '230px',display:'inline-block',textAlign:'left',marginLeft:'10px'}}><b>Values</b></label>							
				{this.state.TTest.slice(0, this.state.TTest.length).map(item => {return (<div>						
					<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[4]}</label>
					<label style={{width: '100px',display:'inline-block',textAlign:'left',color:'blue',marginLeft:'5px'}}>{item[0]}</label>
					<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue',marginLeft:'5px'}}>{item[1]}</label>
					<label style={{width: '230px',display:'inline-block',textAlign:'left',color:'blue',marginLeft:'5px'}}>{item[3]}</label>
					<label style={{width: '230px',display:'inline-block',textAlign:'left',color:'blue',marginLeft:'5px'}}>{item[2]}</label>
				</div>)})}<br/><br/>
			</div>
		)
	}
}

export default UpdateBanking