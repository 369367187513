import React, { useState, useEffect } from "react";
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {Link,Redirect} from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../context/auth";
import CommonHeader from '../pages/Head';
import '../css/styles.css';
import MenuBar from '../pages/Menu'
import Footer from '../pages/Footer'

function Login(props) {
	const [workings, setWorkings] = useState([]);	
	const [holiday, setHoliday] = useState([]);	
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/hours.php')
			.then(res => res.json())
			.then((result) => {	
				setWorkings(result);
			})
	}, [])			
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/holidays.php')
			.then(res => res.json())
			.then((result) => {				
				setHoliday(result);
			})
	}, [])
	const [isLoggedIn, setLoggedIn] = useState(false);			
	const [isError, setIsError] = useState(false);	
	const [userName, setUserName] = useState("");
	const [passWord, setPassWord] = useState("");
	const [comments, setComments] = useState("");		
	const { setAuthTokens } = useAuth(); 		
	let referer;	
	if(props.location.state != undefined) {
		referer = props.location.state.referer;
	} else {
		referer = "/";
	}		
	referer='/Application';		
	function postLogin(props) {	 			
		axios.post(process.env.REACT_APP_API_ENV+'api/usersemp.php', props)
			.then(result => {			
			if (result.status == 200) {				
				if(result.data[0] =='NoReg') {
					setComments('Sorry, you enter wrong username/password or you have not registered yet')
				} else {				
					setAuthTokens(result.data);
					setLoggedIn(true);		
				}
			} else {
				setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});
	}			
	if (isLoggedIn) {
		return <Redirect to={referer} />;
	}	
	const initialValues = {
			userName: '',
			passWord: ''     
	};
	
	const validationSchema=Yup.object().shape({
						userName: Yup.string()
							.email('Username is not a valid email address')
							.required('Username is required'),
						passWord: Yup.string()
							.required('password is required'),                    
	})
	
	return (	 	
		<div className="futer">		
			<CommonHeader titles="Login"/>
			<div className="menu" align="center">	 
				<div className="main">
					<div className="right">
						<MenuBar />	<br/>
						<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+workings[0] + ' ~ ' + workings[1]} </span></h3><br/>
						<h3 style={{textAlign:"center"}}><span>{holiday[2] > 0 ? 'Today our office is closed on holiday--'+holiday[0]:''}</span></h3><br/>
						<h2 style={{textAlign:"center"}}>Employee Login</h2><br/>
						<Formik initialValues={initialValues} validationSchema={validationSchema} 
								onSubmit={(values, { setSubmitting }) => {
									setTimeout(() => {							
									  postLogin(values);
									  setSubmitting(false);
									}, 500);
								}}			
						>
							{({ errors, status, touched }) => {
							return(
								<Form>                        							
									<label htmlFor="userName">Username</label><br/>
									<Field id="userName" name="userName" type="text"  style={{width: '150px',border:'1px solid #4195fc'}} className={'form-control' + (errors.userName && touched.userName ? ' is-invalid' : '')} placeholder='email'/>
									<ErrorMessage name="userName" component="div" className="invalid-feedback" /><br/><br/>
									
									<label htmlFor="passWord">Password</label><br/>
									<Field id="passWord" name="passWord" type="password"  style={{width: '150px',border:'1px solid #4195fc'}} className={'form-control' + (errors.passWord && touched.passWord ? ' is-invalid' : '')} placeholder='password' />
									<ErrorMessage name="passWord" component="div" className="invalid-feedback" /><br/><br/>
																					
									<h2>{comments}</h2>
									<br/><br/>	
									
									<button type="submit" className="btn btn-primary mr-2" width="150px" >&nbsp;Login &nbsp;</button>							
									&nbsp;&nbsp;
									<button type="reset" className="btn btn-secondary">&nbsp;Reset&nbsp;</button><br/><br/>
								</Form>
								)
							}}
						</Formik>
						<p><Link to = "/PWDchange">Forgot Password?</Link></p><br/><br/>
					</div>
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default Login;