import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';

class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'', WorkingHours:[]};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')
			.then(result => {				
				this.setState({WorkingHours:result.data})													
			})			
	}
	render() {		
		return(
			<div className="right_content">
				<CommonHeader titles="Payday Loan Failed"/>
				<h2><Link to="/">{this.state.CompName}</Link>--Payday Loan Provider for Canadians</h2><br/>
				<h3><span>Welcome to <Link to="/">{this.state.CompName}</Link></span></h3><br />
				<p>Our office hours: Monday to Friday (except weekends and holidays) from {this.state.WorkingHours[0] + ' to ' + this.state.WorkingHours[1]}</p><br/>
				<p>Since you do not have direct deposit payment from your employer, by policy, we are not able to process your application. Sorry for this inconvenience.</p><br/>
				<p>You are welcome back if you have direct deposit in the future.</p><br/>
				<p>Thank you for your trust with {this.state.CompName}</p><br/>
				<h3><b>{this.state.CompName + ' Team'}</b></h3><br/><br/>
			</div>
		)
	}
}

export default HomtContents