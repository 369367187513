import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';

class SetupOperation extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {manageaction:'NOTICE',CompName:'',NewApp:[],user:localStorage.getItem('tokens'), Resp:'',updaterole:'',management:'',
				human:'',roleforupdate:''};							
		this.handleChange=this.handleChange.bind(this)
		this.handleRoleChange=this.handleRoleChange.bind(this)
		this.handleAddChange=this.handleAddChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/noticelists.php')
			.then(result => {		
					this.setState({NewApp:result.data})				
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}
	handleAddChange = event => {				
		const {name, value} = event.target
		this.setState({[name]: value}) 		
		this.setState({management:''})		
		this.setState({human:''})				
		this.setState({Resp:''})		
	}
	handleChange = event => {				
		const {name, value} = event.target
		this.setState({[name]: value}) 		
		this.setState({Resp:''})
	}
	handleRoleChange= event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value !=="") {			
			this.setState({management:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0]})			
			this.setState({human:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[0])[0]})						
		}
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {updaterole,management,human,roleforupdate} = this.state		
		if(!updaterole){
			alert('Please select action')
			return false 
		} else if(updaterole === '1') {			
			if(!human){
				alert('Please enter new notice code')
				return false 
			} 
			if(!management){
				alert('Please enter new notice content')
				return false 
			} 						
		} else if(updaterole === '2') {
			if(!roleforupdate){
				alert('Please select notice for updating')
				return false
			}
			if(!human){
				alert('Please enter notice code')
				return false 
			} 
			if(!management){
				alert('Please enter notice content')
				return false 
			} 	
		} else if(updaterole === '3') {
			if(!roleforupdate){
				alert('Please select notice to be deleted')
				return false
			} 
		}
		const userObject = {	
			user:this.state.user,
			manageaction:this.state.manageaction,
			updaterole:this.state.updaterole,			
			management:this.state.management,			
			human: this.state.human,						
			roleforupdate:this.state.roleforupdate,			
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/managementprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Announcement"/>	
				<form onSubmit={this.handleSubmit}>
					<h3>Select Action</h3>
					<select name="updaterole" style={{width: '150px', border:'1px solid grey'}} value = {this.state.updaterole} onChange={this.handleAddChange}>
						<option value="">--Select--</option>		
						<option value="1">Add New Notice</option>		
						<option value="2">Update Notice</option>		
						<option value="3">Remove Notice</option>						
					</select><br/><br/>	
					{(()=>{if(this.state.updaterole === "1") {return (<div>													
						<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>New Notice Code (Three Letters)</b></label><br/>
						<input type="text" name="human" style={{width: '190px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.human} onChange={this.handleChange} /><br/><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>New Notice Content</b></label><br/>							
						<textarea name="management" rows="5" value = {this.state.management} style={{width: '600px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
					</div>)} else if(this.state.updaterole === "2") {return (<div>	
						<h3>Select Notice for updating</h3>
						<select name="roleforupdate" style={{width: '150px', border:'1px solid grey'}} value = {this.state.roleforupdate} onChange={this.handleRoleChange}>
							<option value="">--Select--</option>		
							{this.state.NewApp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]}</option>))}							
						</select><br/><br/>	
						{(()=>{if(this.state.roleforupdate !== "") {return (<div>								
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>Notice Code</b></label><br/>
							<input type="text" name="human" style={{width: '190px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.human} onChange={this.handleChange} /><br/><br/>
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>Notice Content (default value = EMPTY)</b></label><br/>							
							<textarea name="management" rows="5" value = {this.state.management} style={{width: '600px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
						</div>)}})()}
					</div>)} else if(this.state.updaterole === "3") {return (<div>	
						<h3>Select Notice to be deleted</h3>
						<select name="roleforupdate" style={{width: '150px', border:'1px solid grey'}} value = {this.state.roleforupdate} onChange={this.handleRoleChange}>
							<option value="">--Select--</option>		
							{this.state.NewApp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]}</option>))}							
						</select><br/><br/>	
					</div>)}})()}
					<h3>{this.state.Resp}</h3><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
				</form>				
			</div>
		)
	}
}

export default SetupOperation