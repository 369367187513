import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';

class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {email:'',accountid:'',profile:'',fullname:'',tracc:'',actions:'',AccID:'',user:localStorage.getItem('tokens'),profileinfo:[],Resp:'',HResp:'',Hist:[]};				
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  		
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {email, accountid,profile,actions,AccID} = this.state				
		const userObject = {	
			user:this.state.user,
			email:this.state.email,
			accountid: this.state.accountid,
			profile: this.state.profile,
			fullname: this.state.fullname,
			tracc:this.state.tracc,
			actions:this.state.actions,
			AccID:this.state.AccID
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/search.php', userObject)
			.then((res) => {
				if(res.data[0] === "EMPTY DATA") {
					this.setState({Resp:"Sorry, no information provided"})
				} else if(res.data[0] === "NO") {
					this.setState({Resp:"Sorry, no information can be found in the system"})
				} else if(res.data[0] === "DELETE") {
					this.setState({Resp:"The select application for this client has been deleted from database successfully"})
				} else if(res.data[0] === "CREATE") {
					this.setState({Resp:"The new application for this client has been created successfully"})
				} else {
					this.setState({profileinfo:res.data})
					this.setState({Resp:"YES"})
				}
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/searchhist.php',userObject)
			.then(result => {	
				if(!result.data) {
					this.setState({HResp:"Sorry, no information provided or found"})
				} else {
					this.setState({Hist:result.data})
					this.setState({HResp:"YES"})
				}				
			})
		
	}
	render() {		
		return(
			<div className="right_content" style={{textAlign:'center'}}>
				<CommonHeader titles="Search"/>
				<form onSubmit={this.handleSubmit}>
					<h3>Search Following Any Item</h3><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Transit-Account:</b></label>
					<input type="text" name="tracc" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.tracc} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Name (First + Last):</b></label>
					<input type="text" name="fullname" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.fullname} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
					<input type="text" name="email" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.email} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Application ID:</b></label>
					<input type="text" name="accountid" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.accountid} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Profile ID:</b></label>
					<input type="text" name="profile" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profile} onChange={this.handleChange} /><br/><br/>					
					<h3>Select Action</h3>
					<select name="actions" style={{width: '150px', border:'1px solid grey'}} value = {this.state.actions} onChange={this.handleChange}>
						<option value="">--Select--</option>							
						<option value="1">Delete Application</option>							
						<option value="2">Create New Application</option>
						
					</select><br/>
					<h4>If no action selected, then only for seach action. If below loan status is not selected,
						only update Approval Loan Amount, if Approval Loan Amount is not entered, then only update Loan status. If we need update 
						approval loan amount, please also update FTPTABLE separately</h4>	
					{(()=>{if(this.state.actions != "") {return (<div>	
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Account ID:</b></label>
						<input type="text" name="AccID" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.AccID} onChange={this.handleChange} /><br/><br/>					
					</div>)}})()}
					
					<h3>{this.state.Resp === "YES" ? "":this.state.Resp}</h3><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
				</form><br/><br/>
				{(()=>{if(this.state.Resp === "YES" && this.state.actions == "") {return (<div>	
					<h3>Latest or Selected Application Information</h3><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[1]} readOnly/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Last Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[2]} readOnly/><br/>							
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Gender:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[19]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Date of Birth:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[20]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[43]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[30]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[32]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Branch Transit:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[31]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Check Number:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[10]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[11]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Online PWD:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[12]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[33]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[34]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[35]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[36]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[37]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[38]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[39]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[40]} readOnly/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[41]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[42]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[44]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer Phone:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[45]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Job Started:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[46]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employment Type:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[5]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Type:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[6]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Frequency:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[7]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[8]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[9]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Pay Day:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[50]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Second Pay Day:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[51]} readOnly/><br/>											
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Profile ID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[52]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Visit Times:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[16]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Applied Loan:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[54]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Loan:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[55]}readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Date:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[56]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>AccountID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[53]} readOnly/><br/><br/>
					<h3>Historical Record Checking</h3><br/>
					<select id="hischeck" name="hischeck" style={{width: '150px', border:'1px solid grey'}} value = {this.state.hischeck} onChange={this.handleChange}>
						<option value="">--Select--</option>							
						<option value="1">Check Historical Payday Loans</option>							
					</select><br/><br/>	
					{(()=>{if(this.state.hischeck === "1") {return (<div>					
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>AccountID</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Bank</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Check #</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Approved Loan</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Charge</b></label>			
						<label style={{width: '130px',display:'inline-block',textAlign:'center'}}><b>Approved Date</b></label>
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Due Day</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Paid AMT</b></label>	
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>NSF AMT</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Loan Process</b></label>
						{this.state.Hist.slice(0, this.state.Hist.length).map(item => {return (<div>						
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[4]}</label>
							<label style={{width: '130px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[5]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[8]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[6]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[9]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[7]}</label>
						</div>)})}<br/><br/>					
					</div>)}})()}
				</div>)}})()}
			</div>
		)
	}
}

export default NewApplication