import React from 'react'
import axios from 'axios';
import {Helmet} from "react-helmet";

class HeadInfo extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {			
		axios.post(process.env.REACT_APP_API_ENV + 'api/FindCompanyName.php')
			.then(result => {							
				this.setState({CompName:result.data[0]})													
			})		
	}

	render(){
		return (
			<Helmet>				
					<title>{this.props.titles+' | '+this.state.CompName+' Payday Loan'}</title>
					<meta name="description" content="Payday loans|Online Payday Loans|Payday Loans Provider|Canadian Payday Loans|Faxless Payday Loans|Fast Payday Loans|cash advance|Payday Loans for Canadians|Easy Payday Loans and Cash Advances"/>      									
					<meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
					<link rel="canonical" href={process.env.REACT_APP_API_ENV} />				
					<meta property="og:type" content="website" />
					<meta property="og:title" content="Online Payday Loan Provider|Online Cash Advance Provider|Canadian Payday Loans|Payday Loans for Canadians|Fast Payday Loans|Instant Payday Loans|Faxless Payday Loans|Cash Advance" />
					<meta property="og:description" content="Online Payday Loan Provider|Online Cash Advance Provider|Canadian Payday Loans|Payday Loans for Canadians|Fast Payday Loans|Instant Payday Loans|Faxless Payday Loans|Cash Advance" />
					<meta property="og:url" content={process.env.REACT_APP_API_ENV}/>
					<meta property="og:site_name" content="Online Payday Loan Provider|Online Cash Advance Provider|Canadian Payday Loans|Payday Loans for Canadians|Fast Payday Loans|Instant Payday Loans|Faxless Payday Loans|Cash Advance" />								
			</Helmet>
		)
	}
}

export default HeadInfo