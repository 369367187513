import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios'
import dayjs from 'dayjs';
const nextyear=dayjs().add(1,'year').format('YYYY')

class PaydayInfor extends React.Component {	
	constructor(props) {
		super(props)
		this.state = {paydayinfo: [],hours:[],CompName:'',user:localStorage.getItem('tokens'),canperiod:'',loandate:'',
						loanamount:'',borrowers:'',datetimes:'',cancels:'',Resp:''}				
	}

	componentDidMount() {		
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/paydayloaninformation.php',para)
			.then(result => {						
				this.setState({paydayinfo:result.data})	
				this.setState({canperiod:result.data[11]})	
				this.setState({ProfileID:result.data[0]})
				this.setState({accountid:result.data[39]})
				this.setState({loandate:result.data[11]})
				this.setState({loanamount:result.data[5]})
				this.setState({borrowers:result.data[16]})
				this.setState({datetimes:dayjs().format('MM/DD/YYYY,h:mm A')})
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')			
			.then((result) => {	
				this.setState({hours:result.data});
			})	
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  	
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {loandate,loanamount,borrowers,datetimes} = this.state				
		const userObject = {	
			user:this.state.user,
			loandate: this.state.loandate,
			loanamount: this.state.loanamount,
			borrowers: this.state.borrowers,				
			datetimes: this.state.datetimes,
			cancels: this.state.cancels,
			accountid: this.state.accountid,
			profileid: this.state.ProfileID
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/cancelpaydayloan.php', userObject)
			.then((res) => {
					this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});					
	}	
	render() {    		
		return (
			<div >
				<CommonHeader titles="Payday Loan Cancellation"/>
				<form onSubmit={this.handleSubmit}>						
					{(()=>{if(this.state.paydayinfo[8] === 'CANCEL' ) {return (<div>										
						<label style={{width: '700px',display:'inline-block',textAlign:'left'}}>
							You have submitted your request to cancel your loan, please wait for us to cancel your loan and we will send email to you about the cancellation status.
						</label><br/><br/><br/>						
					</div>)} else if(dayjs().diff(dayjs(this.state.paydayinfo[11]),'day') <= 3 && dayjs(this.state.paydayinfo[13]).diff(dayjs(),'day')>1 && dayjs(this.state.paydayinfo[11]).format('d') === 5 ) {return (<div>				
						<br/><h2 style={{textAlign:'center'}}><b>Loan Cancellation Form</b></h2><br/><br/><br/>
						<label style={{width: '700px',display:'inline-block',textAlign:'left'}}>To cancel a loan, the Borrower must during the "Cancellation Period" complete and deliver the 
							Notice of Cancellation in the form set out below to {this.state.CompName} in each case together 
							with payment in full of the cancelled loan PAD payment.</label><br/><br/><br/>
						<h2 style={{textAlign:'center'}}><b>Notice of Cancellation</b></h2><br/><br/><br/>
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
						I <b>{this.state.paydayinfo[16]}</b>, (Borrower) hereby notify {this.state.CompName} of cancellation of the Loan and </label><br/><br/>
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
						Tender Repayment of the Loan Advance</label><br/><br/>						
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Loan Date:</b></label>						
						<input type="text" name="loandate" value = {this.state.loandate} style={{width: '300px',color:'blue'}} onChange={this.handleChange}/><br/><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Loan Amount:</b></label>
						<input type="text" name="loandate" value = {this.state.loanamount} style={{width: '300px',color:'blue'}} onChange={this.handleChange}/><br/><br/>						
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Borrower:</b></label>
						<input type="text" name="loandate" value = {this.state.borrowers} style={{width: '300px',color:'blue'}} onChange={this.handleChange}/><br/><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Date / Time:</b></label>
						<input type="text" name="loandate" value = {this.state.datetimes} style={{width: '300px',color:'blue'}} onChange={this.handleChange}/><br/><br/><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Cancel this Loan ?:</b></label>						
						<select style={{width: '300px',border:'1px solid #4195fc'}} name="cancels" value={this.state.cancels} onChange={this.handleChange}>
							<option value="">-select-</option>
							<option value="1">-Yes-</option>
							<option value="2">-No-</option>							
						</select><br/><br/>
					</div>)} else if(dayjs().diff(dayjs(this.state.paydayinfo[11]),'day') <= 1 && dayjs(this.state.paydayinfo[13]).diff(dayjs(),'day')>1 && dayjs(this.state.paydayinfo[11]).format('d') !== 5 ){return(<div>
						<br/><h2 style={{textAlign:'center'}}><b>Loan Cancellation Form</b></h2><br/><br/><br/>
						<label style={{width: '700px',display:'inline-block',textAlign:'left'}}>To cancel a loan, the Borrower must during the "Cancellation Period" complete and deliver the 
							Notice of Cancellation in the form set out below to {this.state.CompName} in each case together 
							with payment in full of the cancelled loan PAD payment.</label><br/><br/><br/>
						<h2 style={{textAlign:'center'}}><b>Notice of Cancellation</b></h2><br/><br/><br/>
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
						I <b>{this.state.paydayinfo[16]}</b>, (Borrower) hereby notify {this.state.CompName} of cancellation of the Loan and </label><br/><br/>
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
						Tender Repayment of the Loan Advance</label><br/><br/>						
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Loan Date:</b></label>						
						<input type="text" name="loandate" value = {this.state.loandate} style={{width: '300px',color:'blue'}} readOnly/><br/><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Loan Amount:</b></label>
						<input type="text" name="loandate" value = {this.state.loanamount} style={{width: '300px',color:'blue'}} readOnly/><br/><br/>						
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Borrower:</b></label>
						<input type="text" name="loandate" value = {this.state.borrowers} style={{width: '300px',color:'blue'}} readOnly/><br/><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Date / Time:</b></label>
						<input type="text" name="loandate" value = {this.state.datetimes} style={{width: '300px',color:'blue'}} readOnly/><br/><br/><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Cancel this Loan ?:</b></label>						
						<select style={{width: '300px',border:'1px solid #4195fc'}} name="cancels" value={this.state.cancels} onChange={this.handleChange}>
							<option value="">-select-</option>
							<option value="1">-Yes-</option>
							<option value="2">-No-</option>							
						</select><br/><br/>
					</div>)} else {return(<div>
						{(()=>{if(this.state.paydayinfo[8] === 'NEW') {return(<div>
							<h3>Sorry, you new loan is still in the approving process, please send email to cancel your new loan</h3><br/><br/>
						</div>)} else if(this.state.paydayinfo[8] === 'APPROVED' || this.state.paydayinfo[8] === 'PAD') {return(<div>
							<h3>Sorry, by law you are not able to cancel your payday loan now since it has passed "Cancellation Period"</h3><br/><br/>
						</div>)} else {return(<div>
							<h3>You do not have new loan to be cancelled</h3><br/><br/>							
						</div>)}})()}
					</div>)}})()}
					{(()=>{if(this.state.cancels === '1') {return(<div>		
						<h3 >{this.state.Resp}</h3><br/><br/>						
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Cancel</button><br/><br/>
					</div>)}})()}					
				</form>
			</div>
		)
	}
}

export default PaydayInfor