import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}
class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {ftpprocess:'',Appstatus:'APPROVED',CompName:'',NewApp:[],user:localStorage.getItem('tokens'),Resp:'', NLoan:'',NSFCase:''};											
		this.handleChange=this.handleChange.bind(this)			
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/approvedloan.php')
			.then(result => {				
				if(result.data[0][0] === "No") {
					this.setState({Resp:"No Approved Loan yet"})
					this.setState({NLoan:0})
				} else {
					this.setState({Resp:""})
					this.setState({NewApp:result.data})
					this.setState({NLoan:result.data[0][9]})
				}
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/nsfcases.php')
			.then(result => {								
				this.setState({NSFCase:result.data})									
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})			
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}

		
	handleSubmit = event => {
		event.preventDefault()		
		const {ftpprocess} = this.state						
		const userObject = {	
			user:this.state.user,			
			Appstatus: this.state.Appstatus,					
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/approveprocess.php', userObject)
				.then((res) => {
					this.setState({Resp:res.data})											
				}).catch((error) => {
					console.log(error)
				});		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>				
				{(()=>{if(this.state.NLoan > 0 || this.state.NSFCase > 0) {return (<div>	
					<label><b>FTP Banking Files {this.state.NLoan > 1 ? '('+this.state.NLoan+' loans)':(this.state.NLoan === 1 ? '(total '+this.state.NLoan+' loan)':'(No Approved Loan)') }{this.state.NSFCase ===0 ? ", (No NSF FTP cases)":", (There are NSF FTP cases)"}</b></label><br/>
					<select id="ftpprocess" name="ftpprocess" style={{width: '150px', border:'1px solid grey'}} value = {this.state.ftpprocess} onChange={this.handleChange}>
						<option value="">--Select--</option>					
						<option value="2">Send Data to Cash2u</option>					
					</select><br/><br/>				
				</div>)} else {return (<div>	
					<label>There are no new approved loans or NSF repayment cases</label><br/><br/>
				</div>)}})()}
				{(()=>{if(this.state.ftpprocess === "2") {return (<div>	
					<form onSubmit={this.handleSubmit}>						
						<h3>{this.state.Resp}</h3><br/><br/>
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
					</form>
				</div>)}})()}
			</div>
		)
	}
}

export default NewApplication