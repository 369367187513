import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";

class MenuBar extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {roles:'',management:'',administrative:'',hrs:'',accountant:'',manager:'',emp:'', user:localStorage.getItem('tokens'),
						CompName:'',names:''};				
	}
	
	componentDidMount() {				
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformationemp.php',para)
			.then(result => {						
				this.setState({management:result.data[5]})													
				this.setState({administrative:result.data[6]})		
				this.setState({hrs:result.data[7]})		
				this.setState({accountant:result.data[8]})		
				this.setState({manager:result.data[9]})		
				this.setState({emp:result.data[10]})								
				this.setState({roles:result.data[2]})			
				this.setState({names:result.data[1]})	
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}
	render() {				
		return (<div className="header">
				<div className="top"><Link to="/Application" style={{color:"white"}}>{this.state.CompName+' welcome '+this.state.names}</Link></div>
				<div className="buttons" >
				<ul>
					{(()=>{if(this.state.emp === "1") {return(
						<li><Link to = "/Application">Application</Link></li>
					)}})()}
					{(()=>{if(this.state.administrative==="1") {return(
						<li><Link to = "/Admin">Admin</Link></li>
					)}})()}
					{(()=>{if(this.state.management==="1") {return(<div>
						<li><Link to = "/Management">Management</Link></li>
						<li><Link to = "/report">Report</Link></li>
					</div>)}})()}						
					{(()=>{if(this.state.emp === "1") {return(
						<li><Link to = "/Search">Search</Link></li>
					)}})()}		
					{(()=>{if(this.state.management==="1") {return(<div>
						<li><Link to = "/ManageSetting">Setting</Link></li>						
					</div>)}})()}
					<li><Link to = "/Manages">Logout</Link></li>
				</ul>
				
			</div>
		</div>)
	}	
}


export default MenuBar