import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import UpdateProfile from '../pages/updateProfile'

class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'',isUpdating:false,	viewProfile:true,user:localStorage.getItem('tokens'),profileinfo:[]
						,name:''};		
		this.handleClick=this.handleClick.bind(this);		
		this.handleProfile=this.handleProfile.bind(this);
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php',para)
			.then(result => {						
				this.setState({profileinfo:result.data})				
				this.setState({name:result.data[1]})
			})							
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	handleClick(){				
		this.setState(prevState=>({isUpdating: !prevState.isUpdating}))		
		this.setState(prevState=>({viewProfile: !prevState.viewProfile}))		
	}	
	handleProfile(){				
		this.setState(prevState=>({viewProfile: !prevState.viewProfile}))			
		this.setState({isUpdating:false})		
	}
	render() {		
		return(
			<div className="right_content">
				<CommonHeader titles="Profile"/>
				<div style={{textAlign:'center'}}>
				<button onClick={this.handleProfile}>{this.state.viewProfile ? "View Profile Now":"View Profile ?"}</button>&nbsp;&nbsp;
				<button onClick={this.handleClick}>{this.state.isUpdating ? "Updating  Profile Now": "Update Your Profile?"}</button><br/><br/>
				</div>
				{(()=>{if(this.state.viewProfile === true) {return (<div>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Name:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[1]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>SIN:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[18]}</label><br/><br/>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Gender:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[19]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Birthday:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[20]}</label><br/><br/>					
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Address:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[21]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>City:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[22]}</label><br/><br/>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[43]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Post Code:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[24]}</label><br/><br/>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Date Move In:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[25]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Cell Phone:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[26]}</label><br/><br/>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Employer:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[44]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Employer Phone:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[45]}</label><br/><br/>					
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Date Started:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[46]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Job Status:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[47]}</label><br/><br/>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Payment Type:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[48]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Payment Frequency:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[49]}</label><br/><br/>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[50]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[51]}</label><br/><br/>
				</div>)}})()}	
				{(()=>{if(this.state.isUpdating === true) {return (
					<UpdateProfile/>
				)}})()}			
			</div>
		)
	}
}

export default HomtContents