import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios'
import dayjs from 'dayjs';
import PDF_1 from '../pages/PaydayLoanAgreement_1.pdf'
import PDF_3 from '../pages/PaydayLoanAgreement_3.pdf'
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}

class ApplyContents extends React.Component {	
	constructor(props) {
		super(props)
		this.state = {province: '0', Names:'', ssn:'',email:'',bankname:'',FINID:'',BTransit:'',CNumber: '',employer:'',JobStatus:'',
					 paytype:'',directpay:'',payperiod:'',totalincome:'',loanborrows:[],Resp:'',user:localStorage.getItem('tokens'),visits:'',
					 CompName:'',states:[],loanborrow:'',provid:'',maxloan:[],fdate1:'',fdate2:'',fdate3:'',sdate1:'',sdate2:'',sdate3:'',
					 credits:''}
		this.handleChange=this.handleChange.bind(this)		
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	
	componentDidMount() {		
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php',para)
			.then(result => {						
				this.setState({Names:result.data[1]})				
				this.setState({ssn:result.data[18]})				
				this.setState({email:result.data[0]})
				this.setState({bankname:result.data[30]})
				this.setState({FINID:result.data[32]})
				this.setState({BTransit:result.data[31]})
				this.setState({CNumber:'xxxxxxxx'+result.data[52].substring(8,12)})
				this.setState({province:result.data[43]})				
				this.setState({provid:result.data[23]})	
				this.setState({employer:result.data[44]})
				this.setState({JobStatus:result.data[47]})
				this.setState({paytype:result.data[48]})
				this.setState({directpay:result.data[51]})
				this.setState({payperiod:result.data[49]})								
				this.setState({visits:result.data[16]})
				this.setState({credits:result.data[55]})				
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/findprovinces.php')
			.then(result => {
				this.setState({states:result.data});
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/loanapproval.php')			
					.then(result => {					
						this.setState({loanborrows:result.data});						
					})
		axios.post(process.env.REACT_APP_API_ENV+'api/maxloans.php')			
					.then(result => {											
						this.setState({maxloan:result.data});
					})
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})    
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {totalincome,fdate1,	fdate2,fdate3,sdate1,sdate2,sdate3,loanborrow} = this.state			
		if(!totalincome){
			alert('Please enter your each payment amount')
			return false
		} 
		if(!fdate1){
			alert('Please select your first payment year')
			return false
		} 
		if(!fdate2){
			alert('Please select your first payment month')
			return false
		} 
		if(!fdate3){
			alert('Please select your first payment day')
			return false
		} 
		if(!sdate1){
			alert('Please select your second payment year')
			return false
		} 
		if(!sdate2){
			alert('Please select your second payment month')
			return false
		} 
		if(!sdate3){
			alert('Please select your second payment day')
			return false
		} 
		if(!loanborrow){
			alert('Please select loan you want to borrow')
			return false
		} else {			
			const userObject = {				
				user: this.state.user,		
				visits:this.state.visits,
				credits:this.state.credits,
				province: this.state.provid,				
				Names: this.state.Names,
				email: this.state.email,
				bankname:this.state.bankname,
				FINID:this.state.FINID,				
				BTransit:this.state.BTransit,
				CNumber:this.state.CNumber,
				employer:this.state.employer,
				totalincome:this.state.totalincome,		
				payperiod:this.state.payperiod,
				fdate1:this.state.fdate1,
				fdate2:this.state.fdate2,
				fdate3:this.state.fdate3,
				sdate1:this.state.sdate1,
				sdate2:this.state.sdate2,
				sdate3:this.state.sdate3,
				loanborrow:this.state.loanborrow				
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/reloansubmit.php', userObject)
				.then((res) => {
						this.setState({Resp:res.data})										
				}).catch((error) => {
					console.log(error)
				});
			
		}
	}
  
	
	
	render() {    		
		return (
			<div className="right_content">
				<CommonHeader titles="Payday Loan Application"/>
				<h2>Existing Customer Payday Loan Application</h2><br/>
				<h3>Note:<span>Please update your profile and bank information if changed</span></h3><br/><br/>
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Name:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.Names}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>SIN:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.ssn}</label><br/><br/>								
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.email}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.province}</label><br/><br/>								
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.bankname}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.FINID}</label><br/><br/>								
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Transit #:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.BTransit}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Account #:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.CNumber}</label><br/><br/>				
				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Employer:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.employer}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Job Status:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.JobStatus}</label><br/><br/>								
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Payment Type:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.paytype}</label>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.directpay}</label><br/><br/>				
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Pay Frequency:</b></label>
				<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{' '+this.state.payperiod === '7' ? "Weekly":(this.state.payperiod === '14' ? "Bi-weekly":(this.state.payperiod === '15' ? "Semi-Monthly":"Monthly"))}</label><br/><br/>				
				<p style={{color:'#B91B1B'}}>You are required to provide the following information for your reloan application</p><br/>
				<form onSubmit={this.handleSubmit}>											
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
					<input type="text" name="totalincome" id="totalincome" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.totalincome} onChange={this.handleChange} /><br/><br/>				
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>First Pay Day:</b></label>	
					<select style={{width: '55px',border:'1px solid #4195fc'}} name="fdate1" value={this.state.fdate1} onChange={this.handleChange}>
						<option value="">--</option> 
						<option value={thisyear}>{thisyear}</option> 
						{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 31) {return (
							<option value={nextyear}>{nextyear}</option> 				
						)}})()}
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate2" value={this.state.fdate2} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate3" value={this.state.fdate3} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select><br/><br/>			
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Second Pay Day:</b></label>	
					<select style={{width: '55px',border:'1px solid #4195fc'}} name="sdate1" value={this.state.sdate1} onChange={this.handleChange}>
						<option value="">--</option> 
						<option value={thisyear}>{thisyear}</option> 
						{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 61) {return (
							<option value={nextyear}>{nextyear}</option> 				
						)}})()}
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate2" value={this.state.sdate2} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate3" value={this.state.sdate3} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select><br/><br/>	
					<p style={{color:'#B91B1B'}}>Based on the credits you earned from our company, the maximum loan amount you can borrow is {'$'+this.state.maxloan.filter(sub=>sub[0] <= this.state.credits).map(sub=>sub[1])[0]}</p><br/>
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Borrow:</b></label>		
					<select id="loanborrow" name="loanborrow" value = {this.state.loanborrow} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange}>
							<option value="">--Select--</option>
							{this.state.loanborrows.filter(sub=>sub[0]<=this.state.credits).map(item => (<option value={item[1]} key={item[0]}>{item[1]}</option>))}					
					</select>		
					<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Loan Cost:</b></label>
					<input type="text" name="loancost" id="loancost" value = {this.state.loanborrow*this.state.states.filter(sub=>sub[0]===this.state.provid).map(sub=>sub[2])/100} style={{width: '150px',border:'1px solid #4195fc'}} readOnly/><br/><br/>
					
					<label><strong>Please read the following agreement carefully before you submit</strong></label><br/><br/>
					<p><input type="checkbox" id="signoff" name="signoff" value = "yes" required /> 
					I have read and agree to all terms 
					and conditions of {this.state.province === "3" ? <Link to={PDF_3} target="_blank" style={{color:'blue'}}>the Loan Agreement</Link>:<Link to={PDF_1} target="_blank" style={{color:'blue'}}>the Loan Agreement</Link>},<br/>and I also authorize {this.state.CompName} to verify the accuracy of this information
					provided by me. Here signed electroniclly</p><br/>		
					<h3 style={{color:'#B91B1B'}}>{this.state.Resp}</h3><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/>					
				</form>				
			</div>
		)
	}
}

export default ApplyContents