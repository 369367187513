import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';
import { CSVLink } from "react-csv"
const Columns = ["First Name","Last Name","Gender ","Social Insurance Number ",	"Client Email ","Date of birth ","Address ","City",
				"Province ","Post Code ","Cell Phone ","Employer ","Employer Phone ","Approved Loan ","Approved Loan Cost ",
				"Approved Date ","Paid Amount ","Unpaid Amount including NSF Charges", "Unpaid Reason "]	

class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'COLLECTIONAGENCY',CompName:'',NewApp:[],user:localStorage.getItem('tokens'),
			profileinfo:[],Resp:'',newapps:'',AppDates:[],starting:'',ending:'', collectemail:'',Agencys:[],collectData:[],
			isSelected:false,file: '', EmptyDate:''};
		this.handleChange=this.handleChange.bind(this)		
		this.onChange=this.onChange.bind(this)		
		this.handleEndChange=this.handleEndChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/foragencyloan.php')
			.then(result => {		
				if(result.data[0][0] === "No") {
					this.setState({Resp:"No Clients for Agency to collect payment"})
				} else {
					this.setState({Resp:""})
					this.setState({NewApp:result.data})
				}
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/approveddates.php')
			.then(result => {				
				if(result.data[0][0] === "No Client for Agency") {
					this.setState({EmptyDate:'YES'})
				} else {
					this.setState({AppDates:result.data})
					this.setState({EmptyDate:''})
				}
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/agencycommunication.php')
			.then(result => {				
				this.setState({Agencys:result.data})													
			})	
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		this.setState({file:''})
	}
	handleEndChange = event =>{
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({file:''})
		const userObject = {
			starting:this.state.starting,
			ending:event.target.value				
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/collectionlist.php', userObject)
			.then((res) => {
				if(res.data[0][0] === "NO") {
					this.setState({Resp:"No default client for Agency to collect payment"})						
					this.setState({collectData:res.data})	
				} else {
					this.setState({collectData:res.data})					
				}
			}).catch((error) => {
				console.log(error)
			});		
	}
	onChange(e) {
		const {name, value} = e.target
		this.setState({[name]: value})  
        this.setState({file:e.target.files[0]});
		this.setState({isSelected:true});
    }
				
	handleSubmit = event => {
		event.preventDefault()		
		const {starting,ending,collectemail} = this.state		
		
		const formData = new FormData();
			formData.append('user',this.state.user);			
			formData.append('clientemail', this.state.collectemail);
			formData.append('file',this.state.file);
		const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
		axios.post(process.env.REACT_APP_API_ENV+'api/sendtoagency.php', formData,config)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}	
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>
				<form onSubmit={this.handleSubmit}>	
					<h3>Collection Action</h3>
					<select id="collectemail" name="collectemail" style={{width: '150px', border:'1px solid grey'}} value = {this.state.collectemail} onChange={this.handleChange}>
						<option value="">--Select--</option>		
						<option value="FILE">Generate Agency File</option>
						{this.state.Agencys.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}								
					</select><br/><br/>	
					{(()=>{if(this.state.collectemail === "FILE" && this.state.EmptyDate === "YES") {return (<div>
						<h3>Sorry, there are no default clients</h3><br/>
					</div>)}})()}
					{(()=>{if(this.state.collectemail === "FILE" && this.state.EmptyDate !== "YES") {return (<div>
						<h3>Select Starting Date</h3>
						<select id="starting" name="starting" style={{width: '150px', border:'1px solid grey'}} value = {this.state.starting} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							{this.state.AppDates.map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
						</select><br/><br/>		
						{(()=>{if(this.state.starting !== "") {return (<div>
							<h3>Select Ending Date</h3>
							<select id="ending" name="ending" style={{width: '150px', border:'1px solid grey'}} value = {this.state.ending} onChange={this.handleEndChange}>
								<option value="">--Select--</option>		
								{this.state.AppDates.map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
							</select><br/><br/>	
							{(()=>{if(this.state.ending !== "") {return (<div>
								<h4>Please save this file into your local folder</h4><br/>
								<CSVLink  data={this.state.collectData} filename={"collection_data.csv"} headers={Columns} className="btn btn-primary"   target="_blank">									
									<h3>Generate Client Agency File</h3>
								</CSVLink><br/><br/>													
							</div>)}})()}
							<h3>{this.state.Resp}</h3><br/><br/>
						</div>)}})()}
					</div>)} else if(this.state.collectemail !== "FILE" && this.state.collectemail !== "") {return (<div>						
						<input type="file" name="file" style={{width:'160px'}} onChange= {this.onChange} /><br/><br/>	
						{(()=>{if(this.state.file !== "") {return (<div>
							<h3>{this.state.Resp}</h3><br/><br/>
							<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Send to Collection Agency</button><br/><br/>
						</div>)}})()}						
					</div>)}})()}
				</form>
			</div>
		)
	}
}

export default NewApplication