import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import SetupRoles from '../manages/setuproles';
import SetupProvinceOperations from '../manages/setupprovinceoperations'
import SetupBanks from '../manages/setupbanks'
import SetupHolidays from '../manages/setupholidays'
import SetupOfficehours from '../manages/setupofficehours'
import SetupAgency from '../manages/setupagency'
import SetupMaxloans from '../manages/setupmaxloans'
import SetupDefault from '../manages/setupdefault'
import SetupMedia from '../manages/setupmedia'
import SetupQuestions from '../manages/setupquestions'
import SetupEmail from '../manages/setupemail'
import UpdateClient from '../manages/updateclient'
import SetupEmployees from '../manages/setupEmployees'
import SetupManageItems from '../manages/setupmanageitems'
import UpLoadLicense from '../manages/uploadlicense'
import UpdateCompany from '../manages/updateCompany'
import SetupAnnouncement from '../manages/setupAnnouncement'


class TeamManagement extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminjobs:'',user:localStorage.getItem('tokens'),	manageitems:[]};				
		this.handleChange=this.handleChange.bind(this)
	}
	componentDidMount() {										
		axios.post(process.env.REACT_APP_API_ENV+'api/manageitems.php')
			.then(result => {						
				this.setState({manageitems:result.data})																	
			})	
	}
	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	
	render() {							
		return (
			<div className="right_content">
				<CommonHeader titles="Management"/>
				<div style={{textAlign:'center'}}>
					<h2 style={{textAlign:'center'}}>Management</h2><br/><br/>						
					<select name="adminjobs" id="adminjobs" style={{width: '150px', border:'1px solid grey'}} value={this.state.adminjobs} onChange={this.handleChange}>
						<option value="">--select--</option>											
						{this.state.manageitems.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}							
					</select><br/><br/>				
					{(()=>{if(this.state.adminjobs =="1") {return (
							<SetupRoles/>					
						)} else if(this.state.adminjobs =="2" ) {return (
							<SetupProvinceOperations />					
						)} else if(this.state.adminjobs =="3") {return (
							<SetupBanks/>					
						)} else if(this.state.adminjobs =="4") {return (
							<SetupHolidays />
						)} else if(this.state.adminjobs =="5") {return (							
							<SetupOfficehours/>
						)} else if(this.state.adminjobs =="6") {return (
							<SetupAgency/>					
						)} else if(this.state.adminjobs =="7") {return (
							<SetupMaxloans/>
						)} else if(this.state.adminjobs =="8") {return (
							<SetupDefault/>
						)} else if(this.state.adminjobs =="9") {return (
							<SetupMedia/>					
						)} else if(this.state.adminjobs =="10") {return (
							<SetupQuestions/>		
						)} else if(this.state.adminjobs =="11") {return (
							<SetupEmail/>
						)} else if(this.state.adminjobs =="12") {return (
							<UpdateClient/>
						)} else if(this.state.adminjobs =="13") {return (
							<SetupEmployees/>	
						)} else if(this.state.adminjobs =="14") {return (
							<SetupManageItems/>
						)} else if(this.state.adminjobs =="15") {return (
							<UpLoadLicense/>
						)} else if(this.state.adminjobs =="16") {return (
							<UpdateCompany/>
						)} else if(this.state.adminjobs =="17") {return (
							<SetupAnnouncement/>
						)}
					})()}					
				</div>
			</div>
		)
	}
}

export default TeamManagement;