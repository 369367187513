import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios'

class PaydayInfor extends React.Component {	
	constructor(props) {
		super(props)
		this.state = {paydayinfo: [],CompName:'',user:localStorage.getItem('tokens')}				
	}
	
	componentDidMount() {		
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/paydayloaninformation.php',para)
			.then(result => {						
				this.setState({paydayinfo:result.data})								
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})	
	}
		
	render() {    		
		return (
			<div >
				<CommonHeader titles="Payday Loan Status"/>
				{(()=>{if(this.state.paydayinfo[8] === "NEW") {return (<div>					
						<h3>You have submitted a new payday loan application which is in the approving process</h3><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Payday Loan Application Date:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[10]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Payday Loan Applied Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[3]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Payday Loan Cost:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[4]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your First Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[1]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Second Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[2]}</label><br/><br/>
					</div>)} else if(this.state.paydayinfo[8] === "APPROVED") {return (<div>					
						<h3>Your payday loan has been approved and it is in the payment process</h3><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Payday Loan Approved Date:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[11]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Payday Loan Approved Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[5]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Approved Payday Loan Cost:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[6]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled First Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[1]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled Second Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[2]}</label><br/><br/>
					</div>)} else if(this.state.paydayinfo[8] === "PAD") {return (<div>					
						<h3>Your approved payday loan is in the repayment process</h3><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[5]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Cost:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[6]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled First Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[1]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled Second Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[2]}</label><br/><br/>
					</div>)} else if(this.state.paydayinfo[8] === "PAID") {return (<div>					
						<br/><h2 style={{textAlign:'center'}}><b>Receipt of Payment</b></h2><br/><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Name of Borrower:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[16]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Loan Agreement Number:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[10].substring(0,4)+this.state.paydayinfo[10].substring(5,7)+this.state.paydayinfo[10].substring(8,10)}</label><br/><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Loan Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[5]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Loan Agreement Date:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[11].substring(5,7)+'/'+this.state.paydayinfo[11].substring(8,10)+'/'+this.state.paydayinfo[11].substring(0,4)}</label><br/><br/><br/><br/>
						
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Paid Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[6]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Paid Date:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[7]}</label><br/><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Amount still owning on this payday loan:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[7]}</label><br/><br/><br/><br/>
						
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>{this.state.CompName}</b></label>						
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b></b></label><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'left'}}><b>Date / Time: {this.state.paydayinfo[12]}</b></label>				
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}></label><br/><br/>						
					</div>)} else if(this.state.paydayinfo[8] === "NSF") {return (<div>									
						<h3>Your last payday loan is in NSF now, please arrange your repayment, appreciated!</h3><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[5]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Cost:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[6]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled First Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[1]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled Second Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[2]}</label><br/><br/>
					</div>)} else if(this.state.paydayinfo[8] === "DEFAULT") {return (<div>					
						<h3>Your last payday loan is default, Please arrange your repayment with us now, otherwise we will send your case to collection agency for the repayment. </h3><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[5]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Cost:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[6]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled First Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[1]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled Second Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[2]}</label><br/><br/>
					</div>)} else if(this.state.paydayinfo[8] === "COLLECTIONAGENCY") {return (<div>					
						<h3>Your last payday loan has been sent to collection agency who will contact you for your payday loan repayment</h3><br/>						
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Amount:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[5]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Your Payday Loan Cost:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{'$'+this.state.paydayinfo[6]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled First Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[1]}</label><br/><br/>
						<label style={{width: '200px',display:'inline-block',textAlign:'right'}}><b>Scheduled Second Pay Day:</b></label>
						<label style={{width: '200px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[2]}</label><br/><br/>
					</div>)}  else if(this.state.paydayinfo[8] === "CANCEL") {return (<div>					
						<br/><h2 style={{textAlign:'center'}}><b>Receipt</b></h2><br/><br/><br/>							
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
						{this.state.CompName} acknowledges receipt of Notice of Cancellation and repayment of the Loan</label><br/><br/>
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
						Advance within the Cancellation Period from {this.state.paydayinfo[16]}</label><br/><br/><br/>						
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Loan Date:</b></label>						
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}>{this.state.paydayinfo[11]}</label><br/><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Loan Amount:</b></label>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}>{this.state.paydayinfo[5]}</label><br/><br/>						
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>{this.state.CompName}</b></label>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}></label><br/>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Date / Time: {this.state.paydayinfo[12]}</b></label>
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}></label><br/><br/>
					</div>)}
				})()}	
			</div>
		)
	}
}

export default PaydayInfor