import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';

class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'EMPLOYEE',user:localStorage.getItem('tokens'),
						Resp:'',firstname:'',lastname:'',pwd:'',roles:[]};	
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}									
		axios.post(process.env.REACT_APP_API_ENV+'api/findrole.php')			
			.then(result => {		
				this.setState({roles:result.data});
			})
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}						
	
	handleSubmit = event => {
		event.preventDefault()		
		const {firstname,lastname,address,pwd} = this.state		
		if(!firstname){
			alert('Please enter first name')
			return false 
		} 
		if(!lastname){
			alert('Please enter last name')
			return false 
		} 			
		if(!address){
			alert('Please enter employee email address')
			return false 
		} 			
		if(!pwd){
			alert('Please assign employee role')
			return false 
		} 			
		const userObject = {	
			user:this.state.user,			
			manageaction: this.state.Appstatus,
			firstname:this.state.firstname,
			lastname:this.state.lastname,						
			address:this.state.address,			
			pwd:this.state.pwd
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/managementprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Create New Employee Information"/>
				<form onSubmit={this.handleSubmit}>					
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Name:</b></label>
					<input type="text" name="firstname" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.firstname} onChange={this.handleChange} />				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Last Name:</b></label>
					<input type="text" name="lastname" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.lastname} onChange={this.handleChange} /><br/><br/>																	
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.address} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Role:</b></label>
					<select name="pwd" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.pwd} onChange={this.handleChange}>
						<option value=""></option>						
						{this.state.roles.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}	
					</select><br/><br/>									
					<h3>{this.state.Resp}</h3><br/><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/><br/>
				</form>				
			</div>
		)
	}
}

export default NewApplication