import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';

class SendEmail extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'EMAIL',CompName:'',NewApp:[],user:localStorage.getItem('tokens'),
						Resp:'',newapps:'',whours:[]};									
		this.handleChange=this.handleChange.bind(this)		
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')
			.then(result => {				
				this.setState({whours:result.data})			
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {newapps} = this.state		
		if(!newapps){
			alert('Please select whether to send out emails')
			return false 
		} 	
		const userObject = {	
			user:this.state.user,			
			Appstatus: this.state.Appstatus			
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/approveprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>
				<form onSubmit={this.handleSubmit}>
					<h3>Sending Emails</h3><br/>
					<select id="newapps" name="newapps" style={{width: '150px', border:'1px solid grey'}} value = {this.state.newapps} onChange={this.handleChange}>
						<option value="">--Select--</option>		
						<option value="1">Sending Emails</option>		
					</select><br/><br/>	
					{(()=>{if(this.state.newapps === "1") {return (<div>							
						<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
						Our company working hours are from {this.state.whours[0] + ' to '+ this.state.whours[1] + ' except holidays and weekends '}, 
						any emails regarding the approval results outside this period will be stored in database and not be sent out to clients. Please 
						work on this function each morning after {this.state.whours[0]}, thanks.						
						</label><br/><br/>							
						<h3>{this.state.Resp}</h3><br/><br/>
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>					
					</div>)}})()}
				</form>
			</div>
		)
	}
}

export default SendEmail