import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';

class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'', workinghours:[],holidays:[], fName:'',lName:'', email:'', subject:'', comments:'',Resp:''};		
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}	
	componentDidMount() {						
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')			
			.then(result => {	
				this.setState({workinghours:result.data});
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/holidays.php')			
			.then((result) => {				
				this.setState({holidays:result.data});
			})	
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {email} = this.state							
		if(!email){
			alert('Please enter your email address as your login ID for the future use')
			return false
		} else if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email))) {
			alert('Sorry, please enter correct email format')
			return false
		} else {			
			const userObject = {	
				email: this.state.email				
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/changepwd.php', userObject)
				.then((res) => {
						this.setState({Resp:res.data})											
				}).catch((error) => {
					console.log(error)
				});
			
		}
	}
	
	render() {		
		return(
			<div className="right_content">
				<CommonHeader titles="Password"/>				
				<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+this.state.workinghours[0] + ' ~ ' + this.state.workinghours[1]} </span></h3><br/>
				<h3 style={{textAlign:"center"}}><span>{this.state.holidays[2] > 0 ? 'Today our office is closed on holiday--'+this.state.holidays[0]:''}</span></h3><br/>
				<form onSubmit={this.handleSubmit}>	
					<h3 style={{textAlign:"center"}}>Retrieve Your Password</h3><br/><br/>
					<div style={{textAlign:"center"}}>
						<label style={{width: '120px',display:'inline-block',textAlign:'left'}}><b>Your Email Address:</b></label>			
						<input type="text" name="email" id="email" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.email} onChange={this.handleChange}/><br/><br/>
						<h3>{this.state.Resp}</h3><br/><br/>
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
					</div>
				</form>
			</div>
		)
	}
}

export default HomtContents