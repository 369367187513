import React from 'react'
import '../css/styles.css';
import MenuBar from '../pages/Menu'
import QuestionContent from '../pages/questionContent'
import Footer from '../pages/Footer'

export default function Home() {
	return(		
		<div className="futer">
			<div className="menu">    	
				<div className="main">
					<div className="right">
						<MenuBar />
						<QuestionContent />														
					</div>		
					<Footer />
				</div>
			</div>
		</div>		
	)
}

	