import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import dayjs from 'dayjs';
import {PDFDocument} from 'pdf-lib';
import { saveAs } from 'file-saver';

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}

function writeVariables(pages, xvalue, yvalue, variables) {		
	pages.drawText(variables, {
			  x: xvalue,
			  y: yvalue,
			  size:11
	})
}

function createAgreement(PDFVars,COMP,Terms,APR){			
	var existingPdfBytes = '';			
	fetch(process.env.REACT_APP_API_ENV+"api/getagreementpdf.php?user="+PDFVars[17])	
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			
			// Get the first page of the document
			const pages = pdfDoc.getPages()
			const firstPage = pages[0]
			const thirdPage = pages[2]
			const fourPage = pages[3]
			const sixPage = pages[5]
						
			
			// Get the width and height of the first page
			const { width, height } = firstPage.getSize()
			if(PDFVars[17] !== 3) {
				writeVariables(firstPage, 110, height / 2 + 313, COMP[0])//Lender
				writeVariables(firstPage, 105, height / 2 + 299, COMP[5])//email
				writeVariables(firstPage, 350, height / 2 + 195, '$'+PDFVars[5])//amount borrowed
				writeVariables(firstPage, 350, height / 2 + 175, Terms)//Terms of Agreement in days
				writeVariables(firstPage, 350, height / 2 + 155, '$'+PDFVars[6])//total cost of borrowing
				writeVariables(firstPage, 350, height / 2 + 133, '$'+PDFVars[4])//maximum allowed cost per 100
				writeVariables(firstPage, 350, height / 2 + 113, '$'+PDFVars[4])//cost per 100 borrowed
				writeVariables(firstPage, 350, height / 2 + 93, APR+'%')//Annual Percentage rate
				writeVariables(firstPage, 350, height / 2 + 65, PDFVars[22])//Third loan?
				writeVariables(firstPage, 350, height / 2 + 28, PDFVars[23]+'   '+PDFVars[26])
				writeVariables(firstPage, 350, height / 2 - 2, PDFVars[24]+'   '+PDFVars[27])
				writeVariables(firstPage, 350, height / 2 - 35, PDFVars[25]+'   '+PDFVars[28])
				writeVariables(firstPage, 350, height / 2 - 60, PDFVars[19]+'%')//default interest
				writeVariables(firstPage, 350, height / 2 - 80, PDFVars[16])//borrower signature
				writeVariables(firstPage, 370, height / 2 - 142, '$'+PDFVars[20])
				writeVariables(firstPage, 150, height / 2 - 155, '$'+PDFVars[20]/2)			
				writeVariables(firstPage, 310, height / 2 - 215, PDFVars[29])
				writeVariables(firstPage, 380, height / 2 - 215, PDFVars[30])
				writeVariables(firstPage, 450, height / 2 - 215, PDFVars[31])				
				writeVariables(thirdPage, 108, height / 2 - 8, PDFVars[18])
				writeVariables(thirdPage, 320, height / 2 - 8, PDFVars[19])				
				writeVariables(sixPage, 195, height / 2 + 110, COMP[6])
				writeVariables(sixPage, 190, height / 2 + 85, COMP[0])
				writeVariables(sixPage, 415, height / 2 + 85, PDFVars[11])
				writeVariables(sixPage, 190, height / 2 + 60, PDFVars[16])
				writeVariables(sixPage, 415, height / 2 + 60, PDFVars[10])
				writeVariables(sixPage, 200, height / 2 + 30, PDFVars[16])
				writeVariables(sixPage, 415, height / 2 + 30, PDFVars[32])
				writeVariables(sixPage, 190, height / 2 + 2, PDFVars[33]+', '+PDFVars[34]+', '+PDFVars[35]+', '+PDFVars[36])
			} else if(PDFVars[17] === 3 ) {
				writeVariables(firstPage, 110, height / 2 + 313, COMP[0])//Lender
				writeVariables(firstPage, 105, height / 2 + 299, COMP[5])//email
				writeVariables(firstPage, 350, height / 2 + 190, '$'+PDFVars[5])//amount borrowed
				writeVariables(firstPage, 350, height / 2 + 167, Terms)//Terms of Agreement in days
				writeVariables(firstPage, 350, height / 2 + 146, '$'+PDFVars[6])//total cost of borrowing
				writeVariables(firstPage, 350, height / 2 + 125, '$'+PDFVars[4])//maximum allowed cost per 100
				writeVariables(firstPage, 350, height / 2 + 102, '$'+PDFVars[4])//cost per 100 borrowed
				writeVariables(firstPage, 350, height / 2 + 80, APR+'%')//Annual Percentage rate				
				writeVariables(firstPage, 350, height / 2 + 58, PDFVars[23]+'   '+PDFVars[26])
				writeVariables(firstPage, 350, height / 2 + 30, PDFVars[24]+'   '+PDFVars[27])
				writeVariables(firstPage, 350, height / 2 - 8, PDFVars[25]+'   '+PDFVars[28])
				writeVariables(firstPage, 350, height / 2 - 40, PDFVars[19]+'%')//default interest
				writeVariables(firstPage, 350, height / 2 - 60, PDFVars[16])//borrower signature
				writeVariables(thirdPage, 175, height / 2 + 213, PDFVars[18])
				writeVariables(thirdPage, 385, height / 2 + 213, PDFVars[19])				
				writeVariables(fourPage, 263, height / 2 - 38, PDFVars[37])
				writeVariables(fourPage, 508, height / 2 - 38, PDFVars[38])
				writeVariables(fourPage, 336, height / 2 - 68, APR)
				writeVariables(fourPage, 277, height / 2 - 81, PDFVars[18])
				writeVariables(fourPage, 198, height / 2 - 113, PDFVars[19])
				
			}
			const pdfBytes = await pdfDoc.save()
			var blob = new Blob([pdfBytes])			
			saveAs(blob, 'Payday Loan Agreement.pdf')			
		},

		(error) => {
			alert('Error happens');
		}
	)
}


class PaydayAgreement extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {Resp:'',CompName:'',Comps:'',terms:'',APR:'',email:'',datebirth:'',address:'',city:'',province:'',postcode1:'',postcode2:'',postcode3:'',
			postcode4:'',postcode5:'',postcode6:'',states:[],employer:'',employerphone1:'',employerphone2:'',employerphone3:'',datestart1:'',datestart2:'',
			datestart3:'',JobStatus:'',paytype:'',directpay:'',payperiod:'',totalincome:'',r1name:'',rephone1:'',rephone2:'',rephone3:'',r1relation:'',
			paydayinfo:[],downloads:'',user:localStorage.getItem('tokens')};				
	}

	componentDidMount() {		
		const para={user:this.state.user}		
		axios.post(process.env.REACT_APP_API_ENV+'api/paydayloaninformation.php',para)
			.then(result => {						
				this.setState({paydayinfo:result.data})					
				this.setState({terms:dayjs(result.data[12]).diff(dayjs(result.data[11]).format('MM/DD/YYYY'),'d')})	
				this.setState({APR: (100*result.data[6]/(result.data[5]*(dayjs(result.data[12]).diff(dayjs(result.data[11]).format('MM/DD/YYYY'),'d'))/365)).toFixed(0)})
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})	
				this.setState({Comps:result.data})
			})	
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  		
	}
	
	
	render() {	
		return(
			<div >
				<CommonHeader titles="Payday Loan Agreement"/>							
				{(()=>{if(this.state.paydayinfo[8] !== "NEW" && this.state.paydayinfo[8] !== "DECLINED") {return (<div>
					<h2 style={{textAlign:'center'}}><b>Latest / Current Payday Loan</b></h2><br/><br/><br/>
					<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Approved Loan Date:</b></label>						
					<label style={{width: '300px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[11]}</label><br/><br/>				
					<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Approved Loan Amount:</b></label>
					<label style={{width: '300px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[5]}</label><br/><br/>
					<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Borrower:</b></label>
					<label style={{width: '300px',display:'inline-block',textAlign:'left',color:'blue'}}>{this.state.paydayinfo[16]}</label><br/><br/><br/>
					<h3 style={{textAlign:'center'}}>Download Payday Loan Agreement</h3>
					<select name="downloads" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.downloads} onChange={this.handleChange}>
						<option value="">--select--</option>						
						<option value="1">Download Agreement</option>															
					</select><br/><br/><br/>
				</div>)} else {return(<div>
					<h3>You do not have approved payday loan yet, please wait for the approval</h3><br/><br/>
				</div>)}})()}
				{(()=>{if(this.state.downloads === "1" && this.state.paydayinfo.length !== 0 && this.state.paydayinfo[8] !== "NEW" && this.state.paydayinfo[8] !== "DECLINED") {return (<div>
						<h3>Downloading Payday Loan Agreement Now</h3><br/><br/><br/>						
						{createAgreement(this.state.paydayinfo,this.state.Comps, this.state.terms.toString(),this.state.APR)}						
				</div>)}})()}				
			</div>
		)
	}
}

export default PaydayAgreement