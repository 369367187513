import React from "react";
import { useAuth } from "../context/auth";
import Home from "../pages/home";
import Manages from "../manages/Login";

function Logout(props) {
	const { setAuthTokens } = useAuth();
	setAuthTokens(); 	
	localStorage.clear(); 		
	window.location.reload()		
	return (<div>		
		<Manages />		
	</div>)
}

export default Logout;