import React from "react";
import { useAuth } from "../context/auth";
import Home from "../pages/home";

function Logout(props) {
	const { setAuthTokens } = useAuth();
	setAuthTokens(); 	
	localStorage.clear(); 
	//window.location.href='/'	
	window.location.reload()
	return (	
		<Home />		
	)
}

export default Logout;