import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import Announce from '../pages/announce'


class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'', workinghours:[],holidays:[], fName:'',lName:'', email:'', subject:'', comments:'',Resp:'',TTest:''};		
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}	
	
	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')			
			.then(result => {	
				this.setState({workinghours:result.data});
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/holidays.php')			
			.then((result) => {				
				this.setState({holidays:result.data});
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/testing.php')			
			.then((result) => {			console.log(result.data)	
				this.setState({TTest:result.data});
			})	
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {fName,lName,email,subject,comments} = this.state					
		if(!fName) {
			alert('Please enter your first name')
			return false
		}
		if(!lName){
			alert('Please enter your last name')
			return false
		}		
		if(!email){
			alert('Please enter your email address as your login ID for the future use')
			return false
		} else if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email))) {
			alert('Sorry, please enter correct email format')
			return false
		}
		if(!subject){
			alert('Please enter the subject for the message')
			return false
		} 
		if(!comments){
			alert('Please write your message for asking')
			return false
		} else {			
			const userObject = {	
				fName: this.state.fName,
				lName: this.state.lName,
				email: this.state.email,
				subject:this.state.rephone3,
				comments:this.state.r1relation
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/contactmessage.php', userObject)
				.then((res) => {
						this.setState({Resp:'Your message has been sent out successfully, we will get back to you ASAP'})											
				}).catch((error) => {
					console.log(error)
				});
			
		}
	}
	
	render() {		
		return(
			<div className="right_content" style={{textAlign:'center'}}>
				<CommonHeader titles="Home"/>
				<h2  style={{textAlign:'center'}}>Contact {this.state.CompName}</h2><br/><br/>
				<h3 style={{textAlign:"center"}}><span>{'Our Office Hours: Monday to Friday (except holidays) '+this.state.workinghours[0] + ' ~ ' + this.state.workinghours[1]} </span></h3><br/>				
				{(()=>{if(this.state.holidays[2] > 0) {return (<div>
					<h3 style={{textAlign:"center"}}><span>{'Today our office is closed on holiday--'+this.state.holidays[0]}</span></h3><br />						
				</div>)}})()}
				<Announce/>
				<div className="text2">
					<h3>Send your message</h3><br />
					<form onSubmit={this.handleSubmit}>					
						<label><b>First Name: </b></label><br/>
						<input type="text" name="fName" id="fName" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.fName} onChange={this.handleChange}/><br/><br/>
						
						<label ><b>Last Name: </b></label><br/>
						<input type="text" name="lName" id="lName" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.lName} onChange={this.handleChange}/><br/><br/>
						
						<label ><b>Email: </b></label><br/>
						<input type="text" name="email" id="email" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.email} onChange={this.handleChange}/><br/><br/>
						
						<label><b>Subject: </b></label><br/>
						<input type="text" name="subject" id="subject" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.subject} onChange={this.handleChange}/><br/><br/>					
						<label><b>Messages: </b></label><br/>
						<textarea name="comments" id="comments" rows="5" value = {this.state.comments} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
						<h2>{this.state.Resp==="" ? "":this.state.Resp}</h2><br/><br/>
						<button type="submit" width="200px">&nbsp;Send &nbsp;</button><br/><br/><br/>
					</form>
				</div>
			</div>
		)
	}
}

export default HomtContents