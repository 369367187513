import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";

class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'', workinghours:[],holidays:[]};				
	}	
	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/hours.php')			
			.then(result => {	
				this.setState({workinghours:result.data});
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/holidays.php')			
			.then((result) => {				
				this.setState({holidays:result.data});
			})	
	}
	
	
	render() {		
		return(
			<div className="right_content">
				<CommonHeader titles="Privacy"/>
				<h2 >{this.state.CompName} - Privacy Policy</h2><br/><br/>				
				<h3>Our number one priority is to proect your pricacy</h3><br/>
				<p>{this.state.CompName} collects and uses your personal information to operate <Link to="/">{this.state.CompName}</Link> website and delivers the services 
				you have requested. {this.state.CompName} uses your personal identifiable information to inform you of our other products or services available and 
				its affiliates. {this.state.CompName} respects your right and adheres to the following privacy principles to handle all your personally identifiable 
				information carefully and strictly:</p><br/>
				<ol>
					<li>We collect your personally identifiable information to provide better services to you. This practice is strictly limited to payday loan 
						purposes. Your personally identifiable information is under the strict control by our designated individuals for the company's compliance.</li><br/>
					<li>We will not use or disclose your personal information for non-payday loan/cash advance related purposes without the consent of you unless 
						it is required by law.</li><br/>
					<li>We will obtain your consent and knowledge before your personal information is used and disclosured. All the personally identifiable information 
						you are asked to provide via the online application system is stored on limited access servers and is dealt with strictly in accordance with 
						this policy</li><br/>
					<li>We will make reasonable efforts ensure that personal information is as accurate, complete, and up-to-date as is necessary for the purposes 
						of payday loan/cash advance practices. You have access to the personal information we have on file about you and have the right to 
						correct any misinformation we collect from you.</li><br/>
					<li>We strictly maintain and implement procedures to protect your personal information and review our compliance regularly.</li><br/>
					<li>we fully comply with all laws and regulations applicable with this privacy policy.</li><br/>
					<li>We are not responsible for the privacy practices of websites that are linked from our website. Please consult the privacy policy for 
						the website you link to from {this.state.CompName}</li><br/>
				</ol>
			</div>
		)
	}
}

export default HomtContents