import './css/styles.css';
import React, { useState } from "react";
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import { AuthContext } from "./context/auth";
import PrivateRoute from './PrivateRoute';
import Member from "./login/Login";
import Logout from './login/logout';
import EmpOut from './manages/logout';
import Home from "./pages/home";
import Apply from "./pages/apply";
import Agreement from "./pages/agreement";
import AppDone from "./pages/AppDone";
import MemberApp from "./pages/MemberApp";
import DirectFail from "./pages/DirectFail";
import IncomeFail from "./pages/IncomeFail";
import Contact from "./pages/contact";
import Questions from "./pages/questions";
import Privacy from "./pages/privacy";
import Payday from "./pages/paydayloan";
import Paybank from "./pages/paybank";
import Reloan from "./pages/reloan";
import Setting from "./pages/Management";
import Management from "./manages/Management";
import Admin from "./manages/Management";
import Report from "./manages/report";
import Search from "./manages/search";
import Manages from "./manages/Login";
import Application from "./manages/application";
import PWDchange from "./pages/pwdchanges";
import ManageSetting from "./manages/settings"


function App() {
	const [authTokens, setAuthTokens] = useState();    
	const setTokens = (data) => {	
		if(!data) {
			setAuthTokens(data);
		} else {						
			localStorage.setItem("tokens", JSON.stringify(data[2]));										
			setAuthTokens(data);
		}
	}	
	return (
		<AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>	
			<Router>		
				<div>				        	
					<Switch>				
						<Route exact path="/" component={Home} />				
						<Route exact path="/Apply" component={Apply} />				
						<Route exact path="/Agreement" component={Agreement} />	
						<Route exact path="/AppDone" component={AppDone} />	
						<Route exact path="/MemberApp" component={MemberApp} />	
						<Route exact path="/DirectFail" component={DirectFail} />	
						<Route exact path="/IncomeFail" component={IncomeFail} />	
						<Route exact path="/Contact" component={Contact} />
						<Route exact path="/Questions" component={Questions} />
						<Route exact path="/Privacy" component={Privacy} />						
						<Route exact path="/Member" component={Member} />	
						<Route exact path="/PWDchange" component={PWDchange} />	
						<Route exact path="/Manages" component={Manages} />	
						<PrivateRoute path="/Payday" component={Payday} />						
						<PrivateRoute path="/Paybank" component={Paybank} />
						<PrivateRoute path="/Reloan" component={Reloan} />
						<PrivateRoute path="/Setting" component={Setting} />	
						<PrivateRoute path="/Management" component={Management} />	
						<PrivateRoute path="/Admin" component={Admin} />	
						<PrivateRoute path="/Report" component={Report} />	
						<PrivateRoute path="/Search" component={Search} />	
						<PrivateRoute path="/Application" component={Application} />						
						<PrivateRoute path="/Logout" component={Logout} />
						<PrivateRoute path="/EmpOut" component={EmpOut} />
						<PrivateRoute path="/ManageSetting" component={ManageSetting} />
					</Switch>        		
				</div>
			</Router>
		</AuthContext.Provider>
	)
}

export default App;
