import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}
class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'CLIENT',CompName:'',user:localStorage.getItem('tokens'),profileinfo:[],
						Resp:'',newapps:'',firstname:'',lastname:'',AID:'',PID:'',BID:'',datebirth:'',address:'',city:'',province:'',
						postcode:'', cellphone:'',employer:'',ephone:'',payfreq:'',paytype:'',income:'',directdeposit:'',firstpay:'',
						secondpay:'',bankname:'',finid:'',transit:'',checkid:'',debitid:'',pwd:'',s1:'',a1:'',s2:'',a2:'',s3:'',a3:'',
						s4:'',a4:'',s5:'',a5:'',states:[],banks:[]};	
		this.handleAPPChange=this.handleAPPChange.bind(this)
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
		this.handleBankChange=this.handleBankChange.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/findprovinces.php')			
			.then(result => {		
				this.setState({states:result.data});
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')			
			.then(result => {		
				this.setState({banks:result.data});
			})
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleBankChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		const userObject = {bank:event.target.value}
		axios.post(process.env.REACT_APP_API_ENV+'api/findFIN.php',userObject)
			.then(result => {						
				this.setState({finid:result.data})				
			})
	}
	
	handleAPPChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})			
		const userObject = {AID:event.target.value}
		axios.post(process.env.REACT_APP_API_ENV+'api/updateclientinfo.php',userObject)
			.then(result => {						
				this.setState({profileinfo:result.data})
				this.setState({firstname: result.data[6]})
				this.setState({lastname: result.data[7]})
				this.setState({AID:result.data[0]})
				this.setState({PID:result.data[1]})
				this.setState({BID:result.data[2]})
				this.setState({datebirth:result.data[10]})
				this.setState({address:result.data[11]})
				this.setState({city:result.data[12]})
				this.setState({province:result.data[13]})
				this.setState({postcode:result.data[14]})
				this.setState({cellphone:result.data[15]})
				this.setState({employer:result.data[19]})
				this.setState({ephone:result.data[20]})
				this.setState({payfreq:result.data[21]})
				this.setState({paytype:result.data[22]})
				this.setState({income:result.data[23]})
				this.setState({directdeposit:result.data[24]})
				this.setState({fdate1:result.data[3].substring(6,10)})
				this.setState({fdate2:result.data[3].substring(0,2)})
				this.setState({fdate3:result.data[3].substring(3,5)})				
				this.setState({sdate1:result.data[4].substring(6,10)})
				this.setState({sdate2:result.data[4].substring(0,2)})
				this.setState({sdate3:result.data[4].substring(3,5)})	
				this.setState({bankname:result.data[25]})
				this.setState({finid:result.data[27]})
				this.setState({transit:result.data[26]})
				this.setState({checkid:result.data[28]})
				this.setState({debitid:result.data[29]})
				this.setState({pwd:result.data[30]})
				this.setState({s1:result.data[31]})
				this.setState({a1:result.data[32]})
				this.setState({s2:result.data[33]})
				this.setState({a2:result.data[34]})
				this.setState({s3:result.data[35]})
				this.setState({a3:result.data[36]})
				this.setState({s4:result.data[37]})
				this.setState({a4:result.data[38]})
				this.setState({s5:result.data[39]})
				this.setState({a5:result.data[40]})					
			})
				
						
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {newapps, firstname,lastname,datebirth,address,city,province,postcode,cellphone,employer,ephone,payfreq,paytype,
				income,directdeposit,firstpay,secondpay,bankname,finid,transit,checkid,debitid,pwd,s1,a1,s2,a2,s3,a3,s4,a4,s5,a5} = this.state		
		if(!newapps){
			alert('Please enter accountid')
			return false 
		} 			
		const userObject = {	
			user:this.state.user,
			applicationid:this.state.newapps,
			manageaction: this.state.Appstatus,
			firstname:this.state.firstname,
			lastname:this.state.lastname,			
			datebirth:this.state.datebirth,
			address:this.state.address,
			city:this.state.city,
			province:this.state.province,
			postcode:this.state.postcode,
			cellphone:this.state.cellphone,
			employer:this.state.employer,
			ephone:this.state.ephone,
			payfreq:this.state.payfreq,
			paytype:this.state.paytype,
			income:this.state.income,
			directdeposit:this.state.directdeposit,
			fdate1:this.state.fdate1,
			fdate2:this.state.fdate2,
			fdate3:this.state.fdate3,
			sdate1:this.state.sdate1,
			sdate2:this.state.sdate2,
			sdate3:this.state.sdate3,
			bankname:this.state.bankname,
			finid:this.state.finid,
			transit:this.state.transit,
			checkid:this.state.checkid,
			debitid:this.state.debitid,
			pwd:this.state.pwd,			
			s1:this.state.s1,
			a1:this.state.a2,
			s2:this.state.s2,
			a2:this.state.a2,
			s3:this.state.s3,
			a3:this.state.a3,
			s4:this.state.s4,
			a4:this.state.a4,
			s5:this.state.s5,
			a5:this.state.a5,
			AID:this.state.AID,
			PID:this.state.PID,
			BID:this.state.BID
			
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/managementprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Update Client Information"/>
				<form onSubmit={this.handleSubmit}>
					<label><b>Please Enter AccountID</b></label><br/>
					<input type="text" name="newapps" style={{width: '150px', border:'1px solid grey'}} value = {this.state.newapps} onChange={this.handleAPPChange} />
					<br/><br/><br/>				
					<h3>Update Client Information</h3>
					<h4>Note: please follow the below given format to update the information</h4><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Name:</b></label>
					<input type="text" name="firstname" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.firstname} readOnly/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Last Name:</b></label>
					<input type="text" name="lastname" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.lastname} readOnly/><br/><br/>												
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Account ID:</b></label>
					<input type="text" name="AID" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.AID} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Profile ID:</b></label>
					<input type="text" name="PID" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.PID} readOnly/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Date of Birth:</b></label>
					<input type="text" name="datebirth" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.datebirth} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Address:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.address} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>City:</b></label>
					<input type="text" name="city" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.city} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
					<select name="province" style={{width: '160px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.province} onChange={this.handleChange}>
						<option value=""></option>
						<option value="0">--Select province--</option>		
						{this.state.states.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}	
					</select><br/><br/>									
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Post Code:</b></label>
					<input type="text" name="postcode" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.postcode} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Cell Phone:</b></label>
					<input type="text" name="cellphone" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.cellphone} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer:</b></label>
					<input type="text" name="employer" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.employer} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer Phone:</b></label>
					<input type="text" name="ephone" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.ephone} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Frequency:</b></label>
					<select name="payfreq" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.payfreq} onChange={this.handleChange} >
						<option value="">- Select -</option>
						<option value="7"  key="1">Weekly</option>
						<option value="14" key="2">Bi-weekly</option>
						<option value="15" key="3">Semi-monthly</option>
						<option value="30" key="4">Monthly</option>
					</select>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Type:</b></label>
					<select name="paytype" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.paytype} onChange={this.handleChange}>
						<option value="">- Select -</option>
						<option value="Salary" key="1">Salary</option>
						<option value="Hourly" key="2" >Hourly</option>
					</select><br/><br/>									
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Each Payment:</b></label>
					<input type="text" name="income" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.income} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>
					<select name="directdeposit" style={{width: '160px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.directdeposit} onChange={this.handleChange}>
						<option value="">--select--</option>
						<option value="YES" key="1">Yes</option>
						<option value="NO" key="2">No</option>
					</select><br/><br/>		
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
					<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
					<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Pay Day:</b></label>					
					<select style={{width: '55px',border:'1px solid #4195fc',marginLeft:'10px'}} name="fdate1" value={this.state.fdate1} onChange={this.handleChange}>
						<option value="">--</option> 
						<option value={thisyear}>{thisyear}</option>
						{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 31) {return (
							<option value={nextyear}>{nextyear}</option> 				
						)}})()}
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate2" value={this.state.fdate2} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate3" value={this.state.fdate3} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>			
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Second Pay Day:</b></label>					
					<select style={{width: '55px',border:'1px solid #4195fc',marginLeft:'10px'}} name="sdate1" value={this.state.sdate1} onChange={this.handleChange}>
						<option value="">--</option> 
						<option value={thisyear}>{thisyear}</option>
						{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 31) {return (
							<option value={nextyear}>{nextyear}</option> 				
						)}})()}
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate2" value={this.state.sdate2} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select>-
					<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate3" value={this.state.sdate3} onChange={this.handleChange}>
						<option value="">--</option>
						{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
					</select><br/><br/>					
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
					<select name="bankname" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.bankname} onChange={this.handleBankChange}>
						<option value="">--Select Bank--</option>
						{this.state.banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}						
					</select>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>
					<input type="text" name="finid" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} maxLength="3" value={this.state.finid} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Branch Transit:</b></label>
					<input type="text" name="transit" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} maxLength="5" value={this.state.transit} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Check Number:</b></label>
					<input type="text" name="checkid" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} maxLength="12" value={this.state.checkid} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
					<input type="text" name="debitid" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.debitid} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Online PWD:</b></label>
					<input type="text" name="pwd" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.pwd} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
					<input type="text" name="s1" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.s1} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
					<input type="text" name="a1" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.a1} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
					<input type="text" name="s2" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.s2} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
					<input type="text" name="a2" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.a2} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
					<input type="text" name="s3" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.s3} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
					<input type="text" name="a3" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.a3} onChange={this.handleChange}/><br/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
					<input type="text" name="s4" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.s4} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
					<input type="text" name="a4" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.a4} onChange={this.handleChange}/><br/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
					<input type="text" name="s5" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.s5} onChange={this.handleChange}/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
					<input type="text" name="a5" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.a5} onChange={this.handleChange}/><br/><br/>									
					<h3>{this.state.Resp}</h3><br/><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/><br/>
				</form>				
			</div>
		)
	}
}

export default NewApplication