import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}
class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'NEW',CompName:'',NewApp:[],user:localStorage.getItem('tokens'),profileinfo:[],Resp:'',newapps:'', Hist:[],
						appdecline:'',loans:[],approvedloan:'',appcost:'',payschedules:'', install1:'', install2:'',install3:'',
						fdate1:'',fdate2:'',fdate3:'',sdate1:'',sdate2:'',sdate3:'',tdate1:'',tdate2:'',tdate3:'',appcredit:'',
						comments:'No Comment',maxloan:[],reasons:'', ReasonList:[], QLists:[], questions:'',addq1:'',addq2:'',
						inquests:'',otherq:'',ploanpaid:''};							
		this.handleAPPChange=this.handleAPPChange.bind(this)
		this.handleDecChange=this.handleDecChange.bind(this)
		this.handleChange=this.handleChange.bind(this)
		this.handleLoanChange=this.handleLoanChange.bind(this)
		this.handleSchChange=this.handleSchChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/newapplication.php')
			.then(result => {				
				if(result.data[0][0] === "No") {
					this.setState({Resp:"No new application now"})
				} else {
					this.setState({Resp:""})
					this.setState({NewApp:result.data})
				}
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/maxloans.php')
			.then(result => {				
				this.setState({maxloan:result.data})													
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/reasonlists.php')
			.then(result => {				
				this.setState({ReasonList:result.data})													
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/questionlists.php')
			.then(result => {				
				this.setState({QLists:result.data})													
			})	
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleSchChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value === "1") {
			this.setState({install1:parseFloat(this.state.approvedloan)+parseFloat(0.01*this.state.approvedloan*this.state.profileinfo[47])})			
		} else {
			this.setState({install1:""})			
		}
		
	}
	handleLoanChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value === "") {			
			this.setState({install1:""})
			this.setState({payschedules:""})
		} 
		
	}
	handleDecChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value === "") {
			this.setState({approvedloan:""})
			this.setState({install1:""})
			this.setState({payschedules:""})
		} 		
	}
	
	handleAPPChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})			
		const userObject = {	
				PID:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0],
				AID:event.target.value
			 }
		axios.post(process.env.REACT_APP_API_ENV+'api/applicationinfo.php',userObject)
			.then(result => {						
				this.setState({profileinfo:result.data})
				this.setState({comments:result.data[17]})
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/histrecord.php',userObject)
			.then(result => {						
				this.setState({Hist:result.data})				
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/loanforapproval.php',userObject)
			.then(result => {						
				this.setState({loans:result.data})				
			})					
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {newapps, appdecline,approvedloan,payschedules, install1,install2,install3,fdate1,fdate2,fdate3,sdate1,sdate2,sdate3,
				tdate1,tdate2,tdate3,appcredit,comments,reasons,questions,addq1,addq2,inquests,otherq,ploanpaid} = this.state		
		if(!newapps){
			alert('Please select application ID')
			return false 
		} 	
		if(!ploanpaid){
			if(!appdecline){
				alert('Please select approval, refuse or ask question')
				return false
			} else if(appdecline === '1'){
				if(!approvedloan){
					alert('Please select to be approved loan')
					return false 
				} 
				if(!payschedules){
					alert('Please select payment schedule')
					return false 
				} else if(payschedules !== ''){
					if(!install1){
						alert('Please enter installment 1 amount')
						return false 
					} 
					if(!fdate1){
						alert('Please select intallment 1 payday year')
						return false 
					} 
					if(!fdate2){
						alert('Please select intallment 1 payday month')
						return false 
					} 
					if(!fdate3){
						alert('Please select intallment 1 payday day')
						return false 
					} 
				} else if(payschedules === '2'){
					if(!install2){
						alert('Please enter installment 2 amount')
						return false 
					} 
					if(!sdate1){
						alert('Please select intallment 2 payday year')
						return false 
					} 
					if(!sdate2){
						alert('Please select intallment 2 payday month')
						return false 
					} 
					if(!sdate3){
						alert('Please select intallment 2 payday day')
						return false 
					} 
				} else if(payschedules === '3'){
					if(!install2){
						alert('Please enter installment 2 amount')
						return false 
					} 
					if(!sdate1){
						alert('Please select intallment 2 payday year')
						return false 
					} 
					if(!sdate2){
						alert('Please select intallment 2 payday month')
						return false 
					} 
					if(!sdate3){
						alert('Please select intallment 2 payday day')
						return false 
					} 
					if(!install3){
						alert('Please enter installment 3 amount')
						return false 
					} 
					if(!tdate1){
						alert('Please select intallment 3 payday year')
						return false 
					} 
					if(!tdate2){
						alert('Please select intallment 3 payday month')
						return false 
					} 
					if(!tdate3){
						alert('Please select intallment 3 payday day')
						return false 
					} 
				}
			} else if(appdecline === '2'){		
				if(!reasons){
					alert('Please select reason to refuse application')
					return false 
				} 			
			} else if(appdecline === '3'){		
				if(!questions){
					alert('Please select questin to ask client')
					return false 
				} else if(this.state.questions === 'a') {
					if(!addq1){
						alert('Please enter banking additional security question 1 to ask client ')
						return false 
					}
				} else if(this.state.questions === 'i') {
					if(!inquests){
						alert('Please select invalid security question/answer ')
						return false 
					}
				} else if(this.state.questions === 'o') {
					if(!otherq){
						alert('Please enter other question for asking')
						return false 
					}
				}
			} 
		}
		const userObject = {	
			user:this.state.user,
			applicationid:this.state.newapps,
			Appstatus: this.state.Appstatus,
			appdecline: this.state.appdecline,
			approvedloan: this.state.approvedloan,				
			appcost: 0.01*this.state.approvedloan*this.state.profileinfo[47],
			payschedules: this.state.payschedules,
			install1: this.state.install1,
			install2: this.state.install2,
			install3: this.state.install3,
			fdate1: this.state.fdate1,
			fdate2: this.state.fdate2,								
			fdate3: this.state.fdate3,
			sdate1: this.state.sdate1,				
			sdate2: this.state.sdate2,
			sdate3: this.state.sdate3,
			tdate1: this.state.tdate1,
			tdate2: this.state.tdate2,
			tdate3:this.state.tdate3,
			appcredit:this.state.appcredit,
			comments:this.state.comments,
			firstpay:this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[3])[0],
			secondpay: this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[4])[0],
			email:this.state.profileinfo[0],
			firstname:this.state.profileinfo[1],
			lastname:this.state.profileinfo[2],
			reasons:this.state.reasons,
			days:this.state.days,
			qcontent:this.state.QLists.filter(sub => sub[0] === this.state.questions).map(sub=>sub[1])[0],
			qtitle:this.state.QLists.filter(sub => sub[0] === this.state.questions).map(sub=>sub[0])[0],
			addq1:this.state.addq1,
			addq2:this.state.addq2,
			s1:this.state.profileinfo[33],
			a1:this.state.profileinfo[34],
			s2:this.state.profileinfo[35],
			a2:this.state.profileinfo[36],
			s3:this.state.profileinfo[37],
			a3:this.state.profileinfo[38],
			s4:this.state.profileinfo[39],
			a4:this.state.profileinfo[40],
			s5:this.state.profileinfo[41],
			a5:this.state.profileinfo[42],
			inquests:this.state.inquests,
			otherq:this.state.otherq,
			FIN:this.state.profileinfo[32],
			Transit:this.state.profileinfo[31],
			CHECK:this.state.profileinfo[10]
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/approveprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>
				<label><b>Please Select Application ID:</b></label><br/>
				<select id="newapps" name="newapps" style={{width: '150px', border:'1px solid grey'}} value = {this.state.newapps} onChange={this.handleAPPChange}>
					<option value="">--Select Application ID--</option>		
					{this.state.NewApp.map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
				</select><br/><br/>	
				{(()=>{if(this.state.newapps !== "") {return (<div>	
					<h3>Current Application</h3><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[1]} readOnly/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Last Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[2]} readOnly/><br/>							
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Gender:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[19]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Date of Birth:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[20]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[43]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[30]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[32]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Branch Transit:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[31]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Check Number:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[10]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[11]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Online PWD:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[12]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[33]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[34]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[35]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[36]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[37]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[38]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[39]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[40]} readOnly/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[41]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[42]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[44]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer Phone:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[45]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Job Started:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[46]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employment Type:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[5]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Type:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[6]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Frequency:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[7]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[8]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[9]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Pay Day:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[3])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Second Pay Day:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[4])[0]} readOnly/><br/>											
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Profile ID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[1])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Visit Times:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[16]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Applied Loan:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[5])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Applied Loan Cost:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[6])[0]}readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Applied Date:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>AccountID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[0])[0]} readOnly/><br/><br/>
					<h3>Historical Record Checking</h3><br/>
					<select id="hischeck" name="hischeck" style={{width: '150px', border:'1px solid grey'}} value = {this.state.hischeck} onChange={this.handleChange}>
						<option value="">--Select--</option>							
						<option value="1">Check Historical Payday Loans</option>							
					</select><br/><br/>	
					{(()=>{if(this.state.hischeck === "1") {return (<div>					
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>AccountID</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Bank</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Check #</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Approved Loan</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Charge</b></label>			
						<label style={{width: '130px',display:'inline-block',textAlign:'center'}}><b>Approved Date</b></label>
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Due Day</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Paid AMT</b></label>	
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>NSF AMT</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Loan Process</b></label>
						{this.state.Hist.slice(0, this.state.Hist.length).map(item => {return (<div>						
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[4]}</label>
							<label style={{width: '130px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[5]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[8]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[6]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[9]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[7]}</label>
						</div>)})}<br/><br/>					
					</div>)}})()}
					<h3>Update to Loan Paid</h3>
					<label style={{width: '600px',display:'inline-block',textAlign:'center'}}>
					Please enter the AccountID and update the loan paid</label><br/><br/>					
					<input type="text" name="ploanpaid" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.ploanpaid} onChange={this.handleChange}/><br/><br/>					
					<h3>Approve Process</h3>
					<label style={{width: '600px',display:'inline-block',textAlign:'center'}}>Please verify the applicant banking information from the checking account which the applicant provided in 
					the above and the applicant historical record to decide whether we will approve the applicant payday loan.</label><br/><br/>
					<form onSubmit={this.handleSubmit}>
						<label><b>Approval decision:</b></label><br/>
						<select id="appdecline" name="appdecline" style={{width: '150px', border:'1px solid grey'}} value = {this.state.appdecline} onChange={this.handleDecChange}>
							<option value="">--Select--</option>		
							<option value="1">Approve Loan</option>		
							<option value="2">Refuse Loan</option>									
							<option value="3">Ask Question</option>		
						</select><br/><br/>	
						{(()=>{if(this.state.appdecline === "1") {return (<div>
								<h3 style={{textAlign:'center'}}>Clients can view/print Payday Loan Agreement from their personal page if they request</h3><br/>
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Loan:</b></label>
								<select id="approvedloan" name="approvedloan" style={{width: '150px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.approvedloan} onChange={this.handleLoanChange}>
									<option value="">--Select--</option>		
									{this.state.loans.map(item => (<option value={item[1]} key={item[0]}>{item[1]}</option>))}		
								</select>
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Loan Cost:</b></label>
								<input type="text" name="appcost" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={0.01*this.state.approvedloan*this.state.profileinfo[47]} readOnly/><br/><br/>
								{(()=>{if(this.state.approvedloan !== "") {return (<div>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Schedules:</b></label>
									<select name="payschedules" style={{width: '150px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.payschedules} onChange={this.handleSchChange}>
										<option value="">--Select--</option>									
										<option value="1">1 Installment</option>
										<option value="2">2 Installments</option>
										<option value="3">3 Installments</option>									
									</select>
									<label style={{width: '300px',display:'inline-block',textAlign:'right'}}>This schedule is for pay installment</label><br/><br/>
								</div>)}})()}
								{(()=>{if(this.state.payschedules !== "") {return (<div>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
									<label style={{width: '160px',display:'inline-block',textAlign:'center'}}></label>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
									<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
									<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
									<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>																	
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Installment 1:</b></label>
									<input type="text" name="install1" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.install1} onChange={this.handleChange} />
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payday:</b></label>	
									<select style={{width: '55px',border:'1px solid #4195fc'}} name="fdate1" value={this.state.fdate1} onChange={this.handleChange}>
										<option value="">--</option> 
										<option value={thisyear}>{thisyear}</option> 
										{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 31) {return (
											<option value={nextyear}>{nextyear}</option> 				
										)}})()}
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate2" value={this.state.fdate2} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate3" value={this.state.fdate3} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select><br/>
								</div>)}})()}
								{(()=>{if(this.state.payschedules === "2" || this.state.payschedules === "3") {return (<div>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Installment 2:</b></label>
									<input type="text" name="install2" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.install2} onChange={this.handleChange} />					
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payday:</b></label>	
									<select style={{width: '55px',border:'1px solid #4195fc'}} name="sdate1" value={this.state.sdate1} onChange={this.handleChange}>
										<option value="">--</option> 
										<option value={thisyear}>{thisyear}</option> 
										{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 62) {return (
											<option value={nextyear}>{nextyear}</option> 				
										)}})()}
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate2" value={this.state.sdate2} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate3" value={this.state.sdate3} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select><br/>
								</div>)}})()}
								{(()=>{if(this.state.payschedules === "3") {return (<div>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Installment 3:</b></label>
									<input type="text" name="install3" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.install3} onChange={this.handleChange} />					
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payday:</b></label>	
									<select style={{width: '55px',border:'1px solid #4195fc'}} name="tdate1" value={this.state.tdate1} onChange={this.handleChange}>
										<option value="">--</option> 
										<option value={thisyear}>{thisyear}</option> 
										{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 62) {return (
											<option value={nextyear}>{nextyear}</option> 				
										)}})()}
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="tdate2" value={this.state.tdate2} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="tdate3" value={this.state.tdate3} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select><br/>
								</div>)}})()}<br/>
								{(()=>{if(this.state.payschedules !== "") {return (<div>
									<label><b>Approved Credit</b></label><br/>
									<select style={{width: '150px',border:'1px solid #4195fc'}} name="appcredit" value={this.state.appcredit} onChange={this.handleChange}>
											<option value="">-Assign Credit-</option> 										
											{this.state.maxloan.filter(sub=>sub[0] <= this.state.profileinfo[3]).map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}
									</select><br/>
									<label>Assign client credit to any level. Ignore this if no change</label><br/><br/>
									<label><b>Comments</b></label><br/>
									<textarea name="comments" id="comments" rows="5" value = {this.state.comments} style={{width: '300px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
								</div>)}})()}
						</div>)} else if(this.state.appdecline === "2") {return (<div>
							<label><b>Reason to Refuse</b></label><br/>
							<select style={{width: '150px',border:'1px solid #4195fc'}} name="reasons" value={this.state.reasons} onChange={this.handleChange}>
								<option value="">-select-</option> 										
								{this.state.ReasonList.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}
							</select><br/><br/>
							<label><b>Comments</b></label><br/>
							<textarea name="comments" id="comments" rows="5" value = {this.state.comments} style={{width: '300px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>							
						</div>)} else if(this.state.appdecline === "3") {return (<div>
							<label><b>Question to Ask</b></label><br/>
							<select style={{width: '150px',border:'1px solid #4195fc'}} name="questions" value={this.state.questions} onChange={this.handleChange}>
								<option value="">-select-</option> 										
								<option value="a">Additional Security Questions</option> 			
								<option value="i">Invalid Security Questions</option> 											
								{this.state.QLists.map(item => (<option value={item[0]} key={item[0]}>{item[2]}</option>))}
								<option value="o">Other Questions</option> 			
							</select><br/><br/>	
							{(()=>{if(this.state.questions === "a" ) {return (<div>
								<h3>Question to Client</h3><br/>
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Dear {this.state.profileinfo[1]+' '+this.state.profileinfo[2]+','}</label><br/><br/>
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
								Since the banking system is asking the following questions, could you please provide the answers?</label><br/><br/>
								<label style={{width: '150px',display:'inline-block',textAlign:'left'}}><b>Additional Question 1:</b></label>
								<input type="text" name="addq1" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.addq1} onChange={this.handleChange} />
								<label style={{width: '150px',display:'inline-block',textAlign:'left'}}><b></b></label>
								<label style={{width: '150px',display:'inline-block',textAlign:'left'}}><b></b></label><br/><br/>
								<label style={{width: '150px',display:'inline-block',textAlign:'left'}}><b>Additional Question 2:</b></label>
								<input type="text" name="addq2" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.addq2} onChange={this.handleChange} />
								<label style={{width: '150px',display:'inline-block',textAlign:'left'}}><b></b></label>
								<label style={{width: '150px',display:'inline-block',textAlign:'left'}}><b></b></label><br/><br/>													
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Please login onto <Link to="/Member" style={{color:'blue'}}>{this.state.CompName}</Link> to update your security questions.</label>
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Thanks for your cooperation and business!</label>
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>{this.state.CompName} Team</label><br/><br/>								
							</div>)} else if(this.state.questions === "i" ) {return (<div>
								<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Invalid Security Questions/Answers:</b></label>
								<select style={{width: '150px',border:'1px solid #4195fc'}} name="inquests" value={this.state.inquests} onChange={this.handleChange}>
									<option value="">-select-</option> 										
									<option value="1">Security Question 1</option> 			
									<option value="2">Security Question 2</option> 																				
									<option value="3">Security Question 3</option> 			
									<option value="4">Security Question 4</option> 			
									<option value="5">Security Question 5</option> 			
								</select>								
								<label style={{width: '150px',display:'inline-block',textAlign:'left'}}><b></b></label><br/><br/>
								{(()=>{if(this.state.inquests !== "" ) {return (<div>								
									<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Dear {this.state.profileinfo[1]+' '+this.state.profileinfo[2]+','}</label><br/><br/>
									<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>
									Since you provided invalid security question {this.state.inquests} or answer, 
									please login onto <Link to="/Member" style={{color:'blue'}}>{this.state.CompName}</Link> to update your security question or answer.</label><br/>
									<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>You provded security question and answer: <br/>{'security question:'+this.state.profileinfo[32+2*this.state.inquests-1]+', answer：'+this.state.profileinfo[33+2*this.state.inquests-1]}</label><br/>
									<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Thanks for your cooperation and business!</label><br/>
									<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>{this.state.CompName} Team</label><br/><br/>								
								</div>)}})()}								
							</div>)} else if(this.state.questions === "o" ) {return (<div>
								<h3>Question to Client</h3><br/>
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Dear {this.state.profileinfo[1]+' '+this.state.profileinfo[2]+','}</label><br/><br/>							
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Please write question here</label><br/>
								<textarea name="otherq" rows="5" value = {this.state.otherq} style={{width: '600px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
							</div>)} else if(this.state.questions !== "" && this.state.questions !== "a" && this.state.questions !== "i" && this.state.questions !== "o" ) {return (<div>
								<h3>Question to Client</h3><br/>
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>Dear {this.state.profileinfo[1]+' '+this.state.profileinfo[2]+','}</label><br/><br/>							
								<label style={{width: '600px',display:'inline-block',textAlign:'left'}}>{this.state.QLists.filter(sub => sub[0] === this.state.questions).map(sub=>sub[1])[0]}</label><br/><br/>														
							</div>)}})()}
						</div>)}})()}
						<h3>{this.state.Resp}</h3><br/><br/>
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
					</form>
				</div>)}})()}
			</div>
		)
	}
}

export default NewApplication