import React from 'react'
import '../css/styles.css';
import MenuBar from '../manages/LoginMenu'
import ManagementContent from '../manages/settingContent'
import Footer from '../pages/Footer'

export default function Payday() {
	return(		
		<div className="futer">
			<div className="menu">    	
				<div className="main">
					<div className="right">				
						<MenuBar />
						<ManagementContent />			
					</div>		
					<Footer />
				</div>
			</div>
		</div>
	)
}
