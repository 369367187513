import CommonHeader from '../pages/Head'
import React from 'react'
import PaydayStatus from '../pages/paydayStatus'
import PaydayCancel from '../pages/paydayCancel'
import PaydayAgreement from '../pages/paydayAgreement'
import ChangePWD from '../pages/changepassword'

class PaydayLoans extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminjobs:'',user:localStorage.getItem('tokens')};				
		this.handleChange=this.handleChange.bind(this)
	}
	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	
	render() {							
		return (
			<div className="right_content">
				<CommonHeader titles="Payday Loan"/>
				<div style={{textAlign:'center'}}>
				<h3 style={{textAlign:'center'}}>Payday Loan Information</h3>
					<select name="adminjobs" id="adminjobs" style={{width: '150px',border:'1px solid #4195fc'}} value={this.state.adminjobs} onChange={this.handleChange}>
						<option value="">--Manage Items--</option>						
						<option value="1">Payday Loan Status</option>						
						<option value="2">Cancel Payday Loan</option>
						<option value="3">Payday Loan Agreement</option>
						<option value="4">Change PWD</option>			
					</select><br/><br/>				
				{(()=>{
					if(this.state.adminjobs === "1") {return (
						<PaydayStatus/>					
					)} else if(this.state.adminjobs === "2") {return (
						<PaydayCancel/>					
					)} else if(this.state.adminjobs === "3") {return (
						<PaydayAgreement/>					
					)} else if(this.state.adminjobs === "4") {return (
						<ChangePWD />					
					)} 
				})()}				
				</div>
			</div>
		)
	}
}

export default PaydayLoans;