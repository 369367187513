import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}
class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'PAD',CompName:'',NewApp:[],user:localStorage.getItem('tokens'),profileinfo:[],Resp:'',newapps:'',
						appdecline:'',loans:[],approvedloan:'',payschedules:'', install1:'', install2:'',install3:'',fdate1:'',fdate2:'',fdate3:'',
						sdate1:'',sdate2:'',sdate3:'',tdate1:'',tdate2:'',tdate3:'',comments:'No Comment',newpayment:'',partpaid:'',nsfreason:'',NSF_Reason:[],
						appcost:'',newTemp:'',paydates:'',paydates2:'',PaymentDays:[]};							
		this.handleStatusChange=this.handleStatusChange.bind(this)
		this.handleAPPChange=this.handleAPPChange.bind(this)		
		this.handleChange=this.handleChange.bind(this)		
		this.handleSchChange=this.handleSchChange.bind(this)
		this.handlePartChange=this.handlePartChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/padpayment.php')
			.then(result => {																	
				if(result.data[0][0] === "No") {
					this.setState({Resp:"No Scheduled Payday Loan"})
				} else {
					this.setState({Resp:""})
					this.setState({PaymentDays:result.data})
				}
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/padloan.php')
			.then(result => {			
				if(result.data[0][0] === "No") {
					this.setState({Resp:"No Scheduled Payday Loan"})
				} else {
					this.setState({Resp:""})
					this.setState({NewApp:result.data})
				}
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/nsfreason.php')
			.then(result => {				
				this.setState({NSF_Reason:result.data})													
			})					
	}
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleAPPChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})			
		this.setState({partpaid:''})		
		const userObject = {	
				PID:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0],
				AID: event.target.value
			 }
		axios.post(process.env.REACT_APP_API_ENV+'api/padinformation.php',userObject)
			.then(result => {						
				this.setState({profileinfo:result.data})
				this.setState({newpayment:parseFloat(this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[10])[0])+parseFloat(result.data[48])})
				this.setState({newTemp:parseFloat(this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[10])[0])+parseFloat(result.data[48])})
				this.setState({comments:result.data[17]})
			})				
	}
	
	handleStatusChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		this.setState({nsfreason:''})		
		this.setState({payschedules:''})
		this.setState({partpaid:''})		
		if(event.target.value === '4') {
			this.setState({newpayment:(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[10])[0])*(1+0.01*this.state.profileinfo[47])})
			this.setState({newTemp:(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[10])[0])*(1+0.01*this.state.profileinfo[47])})
		} else if(event.target.value === '3') {
			this.setState({newpayment:parseFloat(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[10])[0])+parseFloat(this.state.profileinfo[48])})
			this.setState({newTemp:parseFloat(this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[10])[0])+parseFloat(this.state.profileinfo[48])})
		}
	}	
	
	handleSchChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value === "1") {
			this.setState({install1:this.state.newpayment})			
		} else {
			this.setState({install1:""})			
		}		
	}
	handleNSFChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		this.setState({payschedules:''})
		this.setState({partpaid:''})		
	}
	
	handlePartChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  		
		this.setState({newpayment:this.state.newTemp-event.target.value})
	}	
	handleSubmit = event => {
		event.preventDefault()		
		const {newapps,appdecline,approvedloan,payschedules, install1,install2,install3,fdate1,fdate2,fdate3,sdate1,sdate2,sdate3,
				tdate1,tdate2,tdate3,comments,reasons,newpayment,partpaid,nsfreason} = this.state					
		if(!appdecline){
			alert('Please select payment status')
			return false
		} else if(appdecline !== '1' && appdecline !== '2'  ){			
			if(!nsfreason){
				alert('Please select reason')
				return false 
			} else {
				if(appdecline !== '6'){
					if(!newpayment){
						alert('Please enter new payment')
						return false 
					} 
					if(!partpaid){
						alert('Please enter current partial payment, if no, enter 0')
						return false 
					} 					
				}
			}
		}
		if(appdecline === '3' || appdecline === '4'){
			if(!payschedules){
				alert('Please select payment schedule')
				return false 
			} else if(payschedules !== ''){
				if(!install1){
					alert('Please enter installment 1 amount')
					return false 
				} 
				if(!fdate1){
					alert('Please select intallment 1 payday year')
					return false 
				} 
				if(!fdate2){
					alert('Please select intallment 1 payday month')
					return false 
				} 
				if(!fdate3){
					alert('Please select intallment 1 payday day')
					return false 
				} 
			} else if(payschedules === '2'){
				if(!install2){
					alert('Please enter installment 2 amount')
					return false 
				} 
				if(!sdate1){
					alert('Please select intallment 2 payday year')
					return false 
				} 
				if(!sdate2){
					alert('Please select intallment 2 payday month')
					return false 
				} 
				if(!sdate3){
					alert('Please select intallment 2 payday day')
					return false 
				} 
			} else if(payschedules === '3'){
				if(!install2){
					alert('Please enter installment 2 amount')
					return false 
				} 
				if(!sdate1){
					alert('Please select intallment 2 payday year')
					return false 
				} 
				if(!sdate2){
					alert('Please select intallment 2 payday month')
					return false 
				} 
				if(!sdate3){
					alert('Please select intallment 2 payday day')
					return false 
				} 
				if(!install3){
					alert('Please enter installment 3 amount')
					return false 
				} 
				if(!tdate1){
					alert('Please select intallment 3 payday year')
					return false 
				} 
				if(!tdate2){
					alert('Please select intallment 3 payday month')
					return false 
				} 
				if(!tdate3){
					alert('Please select intallment 3 payday day')
					return false 
				} 
			}
		} 
		const userObject = {	
			user:this.state.user,
			applicationid:this.state.newapps,
			Appstatus: this.state.Appstatus,
			appdecline: this.state.appdecline,
			approvedloan: this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[10])[0],							
			payschedules: this.state.payschedules,
			install1: this.state.install1,
			install2: this.state.install2,
			install3: this.state.install3,
			fdate1: this.state.fdate1,
			fdate2: this.state.fdate2,								
			fdate3: this.state.fdate3,
			sdate1: this.state.sdate1,				
			sdate2: this.state.sdate2,
			sdate3: this.state.sdate3,
			tdate1: this.state.tdate1,
			tdate2: this.state.tdate2,
			tdate3:this.state.tdate3,			
			comments:this.state.comments,
			firstpay:this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[3])[0],
			secondpay: this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[4])[0],
			email:this.state.profileinfo[0],
			firstname:this.state.profileinfo[1],
			lastname:this.state.profileinfo[2],
			reasons:this.state.reasons,
			days:this.state.days,
			FIN:this.state.profileinfo[32],
			Transit:this.state.profileinfo[31],
			CHECK:this.state.profileinfo[10],
			newpayment:this.state.newpayment,
			partpaid:this.state.partpaid,
			nsfreason:this.state.nsfreason,
			appcost: this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[9])[0]			
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/approveprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>
				<label><b>Please Select Starting Day</b></label><br/>
				<select id="paydates" name="paydates" style={{width: '150px', border:'1px solid grey'}} value = {this.state.paydates} onChange={this.handleChange}>
					<option value="">--Select Application ID--</option>		
					{this.state.PaymentDays.map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
				</select><br/><br/>	
				<label><b>Please Select Ending Day</b></label><br/>
				<select id="paydates2" name="paydates2" style={{width: '150px', border:'1px solid grey'}} value = {this.state.paydates2} onChange={this.handleChange}>
					<option value="">--Select Application ID--</option>		
					{this.state.PaymentDays.map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
				</select><br/><br/>	
				{(()=>{if(this.state.paydates !== "" && this.state.paydates2 !== "") {return (<div>	
					<label><b>Please Select Application ID</b></label><br/>
					<select id="newapps" name="newapps" style={{width: '150px', border:'1px solid grey'}} value = {this.state.newapps} onChange={this.handleAPPChange}>
						<option value="">--Select Application ID--</option>		
						{this.state.NewApp.filter(item=>item[3] >= this.state.paydates && item[3]<=this.state.paydates2).map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
					</select><br/><br/>	
				</div>)}})()}
				{(()=>{if(this.state.newapps !== "") {return (<div>	
					<h3>Applicant Information</h3><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[1]} readOnly/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Last Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[2]} readOnly/><br/>							
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Profile ID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[1])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>AccountID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[0])[0]} readOnly/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[30]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[32]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Branch Transit:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[31]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Check Number:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[10]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[11]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Online PWD:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[12]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[33]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[34]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[35]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[36]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[37]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[38]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[39]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[40]} readOnly/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[41]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[42]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Type:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[6]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Frequency:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[7]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[8]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Visit Times:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[16]} readOnly/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Loan:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[5])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Loan Cost:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[6])[0]}readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Date:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Pay Day:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[3])[0]} readOnly/><br/><br/>										
					<h3>Expected Payment</h3><br/>					
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Scheduled Pay Day:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[52]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Amount:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[51]} readOnly/><br/>
					{(()=>{if(this.state.profileinfo[50] > "1") {return (<div>	
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Scheduled Pay Day:</b></label>
						<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[54]} readOnly/>
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Amount:</b></label>
						<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[53]} readOnly/><br/>
					</div>)}})()}
					{(()=>{if(this.state.profileinfo[50] > "2") {return (<div>	
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Scheduled Pay Day:</b></label>
						<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[56]} readOnly/>
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Amount:</b></label>
						<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[55]} readOnly/><br/>
					</div>)}})()}<br/>				
					{/*(()=>{if(this.state.profileinfo[57] > "0") {return (<div>	
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Scheduled Pay Day:</b></label>
						<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[59]} readOnly/>
						<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Amount:</b></label>
						<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[58]} readOnly/><br/>
						{(()=>{if(this.state.profileinfo[57] > "1") {return (<div>	
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Scheduled Pay Day:</b></label>
							<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[61]} readOnly/>
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Amount:</b></label>
							<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[60]} readOnly/><br/>
						</div>)}})()}
						{(()=>{if(this.state.profileinfo[57] > "2") {return (<div>	
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Scheduled Pay Day:</b></label>
							<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[63]} readOnly/>
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Pay Amount:</b></label>
							<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[62]} readOnly/><br/>
						</div>)}})()}<br/>
					</div>)}})()*/}					
					<h3>Payment Status</h3>					
					<form onSubmit={this.handleSubmit}>						
						<select id="appdecline" name="appdecline" style={{width: '150px', border:'1px solid grey'}} value = {this.state.appdecline} onChange={this.handleStatusChange}>
							<option value="">--Select--</option>		
							<option value="1">Email Out Clear Payment</option>		
							<option value="2">PAID - Status Update in DB</option>									
							<option value="3">NSF_{this.state.profileinfo[48]}</option>	
							<option value="4">NSF_New Loan</option>	
							<option value="5">Collection Agency</option>	
							<option value="6">Default</option>
						</select><br/><br/>							
						{(()=>{if(this.state.appdecline !== "") {return (<div>								
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Expected Payment:</b></label>								
								<input type="text" name="approvedloan" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[10])[0]} readOnly/>
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Already Paid Amount:</b></label>
								<input type="text" name="appcost" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[9])[0]} readOnly/><br/>
								<label>{this.state.appdecline === "1" ? "Email will be sent to client regarding clear payment":(this.state.appdecline === "2" ? "No email out, only update paid information in database":"")}</label>
								<br/><br/>
						</div>)}})()}
						{(()=>{if(this.state.appdecline !== "1" && this.state.appdecline !== "2" && this.state.appdecline !== "") {return (<div>								
							<h3>Reason</h3>																
							<select name="nsfreason" style={{width: '150px', border:'1px solid grey'}} value = {this.state.nsfreason} onChange={this.handleNSFChange}>
								<option value="">--Select--</option>									
								{this.state.NSF_Reason.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}
							</select><br/><br/>
							{(()=>{if(this.state.nsfreason !== "" && this.state.appdecline !== "6") {return (<div>
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>New Pay Amount:</b></label>								
								<input type="text" name="newpayment" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.newpayment} onChange={this.handleChange} />
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Current Partial Payment:</b></label>								
								<input type="text" name="partpaid" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.partpaid} onChange={this.handlePartChange} /><br/>
								<label style={{width: '750px',display:'inline-block',textAlign:'center'}}>Note: New Pay Amount should be reduced by Current Partial Payment</label><br/><br/>
							</div>)}})()}
						</div>)}})()}
						{(()=>{if((this.state.appdecline === "3" || this.state.appdecline === "4") && this.state.nsfreason !== "") {return (<div>
								<h3>Re-Payment Schedules</h3>
								<select name="payschedules" style={{width: '150px', border:'1px solid grey'}} value = {this.state.payschedules} onChange={this.handleSchChange}>
									<option value="">--Select--</option>									
									<option value="1">1 Installment</option>
									<option value="2">2 Installments</option>
									<option value="3">3 Installments</option>									
								</select><br/><br/>
								{(()=>{if(this.state.payschedules !== "") {return (<div>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
									<label style={{width: '160px',display:'inline-block',textAlign:'center'}}></label>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
									<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
									<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
									<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>																	
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Installment 1:</b></label>
									<input type="text" name="install1" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.install1} onChange={this.handleChange} />
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payday:</b></label>	
									<select style={{width: '55px',border:'1px solid #4195fc'}} name="fdate1" value={this.state.fdate1} onChange={this.handleChange}>
										<option value="">--</option> 
										<option value={thisyear}>{thisyear}</option> 
										{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 31) {return (
											<option value={nextyear}>{nextyear}</option> 				
										)}})()}
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate2" value={this.state.fdate2} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate3" value={this.state.fdate3} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select><br/>
								</div>)}})()}
								{(()=>{if(this.state.payschedules === "2" || this.state.payschedules === "3") {return (<div>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Installment 2:</b></label>
									<input type="text" name="install2" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.install2} onChange={this.handleChange} />					
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payday:</b></label>	
									<select style={{width: '55px',border:'1px solid #4195fc'}} name="sdate1" value={this.state.sdate1} onChange={this.handleChange}>
										<option value="">--</option> 
										<option value={thisyear}>{thisyear}</option> 
										{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 62) {return (
											<option value={nextyear}>{nextyear}</option> 				
										)}})()}
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate2" value={this.state.sdate2} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate3" value={this.state.sdate3} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select><br/>
								</div>)}})()}
								{(()=>{if(this.state.payschedules === "3") {return (<div>
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Installment 3:</b></label>
									<input type="text" name="install3" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.install3} onChange={this.handleChange} />					
									<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payday:</b></label>	
									<select style={{width: '55px',border:'1px solid #4195fc'}} name="tdate1" value={this.state.tdate1} onChange={this.handleChange}>
										<option value="">--</option> 
										<option value={thisyear}>{thisyear}</option> 
										{(()=>{if(dayjs(thisyear).endOf('year').diff(dayjs(),'day') < 62) {return (
											<option value={nextyear}>{nextyear}</option> 				
										)}})()}
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="tdate2" value={this.state.tdate2} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select>-
									<select style={{width: '44px',border:'1px solid #4195fc'}} name="tdate3" value={this.state.tdate3} onChange={this.handleChange}>
										<option value="">--</option>
										{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
									</select><br/>
								</div>)}})()}<br/>								
						</div>)}})()}
						{(()=>{if(this.state.appdecline !== "" ) {return (<div>
							<label><b>Comments</b></label><br/>
							<textarea name="comments" id="comments" rows="5" value = {this.state.comments} style={{width: '300px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/>
							<label>Please don't delete existing comments</label><br/><br/>
						</div>)}})()}
						<h3>{this.state.Resp}</h3><br/><br/>
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
					</form>
				</div>)}})()}
			</div>
		)
	}
}

export default NewApplication