import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';

class SetupOperation extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {manageaction:'SENDEMAIL',CompName:'',NewApp:[],user:localStorage.getItem('tokens'), Resp:'',updaterole:'',management:'',
				human:'',employee:'',roleforupdate:''};							
		this.handleChange=this.handleChange.bind(this)
		this.handleRoleChange=this.handleRoleChange.bind(this)		
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/questionlists.php')
			.then(result => {		
					this.setState({NewApp:result.data})				
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}

	handleChange = event => {				
		const {name, value} = event.target
		this.setState({[name]: value}) 		
		this.setState({Resp:''})
		this.setState({employee:''})
	}
	handleRoleChange= event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		if(event.target.value !=="") {			
			this.setState({employee:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0]})						
		} else {
			this.setState({employee:''})
		}
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {updaterole,management,human,roleforupdate,employee} = this.state		
		if(!updaterole){
			alert('Please enter to whom email you want to send')
			return false 
		} 
		if(!roleforupdate){
			alert('Please enter CC email')
			return false
		}
		if(!human){
			alert('Please enter BCC email')
			return false 
		} 
		if(!management){
			alert('Please select question you want to send out')
			return false 
		} 			
		const userObject = {	
			user:this.state.user,
			manageaction:this.state.manageaction,
			updaterole:this.state.updaterole,			
			management:this.state.management,			
			human: this.state.human,						
			roleforupdate:this.state.roleforupdate,	
			employee:this.state.employee
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/managementprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Management Role"/>	
				<form onSubmit={this.handleSubmit}>
					<label style={{width: '50px',display:'inline-block',textAlign:'right'}}><b>To: </b></label>
					<input type="text" name="updaterole" style={{width: '150px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.updaterole} onChange={this.handleChange} /><br/><br/>	
					<label style={{width: '50px',display:'inline-block',textAlign:'right'}}><b>CC: </b></label>
					<input type="text" name="roleforupdate" style={{width: '150px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.roleforupdate} onChange={this.handleChange} /><br/><br/>	
					<label style={{width: '50px',display:'inline-block',textAlign:'right'}}><b>BCC:</b></label>
					<input type="text" name="human" style={{width: '150px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.human} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '50px',display:'inline-block',textAlign:'right'}}><b>Subject: </b></label>
					<select name="management" style={{width: '150px', border:'1px solid grey',marginLeft:'10px'}} value = {this.state.management} onChange={this.handleRoleChange}>
						<option value="">--Select--</option>		
						{this.state.NewApp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}							
					</select><br/><br/>	
					<label style={{width: '50px',display:'inline-block',textAlign:'right'}}><b>QUESTION</b></label><br/>
					<textarea name="employee" rows="8" value = {this.state.employee} style={{width: '300px',border:'1px solid #4195fc',marginLeft:'10px'}} readOnly /><br/><br/>
					<h3>{this.state.Resp}</h3><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
				</form>				
			</div>
		)
	}
}

export default SetupOperation