import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import SampleCheck from '../images/Canadian-Cheque.jpg'

class UpdateBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {Resp:'',Names:'',CompName:'',bankname:'',othernames:'',FINID:'',BTransit:'',CNumber: '',debitcard:'',onlinepwd:'',sq1:'',sa1:'',
			sq2:'',sa2:'',sq3:'',sa3:'',sq4:'',sa4:'',sq5:'',sa5:'',banks:[],user:localStorage.getItem('tokens')};				
	}

	componentDidMount() {		
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php',para)
			.then(result => {						
				this.setState({Names:result.data[1]})								
				this.setState({bankname:result.data[30]})	
				this.setState({FINID:result.data[32]})
				this.setState({BTransit:result.data[31]})
				this.setState({CNumber:result.data[52]})
				this.setState({debitcard:result.data[53]})
				this.setState({onlinepwd:result.data[54]})
				this.setState({sq1:result.data[33]})
				this.setState({sa1:result.data[34]})
				this.setState({sq2:result.data[35]})
				this.setState({sa2:result.data[36]})
				this.setState({sq3:result.data[37]})
				this.setState({sa3:result.data[38]})
				this.setState({sq4:result.data[39]})
				this.setState({sa4:result.data[40]})
				this.setState({sq5:result.data[41]})
				this.setState({sa5:result.data[42]})
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})						
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')			
			.then((result) => {	
				this.setState({banks:result.data});
			})	
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	handleSubmit = event => {
		event.preventDefault()		
		const {bankname,othernames,FINID,BTransit,CNumber,debitcard,onlinepwd,sq1,sa1,sq2,sa2,sq3,sa3,sq4,sa4,sq5,sa5} = this.state		
		if(!bankname){
			alert('Please select your bank name')
			return false
		} else if(bankname === 'TEST'){
			if(!othernames){
				alert('Please enter other bank name')
				return false
			}
			if(!FINID){
				alert('Please enter other bank financil institution number (FIN #)')
				return false
			}
		}
		if(!BTransit){
			alert('Please enter branch transit number')
			return false
		}
		if(!CNumber){
			alert('Please enter your checking account number')
			return false
		} 
		if(!debitcard){
			alert('Please enter your debit card number')
			return false
		} 
		if(!onlinepwd){
			alert('Please enter your online banking password')
			return false
		} 
		if(!sq1){
			alert('Please enter your online banking security question 1 or NO')
			return false
		} 
		if(!sa1){
			alert('Please enter your online banking security question 1 answer or NO')
			return false
		}
		if(!sq2){
			alert('Please enter your online banking security question 2 or NO')
			return false
		} 
		if(!sa2){
			alert('Please enter your online banking security question 2 answer or NO')
			return false
		}
		if(!sq3){
			alert('Please enter your online banking security question 3 or NO')
			return false
		} 
		if(!sa3){
			alert('Please enter your online banking security question 3 answer or NO')
			return false
		}
		if(!sq4){
			alert('Please enter your online banking security question 4 or NO')
			return false
		} 
		if(!sa4){
			alert('Please enter your online banking security question 4 answer or NO')
			return false
		}
		if(!sq5){
			alert('Please enter your online banking security question 5 or NO')
			return false
		}
		if(!sa5){
			alert('Please enter your online banking security question 5 answer or NO')
			return false
		} else {			
			const userObject = {	
				user:this.state.user,
				bankname:this.state.bankname,
				othernames:this.state.othernames,
				FINID:this.state.FINID,				
				BTransit:this.state.BTransit,
				CNumber:this.state.CNumber,
				debitcard:this.state.debitcard,
				onlinepwd:this.state.onlinepwd,
				sq1:this.state.sq1,
				sa1:this.state.sa1,
				sq2:this.state.sq2,
				sa2:this.state.sa2,
				sq3:this.state.sq3,
				sa3:this.state.sa3,
				sq4:this.state.sq4,				
				sa4:this.state.sa4,
				sq5:this.state.sq5,
				sa5:this.state.sa5
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/updateBanking.php', userObject)
				.then((res) => {
						this.setState({Resp:res.data})											
				}).catch((error) => {
					console.log(error)
				});
			
		}
	}
	render() {		
		return(
			<div >
				<CommonHeader titles="Banking"/>
				<h2>Updating Banking Information</h2><br/><br/>		
				<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Name:</b></label><label style={{width: '120px',display:'inline-block',textAlign:'left'}}>{' '+this.state.Names}</label><br/><br/>				
					<form onSubmit={this.handleSubmit}>						
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
					<select id="bankname" name="bankname" value = {this.state.bankname} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange}>
							<option value="">--Select Bank--</option>
							{this.state.banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}						
					</select><br/><br/>	
					{(()=>{if(this.state.bankname ==="TEST") {return (<div>			
						<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Other Bank Name:</b></label>
						<input type="text" name="othernames" id="othernames"  value = {this.state.othernames} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange}/><br/><br/>
						<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>			
						<input type="text" name="FINID" id="FINID" value = {this.state.FINID} style={{width: '150px',border:'1px solid #4195fc'}} maxLength="3" onChange={this.handleChange}/> This is 3-digit financial institution number<br/><br/>			
					</div>)} else if(this.state.bankname !=="TEST" && this.state.bankname !=="") {return (<div>						
						<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>			
						<input type="text" name="FINID" id="FINID" value = {this.state.banks.filter(sub=>sub[0]===this.state.bankname).map(sub=>sub[2])} style={{width: '150px',border:'1px solid #4195fc'}} maxLength="3" readOnly/> This is 3-digit financial institution number<br/><br/>			
					</div>)}})()}
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Branch Transit #:</b></label>
					<input type="text" name="BTransit" id="BTransit"  maxLength="5" value = {this.state.BTransit} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /> This is 5-digit branch transit number<br/><br/>			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Checking Account #:</b></label>
					<input type="text" name="CNumber" id="CNumber"  maxLength="12" value = {this.state.CNumber} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /> This is 9 or 12-digit checking account number<br/><br/>
					<label><b>Please see below Sample Check for your reference:</b></label><br/><br/>					
					<p><img src={SampleCheck} alt="check" /></p>				
					<ol>
						<li>"12345"-This is 5-digit branch transist number</li>
						<li>"999"-This is 3-digit financial institution number</li>
						<li>"456789012"-This is chequeing account number</li>
					</ol><br/><br/>									
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
					<input type="text" name="debitcard" id="debitcard"  value = {this.state.debitcard} style={{width: '150px',border:'1px solid #4195fc'}} maxLength="19" onChange={this.handleChange} />			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Online Password:</b></label>
					<input type="text" name="onlinepwd" id="onlinepwd"  value = {this.state.onlinepwd} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
					<input type="text" name="sq1" id="sq1" value = {this.state.sq1} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} />			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
					<input type="text" name="sa1" id="sa1" value = {this.state.sa1} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
					<input type="text" name="sq2" id="sq2" value = {this.state.sq2} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} />			
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
					<input type="text" name="sa2" id="sa2" value = {this.state.sa2} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
					<input type="text" name="sq3" id="sq3" value = {this.state.sq3} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} />
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
					<input type="text" name="sa3" id="sa3" value = {this.state.sa3} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
					<input type="text" name="sq4" id="sq4" value = {this.state.sq4} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} />
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
					<input type="text" name="sa4" id="sa4" value = {this.state.sa4} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
					<input type="text" name="sq5" id="sq5" value = {this.state.sq5} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} />
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
					<input type="text" name="sa5" id="sa5" value = {this.state.sa5} style={{width: '150px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
					<h2>{this.state.Resp}</h2><br/>
					<label style={{width: '120px',display:'inline-block',textAlign:'right'}}><b></b></label>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Update</button><br/><br/>
				</form>
			</div>
		)
	}
}

export default UpdateBanking