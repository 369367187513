import CommonHeader from '../pages/Head'
import React from 'react'
import axios from 'axios';
import UpdateBank from '../pages/updateBank'

class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'',isUpdating:false,	viewProfile:true, checkid:'',user:localStorage.getItem('tokens'),profileinfo:[]};		
		this.handleClick=this.handleClick.bind(this);		
		this.handleProfile=this.handleProfile.bind(this);
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php',para)
			.then(result => {						
				this.setState({profileinfo:result.data})				
				this.setState({checkid:result.data[52].substring(8,12)})	
			})							
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	handleClick(){				
		this.setState(prevState=>({isUpdating: !prevState.isUpdating}))		
		this.setState(prevState=>({viewProfile: !prevState.viewProfile}))		
	}	
	handleProfile(){				
		this.setState(prevState=>({viewProfile: !prevState.viewProfile}))			
		this.setState({isUpdating:false})		
	}
	render() {		
		return(
			<div className="right_content">
				<CommonHeader titles="Bank"/>
				<div style={{textAlign:'center'}}>
				<button onClick={this.handleProfile}>{this.state.viewProfile ? "View Bank Information Now":"View Bank Information?"}</button>&nbsp;&nbsp;
				<button onClick={this.handleClick}>{this.state.isUpdating ? "Updating  Bank Information Now": "Update Your Bank Information?"}</button><br/><br/>
				</div>
				{(()=>{if(this.state.viewProfile === true) {return (<div>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[30]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>FIN#:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[32]}</label><br/><br/>					
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Transit#:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[31]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Check#:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{'xxxxxxxx'+this.state.checkid}</label><br/><br/>										
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[53]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Online Password:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[54]}</label><br/><br/>					
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[33]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[34]}</label><br/><br/>						
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[35]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[36]}</label><br/><br/>					
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[37]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[38]}</label><br/><br/>					
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[39]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[40]}</label><br/><br/>					
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[41]}</label>
					<label style={{width: '180px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
					<label style={{width: '150px',display:'inline-block',textAlign:'left',marginLeft:'5px'}}>{this.state.profileinfo[42]}</label><br/><br/>					
				</div>)}})()}	
				{(()=>{if(this.state.isUpdating === true) {return (
					<UpdateBank/>
				)}})()}			
			</div>
		)
	}
}

export default HomtContents