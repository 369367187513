import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';

class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {days:dayjs().format('MM/DD/YYYY'),Appstatus:'INAGENCY',CompName:'',NewApp:[],user:localStorage.getItem('tokens'),
						profileinfo:[],Resp:'',newapps:'', Hist:[], appdecline:'',approvedloan:'',appcost:'',comments:'No Comment',newpayment:''};							
		this.handleAPPChange=this.handleAPPChange.bind(this)
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {	
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/senttoagencyclients.php')
			.then(result => {				
				if(result.data[0][0] === "No") {
					this.setState({Resp:"No new application now"})
				} else {
					this.setState({Resp:""})
					this.setState({NewApp:result.data})
				}
			})									
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})				
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
	}
	
	handleAPPChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})			
		const userObject = {	
				PID:this.state.NewApp.filter(sub=>sub[0] === event.target.value).map(sub=>sub[1])[0]				
			 }
		axios.post(process.env.REACT_APP_API_ENV+'api/applicationinfo.php',userObject)
			.then(result => {						
				this.setState({profileinfo:result.data})				
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/histrecord.php',userObject)
			.then(result => {						
				this.setState({Hist:result.data})				
			})								
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {newapps, appdecline,approvedloan,appcost,comments} = this.state		
		if(!newapps){
			alert('Please select application ID')
			return false 
		} 	
		if(!appdecline){
			alert('Please select payment status')
			return false
		} 
		if(!appcost){
			alert('Please enter collected payment by agency')
			return false 
		} 		
		const userObject = {	
			user:this.state.user,
			applicationid:this.state.newapps,
			Appstatus: this.state.Appstatus,
			appdecline: this.state.appdecline,					
			appcost: this.state.appcost,			
			comments:this.state.comments,
			email:this.state.profileinfo[0],
			firstname:this.state.profileinfo[1],
			lastname:this.state.profileinfo[2],
			approveddate:this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0],
			approvedloan:this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[8])[0],
			newpayment:this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[9])[0]
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/approveprocess.php', userObject)
			.then((res) => {
				this.setState({Resp:res.data})											
			}).catch((error) => {
				console.log(error)
			});
		
	}
	render() {		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Application"/>
				<label><b>Please Select Application ID:</b></label><br/>
				<select id="newapps" name="newapps" style={{width: '150px', border:'1px solid grey'}} value = {this.state.newapps} onChange={this.handleAPPChange}>
					<option value="">--Select Application ID--</option>		
					{this.state.NewApp.map(item => (<option value={item[0]} key={item[0]}>{item[0]}</option>))}								
				</select><br/><br/>	
				{(()=>{if(this.state.newapps !== "") {return (<div>	
					<h3>Default Application</h3><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[1]} readOnly/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Last Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[2]} readOnly/><br/>							
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Email:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Gender:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[19]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Date of Birth:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[20]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Province:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[43]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Bank Name:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[30]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>FIN #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[32]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Branch Transit:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[31]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Check Number:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[10]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Debit Card #:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[11]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Online PWD:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[12]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 1:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[33]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 1 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[34]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 2:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[35]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 2 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[36]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 3:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[37]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 3 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[38]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 4:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[39]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 4 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[40]} readOnly/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Security Question 5:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[41]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Question 5 Answer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[42]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[44]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employer Phone:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[45]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Job Started:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[46]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Employment Type:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[5]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Type:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[6]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Payment Frequency:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[7]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Each Pay Amount:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[8]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Direct Deposit:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[9]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>First Pay Day:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[3])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Account ID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[0])[0]} readOnly/><br/>											
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Profile ID:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[1])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Visit Times:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.profileinfo[16]} readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Loan:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[5])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Loan Cost:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[6])[0]}readOnly/><br/>				
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Approved Date:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[7])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Default Reason:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[10])[0]} readOnly/><br/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Paid Amount:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[8])[0]} readOnly/>
					<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Unpaid Amount:</b></label>
					<input type="text" name="address" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[9])[0]} readOnly/><br/><br/>					
					<h3>Historical Record Checking</h3><br/>
					<select id="hischeck" name="hischeck" style={{width: '150px', border:'1px solid grey'}} value = {this.state.hischeck} onChange={this.handleChange}>
						<option value="">--Select--</option>							
						<option value="1">Check Historical Payday Loans</option>							
					</select><br/><br/>	
					{(()=>{if(this.state.hischeck === "1") {return (<div>					
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>AccountID</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Bank</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Check #</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Approved Loan</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Charge</b></label>			
						<label style={{width: '130px',display:'inline-block',textAlign:'center'}}><b>Approved Date</b></label>
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Due Day</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Paid AMT</b></label>	
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>NSF AMT</b></label>			
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Loan Process</b></label>
						{this.state.Hist.slice(0, this.state.Hist.length).map(item => {return (<div>						
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[4]}</label>
							<label style={{width: '130px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[5]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[8]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[6]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[9]}</label>
							<label style={{width: '80px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[7]}</label>
						</div>)})}<br/><br/>					
					</div>)}})()}
				
					<h3>Payment Status</h3>					
					<form onSubmit={this.handleSubmit}>						
						<select id="appdecline" name="appdecline" style={{width: '150px', border:'1px solid grey'}} value = {this.state.appdecline} onChange={this.handleChange}>
							<option value="">--Select--</option>		
							<option value="1">Email Out Clear Payment</option>		
							<option value="2">PAID - Status Update in DB</option>																
						</select><br/><br/>							
						{(()=>{if(this.state.appdecline !== "") {return (<div>								
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Expected Payment:</b></label>								
								<input type="text" name="approvedloan" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.NewApp.filter(sub=>sub[0] === this.state.newapps).map(sub=>sub[9])[0]} readOnly/>
								<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Collected Amount:</b></label>
								<input type="text" name="appcost" style={{width: '150px',border:'1px solid #4195fc',marginLeft:'10px'}} value={this.state.appcost} onChange={this.handleChange}/><br/>								
								<label>{this.state.appdecline === "1" ? "Email will be sent to client regarding clear payment":(this.state.appdecline === "2" ? "No email out, only update paid information in database":"")}</label>
								<br/><br/>
						</div>)}})()}						
						{(()=>{if(this.state.appdecline === "2" ) {return (<div>
							<h3>Note: This is only to update payment. If payment is clear by agency, please select Email Out Clear Payment</h3><br/>
						</div>)}})()}
						{(()=>{if(this.state.appdecline !== "" ) {return (<div>
							<label><b>Comments</b></label><br/>
							<textarea name="comments" id="comments" rows="5" value = {this.state.comments} style={{width: '300px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/>
							<label>Please don't delete existing comments</label><br/><br/>
						</div>)}})()}
						<h3>{this.state.Resp}</h3><br/><br/>
						<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
					</form>
				</div>)}})()}
			</div>
		)
	}
}

export default NewApplication