import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import Img1 from '../images/img1.jpg'
import Img2 from '../images/img2.jpg'
import Img3 from '../images/img3.jpg'
import ApplyImg from '../images/Apply Now_1.jpg'


class HomtContents extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	render() {		
		return(
			<div className="right_content">
				<CommonHeader titles="Payday Loan Agreement"/>
				<h2>{this.state.CompName+' Payday Loan Agreement'}</h2><br/>
				<div className="text2">
					<h3><span>Welcome to <Link to="/">{this.state.CompName}</Link></span></h3><br />
					A Canadian payday loan company to provide a fast cash advance. <br/>
					Our mission is to put Canadian's need first and manage online service where you can obtain a payday loan. <br/>
					Our <Link to="/Apply" style={{color:"blue"}}>online application</Link> is simple. If you are an exsiting customer, the <Link to="/Reloan" style={{color:"blue"}}>application</Link> is even simpler.
					<div className="read_right"><Link to="/Apply"><img src={ApplyImg} alt="payday loan apply button" /></Link></div>
				</div><br/>
				<h3><Link to="/">{this.state.CompName}</Link> Payday Loans</h3>
				<div className="text">
					<ul >
						<li>Faxless</li>
						<li>Paperless</li>
						<li>No Credit Check</li>
						<li>No Hidden Cost</li>
						<li>Fast and Easy</li>
						<li>Up to $600 cash advance</li>
						<li>Approved Loan directly deposited into your banking account</li>
					</ul><br />                    
					<div className="read_right"><Link to="/Apply"><img src={ApplyImg} alt="payday loan apply button"/></Link></div>
					<h3>How does it work</h3><br/>
					<ol>
						<li>Apply: Fill out our virtual online <Link to ="/Apply" style={{color:"blue"}}>application</Link></li>
						<li>Review: Verify your application as soon as we receive</li>
						<li>Approve: Your approved loan is sent to bank for deposit process</li>
						<li>Repay: Your repayment is set up at your next payday via PAD process</li>
						<li>Reloan: You can re-borrow after your payment is clear and your credit score grows with the clear payments</li>
					</ol>
					<div className="read_right"><Link to="/Apply"><img src={ApplyImg} alt="payday loan apply button"/></Link></div>
				</div><br/>
				<div className="text">
					<Link to="/Apply"><img src={Img1} style={{width: "168", height:"113",}} className="img" alt="payday loan money" /></Link>
					<h3><Link to="/Apply"><span>Payday Loan Made Easy</span></Link></h3><br/>
					When you need emergency and short term cash, <Link to ="/" style={{color:"blue"}}>{this.state.CompName}</Link> provides a quick, convenient and secure way to get cash fast.
					Quick, no hassle,no fuss payday loans online application makes it easy to apply for when it's convenient for you.
					No matter what time of day it is! Our online payday loan fits perfectly into your busy lifestyle. 
					We've made the application process fast, easy and simple and completely faxless so you can apply online anytime and anywhere.			    
					Try our easy virtual online <Link to ="/Apply" style={{color:"blue"}}>application here</Link> now to get your loan now; 
					no store, no hassle; problem free loans with no credit check. 
					We deposit your borrowed loan into your personal banking account directly.<br/><br/>				
				</div><br/><br/><br/><br/>
				<div className="text">
					<Link to ="/Apply"><img src={Img2} style={{width:"168",height:"113",}} className="img" alt="payday loan handshakes"/></Link>
					<h3><span><Link to = "/Apply">{this.state.CompName} Made Difference</Link></span></h3><br/>
					Payday loans are short-term source of credit used for unexpected emergencies needs. Whenever you need fast cash,
					you have to the right place for a payday loan which is approved and directly deposited into your banking account.
					{this.state.CompName} is proud of putting Canadians' financial needs first. Simply fill out our easy online application
					anytime, anywhere at <Link to = "/Apply" style={{color:"blue"}}>{this.state.CompName}. </Link>
					No credit check, no fax required, no hidden costs involved and all fees are explained during your application process.
					Once the funds are deposited into your bank account, your repayment wil be debited from your bank account.
					That's why we made the process of applying for a payday loan simple, easy and fast. 
					If you are 18 years of age or above and a Canadian, you are welcome to use our friendly services. 
					We are always willing to be a support. Are you ready now? Just cick <Link to="/Apply" style={{color:"blue"}}>here</Link> to start your application now.<br /><br/>                
				</div><br/><br/>
				<div className="text">
					<Link to="/Apply"><img src={Img3} style={{width:"168", height:"113",}} className="img" alt="payday loan flowers" /></Link>
					<h3><span><Link to="/Apply">Our Mission</Link></span></h3><br />
					To provide you with the money you need as quickly as possible and help you be able to continue 
					your daily lifestyles. <br /><br /><br /><br />                
				</div><br/><br/>
				<div className="text">
					<h3><span>Non Payment</span></h3><br/>
					If you do not repay us on an outstanding loan, you will not be permitted to borrow from us again.
					All of your information regarding your unpaid loan will be sent to a third party collection agency
					who will contact you directly to collect your repayment plus cost. In such non-payment case, the collection agency
					will report your default case to a credit agency which will have negative impact on your credit score						
				</div><br /><br />				
			</div>
		)
	}
}

export default HomtContents