import CommonHeader from '../pages/Head'
import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let SA=''
	let TT=''
	let FMT=''
	let Dates=''	
	if($Type==="Y") {
		HM=thisyear-2010		
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12		
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31		
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} 	
	for(let $i=0;$i<HM;$i++) {
		TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)		
	}
	return TM;
}

class NewApplication extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {email:'',accountid:'',profile:'',user:localStorage.getItem('tokens'),profileinfo:[],Resp:'',reportitem:'',Hist:[],
						province:'',NewApp:[],fdate1:'',fdate2:'',fdate3:'',sdate1:'',sdate2:'',sdate3:''};				
		this.handleChange=this.handleChange.bind(this)
		this.handleREPChange=this.handleREPChange.bind(this)
		this.handlePROChange=this.handlePROChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	componentDidMount() {			
		axios.post(process.env.REACT_APP_API_ENV+'api/findprovinces.php')
			.then(result => {				
					this.setState({NewApp:result.data})
			})											
	}
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  	
		this.setState({Resp:''})
	}
	handleREPChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		this.setState({province:''})
		this.setState({fdate1:''})
		this.setState({fdate2:''})
		this.setState({fdate3:''})
		this.setState({sdate1:''})
		this.setState({sdate2:''})
		this.setState({sdate3:''})
	}
	handlePROChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})  
		this.setState({Resp:''})
		this.setState({fdate1:''})
		this.setState({fdate2:''})
		this.setState({fdate3:''})
		this.setState({sdate1:''})
		this.setState({sdate2:''})
		this.setState({sdate3:''})
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {reportitem, province,fdate1,fdate2,fdate3,sdate1,sdate2,sdate3} = this.state		
		if(!reportitem){
			alert('Please select report')
			return false 
		} 	
		if(!province){
			alert('Please select province or federal Canada')
			return false 
		} 	
		if(!fdate1){
			alert('Please select start date year')
			return false 
		}
		if(!fdate2){
			alert('Please select start date month')
			return false 
		}
		if(!fdate3){
			alert('Please select start date day')
			return false 
		}
		if(!sdate1){
			alert('Please select end date year')
			return false 
		}
		if(!sdate2){
			alert('Please select end date month')
			return false 
		}
		if(!sdate3){
			alert('Please select end date day')
			return false 
		}
		const userObject = {	
			user:this.state.user,
			reportitem:this.state.reportitem,
			province:this.state.province,
			fdate1:this.state.fdate1,
			fdate2:this.state.fdate2,
			fdate3:this.state.fdate3,
			sdate1:this.state.sdate1,
			sdate2:this.state.sdate2,
			sdate3:this.state.sdate3			
		};
		axios.post(process.env.REACT_APP_API_ENV+'api/reportcontent.php', userObject)
			.then((res) => {
				if(res.data[0][0] === "NO") {
					this.setState({Resp:'Sorry, No data'})				
				} else {
					this.setState({profileinfo:res.data})
					this.setState({Resp:'YES'})				
				}
			})
	}
	render() {		
		return(
			<div className="right_content" style={{textAlign:'center'}}>
				<CommonHeader titles="Payday Loan Reports"/>
				<form onSubmit={this.handleSubmit}>
					<h3>Reports</h3>
					<select name="reportitem" style={{width: '150px', border:'1px solid grey'}} value = {this.state.reportitem} onChange={this.handleREPChange}>
						<option value="">--Select--</option>							
						<option value="1">Monthly Report</option>
						<option value="2">Annual Report</option>						
						<option value="3">Approved Report</option>
						<option value="4">Outstanding Loans Report</option>
					</select><br/><br/>	
					{(()=>{if(this.state.reportitem !== "") {return (<div>
						<h3>Province</h3>
						<select name="province" style={{width: '150px', border:'1px solid grey'}} value = {this.state.province} onChange={this.handlePROChange}>
							<option value="">--Select--</option>							
							{this.state.NewApp.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}
							<option value="15">Canada</option>
						</select><br/><br/>						
						{(()=>{if(this.state.province !== "") {return (<div>
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
							<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
							<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
							<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label>
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>
							<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
							<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
							<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label>
							<br/>																	
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>Start Date:</b></label>												
							<select style={{width: '55px',border:'1px solid #4195fc'}} name="fdate1" value={this.state.fdate1} onChange={this.handleChange}>
								<option value="">--</option> 
								{TDerived('Y').map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}
							</select>-
							<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate2" value={this.state.fdate2} onChange={this.handleChange}>
								<option value="">--</option>
								{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
							</select>-
							<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate3" value={this.state.fdate3} onChange={this.handleChange}>
								<option value="">--</option>
								{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
							</select>
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}><b>End Date:</b></label>												
							<select style={{width: '55px',border:'1px solid #4195fc'}} name="sdate1" value={this.state.sdate1} onChange={this.handleChange}>
								<option value="">--</option> 
								{TDerived('Y').map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}
							</select>-
							<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate2" value={this.state.sdate2} onChange={this.handleChange}>
								<option value="">--</option>
								{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
							</select>-
							<select style={{width: '44px',border:'1px solid #4195fc'}} name="sdate3" value={this.state.sdate3} onChange={this.handleChange}>
								<option value="">--</option>
								{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}																		
							</select><br/><br/>
						</div>)}})()}
					</div>)}})()}
					<h3>{this.state.Resp === "YES" ? "":this.state.Resp}</h3><br/><br/>
					<button className="btn btn-success btn-block" style={{fontSize:'150%'}}>Submit</button><br/><br/>
				</form><br/><br/>
				{(()=>{if((this.state.reportitem === "1" || this.state.reportitem === "2") && this.state.province !== "" && this.state.Resp === "YES") {return (<div>					
					<h3>{this.state.reportitem === "1" ? "Monthly Report By Amount":"Yearly Report By Amount"}</h3><br/>					
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Date</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Province</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Applicant</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Application</b></label>								
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>$Approved</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Loan Credits</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#-$1-499</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>$-$1-499</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#-$500-999</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>$-$500-999</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#-$1000Up</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>$-$1000Up</b></label>
					{this.state.profileinfo.slice(0, this.state.profileinfo.length).map(item => {return (<div>						
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>						
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[4]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[5]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[6]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[9]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[7]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[10]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[8]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[11]}</label>
					</div>)})}<br/><br/>
					<h3>{this.state.reportitem === "1" ? "Monthly Report By Application":"Yearly Report By Application"}</h3><br/>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Date</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Province</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Applicant</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Application</b></label>								
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Payment</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>$Payment</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Default</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>$Default</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Written Off</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>$Written Off</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b></b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b></b></label>
					{this.state.profileinfo.slice(0, this.state.profileinfo.length).map(item => {return (<div>		
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>						
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[19]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[20]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[21]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[22]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[23]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[24]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}></label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}></label>						
					</div>)})}<br/><br/>
					<h3>{this.state.reportitem === "1" ? "Monthly Report By Term Length":"Yearly Report By Term Length"}</h3><br/>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Date</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Province</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Applicant</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Application</b></label>								
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Average Days</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#1-7 Days</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#8-14 Days</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#15-21 Days</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#22-30 Days</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#31-62 Days</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Over 62 Days</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b></b></label>
					{this.state.profileinfo.slice(0, this.state.profileinfo.length).map(item => {return (<div>		
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>						
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[12]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[13]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[14]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[15]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[16]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[17]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[18]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}></label>						
					</div>)})}<br/><br/>
					<h3>{this.state.reportitem === "1" ? "Monthly Report By Loans":"Yearly Report By Loans"}</h3><br/>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Date</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Province</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Applicant</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#Application</b></label>								
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#with  1 Loan</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#with 2-5 Loans</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#with 6-10 Loans</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#with 11-15 Loans</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>#with 16up Loans</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b></b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b></b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b></b></label>
					{this.state.profileinfo.slice(0, this.state.profileinfo.length).map(item => {return (<div>		
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>						
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[25]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[26]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[27]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[28]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[29]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}></label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}></label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}></label>						
					</div>)})}<br/><br/>
				</div>)} else if ((this.state.reportitem === "3" || this.state.reportitem === "4") && this.state.province !== "" && this.state.Resp === "YES") {return (<div>					
					<h3>{this.state.reportitem === "3" ? "Approved Loan Report":"Outstanding Approved Loan Report"}</h3><br/>					
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Series</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Province</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>AccountID</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>ProfileID</b></label>								
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Bank</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Loan</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Charge</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Due Day</b></label>	
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Status</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Paid AMT</b></label>			
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b>Employee</b></label>
					<label style={{width: '75px',display:'inline-block',textAlign:'center'}}><b></b></label>
					{this.state.profileinfo.slice(0, this.state.profileinfo.length).map(item => {return (<div>		
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[0]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[1]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[2]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[3]}</label>						
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[4]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[5]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[6]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[8]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[9]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[7]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}>{item[10]}</label>
						<label style={{width: '75px',display:'inline-block',textAlign:'center',color:'blue'}}></label>						
					</div>)})}<br/><br/>
				</div>)}})()}
			</div>
		)
	}
}

export default NewApplication